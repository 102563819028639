import React, { useRef, useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { PaperClipIcon, PhotoIcon } from '@heroicons/react/24/outline';
import useGetSignedUrls from '../../Hooks/useGetSignedUrls';
import { Spinner } from 'flowbite-react';

const DocumentViewer = ({
  project,

  documents,
  fileNames,
  setFileNames,
  inputRefs,
  activeDocForChange,
  setActiveDocForChange,
}) => {
  const { setFieldValue } = useFormikContext();

  const { fileUrls, loading, error } = useGetSignedUrls(project?._id);

  const handleFileChange = useCallback(
    (event, name) => {
      const file = event.currentTarget.files[0];

      if (file) {
        setFieldValue(name, file);
        setFileNames((prevNames) => ({ ...prevNames, [name]: file.name }));
      }
    },
    [setFieldValue, setFileNames]
  );

  const handleDrop = useCallback(
    (event, name) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        setFieldValue(name, file);
        setFileNames((prevNames) => ({ ...prevNames, [name]: file.name }));
      }
    },
    [setFieldValue, setFileNames]
  );

  const handleReupload = useCallback(
    (name) => {
      setFieldValue(name, null);
      setFileNames((prevNames) => ({ ...prevNames, [name]: null }));
      if (inputRefs.current[name]) {
        inputRefs.current[name].value = '';
        inputRefs.current[name].click();
      }
    },
    [setFieldValue, setFileNames, inputRefs]
  );

  if (loading)
    return (
      <p>
        {' '}
        <Spinner aria-label="Info spinner example" color="info" />
      </p>
    );
  if (error) return <p>{null}</p>;

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Attach Documents</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Please view and/or attach the following documents.</p>
      </div>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        {documents.map((doc, index) => (
          <div key={index} className="col-span-6">
            <div className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <div className="flex">
                  <a href={fileUrls?.[doc.name]} className="truncate font-medium ml-4 flex">
                    {doc.label}{' '}
                  </a>
                  <div className="text-gray-400 ml-2">{!fileUrls?.[doc.name] && 'No Document Uploaded'}</div>
                </div>
              </div>

              {fileUrls?.[doc.name] ? (
                <div>
                  <a href={fileUrls?.[doc.name]} target="_blank" rel="noreferrer" className="font-medium text-[#2A9D8F] hover:text-[#2A9D8F]">
                    View
                  </a>
                  <button type="button" onClick={() => setActiveDocForChange(doc.name)} className="ml-2 text-gray-600 hover:text-[#2A9D8F]">
                    Upload New
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  onClick={() => setActiveDocForChange(doc.name)}
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-[#2A9D8F] text-sm"
                >
                  Upload Document
                </button>
              )}
            </div>

            {activeDocForChange === doc?.name && (
              <div
                onDragOver={(event) => event.preventDefault()}
                onDrop={(event) => handleDrop(event, doc.name)}
                className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 h-[200px]"
              >
                {fileNames?.[doc.name] ? (
                  <div className="text-center text-sm mt-10">
                    <span className="block mb-4 text-gray-700">{fileNames?.[doc.name]}</span>
                    <button
                      type="button"
                      onClick={() => handleReupload(doc.name)}
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-[#2A9D8F]"
                    >
                      Re-upload
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor={doc.name}
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-[#2A9D8F]"
                      >
                        <span>Upload a file</span>
                        <input
                          ref={(el) => (inputRefs.current[doc.name] = el)}
                          id={doc.name}
                          type="file"
                          name={doc.name}
                          onChange={(event) => handleFileChange(event, doc.name)}
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentViewer;
