import React, { useState, useCallback } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import NoteSlideOver from './NoteSlideOver';
import { deleteNote } from '../../../services/noteService';
import AddNoteButton from './AddNoteButton';
import axios from 'axios';
import api from '../../../utils/api';

function NotesTable({ projectId, notes: initialNotes }) {
  const [notes, setNotes] = useState(initialNotes);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);

  const handleRowClick = (note) => {
    setSelectedNote(note);
    setIsSlideOverOpen(true);
  };

  const refetchNotes = async () => {
    try {
      const notesResponse = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`);
      setNotes(notesResponse.data);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    }
  };

  const handleDelete = async (e, note) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await deleteNote(note._id);
        await refetchNotes();
      } catch (error) {
        console.error('Error deleting note:', error);
        alert('Failed to delete note');
      }
    }
  };

  const handleSlideOverClose = useCallback(async () => {
    setIsSlideOverOpen(false);
    await refetchNotes();
  }, [refetchNotes]);

  const handleNoteUpdate = useCallback((updatedNote) => {
    setNotes((prevNotes) => prevNotes.map((n) => (n._id === updatedNote._id ? updatedNote : n)));
    setSelectedNote(updatedNote);
  }, []);

  const handleAddNote = () => {
    setSelectedNote(null);
    setIsSlideOverOpen(true);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Project Notes</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">A list of all notes for this project.</p>
      </div>
      <div className="border-t border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Author
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Change Requested
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {notes.map((note) => (
              <tr key={note._id} onClick={() => handleRowClick(note)} className="hover:bg-gray-50 cursor-pointer">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {note.title.length > 30 ? `${note.title.slice(0, 30)}...` : note.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{note.authorId?.email || note?.authorEmail || 'N/A'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{note.changeRequested ? 'Yes' : 'No'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(note.timestamp).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button onClick={(e) => handleDelete(e, note)} className="text-red-600 hover:text-red-900 mr-2">
                    <TrashIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(note);
                    }}
                    className="text-[#2A9D8F] hover:text-[#2A9D8F]"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <AddNoteButton onAddNote={handleAddNote} />
      </div>
      <NoteSlideOver
        open={isSlideOverOpen}
        setOpen={setIsSlideOverOpen}
        data={selectedNote}
        projectId={projectId}
        refetch={refetchNotes}
        onClose={handleSlideOverClose}
        onUpdate={handleNoteUpdate}
      />
    </div>
  );
}

export default NotesTable;
