import React, { useState, useCallback } from 'react';
import { PaperClipIcon, ClipboardDocumentIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import api from '../../../utils/api';

export default function InvitationLinks({ project }) {
  const [copiedEmail, setCopiedEmail] = useState(null);
  const [sentEmails, setSentEmails] = useState({});

  const copyToClipboard = useCallback((email) => {
    const inviteLink = `${window.location.origin}/login/?email=${encodeURIComponent(email)}`;
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopiedEmail(email);
      setTimeout(() => setCopiedEmail(null), 5000); // 5 seconds timeout
    });
  }, []);

  const sendInvitationEmail = async (email) => {
    try {
      await api.post(`${process.env.REACT_APP_SERVER_URL}/api/emails/send-invitation/${project._id}`, { email });
      setSentEmails((prev) => ({ ...prev, [email]: true }));
    } catch (error) {
      console.error('Error sending invitation email:', error);
      alert('Failed to send invitation email. Please try again.');
    }
  };

  // Filter out clients with empty emails
  const validClients = project.clients.filter((client) => client.email);

  return (
    <div className="mt-10">
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Invitation Links</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Copy and share these links or send invitation emails to invite clients to the project.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {validClients.length > 0 ? (
            validClients.map((client, index) => (
              <div key={client._id || index} className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Client Email {index + 1}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center justify-between">
                  <span>{client.email}</span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => copyToClipboard(client.email)}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-[#2A9D8F] bg-[#E9F5F4] hover:bg-[#D3EAE8] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                    >
                      {copiedEmail === client.email ? (
                        <span>Copied!</span>
                      ) : (
                        <>
                          <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                          Copy Link
                        </>
                      )}
                    </button>
                    <button
                      onClick={() => sendInvitationEmail(client.email)}
                      className={`inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md ${
                        sentEmails[client.email] ? 'text-white bg-[#238277]' : 'text-white bg-[#2A9D8F] hover:bg-[#238277]'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]`}
                      disabled={sentEmails[client.email]}
                    >
                      {sentEmails[client.email] ? (
                        <span>Sent!</span>
                      ) : (
                        <>
                          <EnvelopeIcon className="h-4 w-4 mr-1" />
                          Send Invite
                        </>
                      )}
                    </button>
                  </div>
                </dd>
              </div>
            ))
          ) : (
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Client Emails</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">No valid client emails available</dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}
