import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";

const fieldMappings = {
  projectStyle: "Project Style",
  styleComments: "Style Comments",
  jobReference: "Job Reference",
  clientName: "Client Name",
  clientPhone: "Client Phone",
  clientAddress: "Client Address",
  clientEmails: "Client Emails",
  numberOfPhases: "Number of Phases",
  projectStartDate: "Project Start Date",
  projectEndDate: "Project End Date",
  projectDescription: "Project Description",
  onlineLink: "Online Link",
  projectManager: "Project Manager",
  projectStage: "Project Stage",
  admins: "Admins",
  clients: "Clients",
  contractors: "Contractors",
  phases: "Phases",
  plansLink: "Plans Link",
  specificationsLink: "Specifications Link",
  permitsLink: "Permits Link",
  contractsLink: "Contracts Link",
  specificationsKey: "Specifications File",
  permitsKey: "Permits File",
  contractsKey: "Contracts File",
  plansKey: "Plans File",
  additionalInfo: {
    additionalSpace: {
      rearSingleStoreyExtension: "Rear Single Storey Extension",
      rearDoubleStoreyExtension: "Rear Double Storey Extension",
      sideSingleStoreyExtension: "Side Single Storey Extension",
      sideDoubleStoreyExtension: "Side Double Storey Extension",
      infillExtension: "Infill Extension",
      loftConversion: "Loft Conversion",
      garageConversion: "Garage Conversion",
      porch: "Porch",
      outbuildingGardenRoom: "Outbuilding/Garden Room",
      newGarageCarport: "New Garage/Carport",
      basement: "Basement",
    },
    internalLayoutReconfiguration: {
      partial: "Partial Internal Layout Reconfiguration",
      affectedAreasOnly: "Affected Areas Only for Layout Reconfiguration",
      fullReconfigurationOfExisting: "Full Reconfiguration of Existing",
      unknown: "Unknown Internal Layout Reconfiguration",
    },
    services: {
      electric: "Electric Service",
      gas: "Gas Service",
      water: "Water Service",
      greenHomeRenewableEnergy: "Green Home/Renewable Energy Service",
      unknown: "Unknown Service",
    },
    decoration: {
      wholeHouseInternal: "Whole House Internal Decoration",
      wholeHouseExternal: "Whole House External Decoration",
      affectedAreasOnly: "Affected Areas Only for Decoration",
      unknown: "Unknown Decoration",
    },
    furtherDetails: "Further Details",
  },
  documents: [
    {
      name: "Document Name",
      category: "Document Category",
      url: "Document URL",
      uploadedBy: "Uploaded By",
      uploadedDate: "Uploaded Date",
    },
  ],
};

export default function SummaryOfChanges({ open, setOpen, version }) {
  const ChangesList = ({ version, fileUrls }) => {
    const getHumanReadableFieldName = (field) => {
      let parts = field.split(".");
      let currentMapping = fieldMappings;

      for (let part of parts) {
        if (currentMapping[part]) {
          currentMapping = currentMapping[part];
        } else {
          return null; // If mapping doesn't exist for this part
        }
      }

      return typeof currentMapping === "string" ? currentMapping : null;
    };

    const objectToString = (obj) => {
      if (typeof obj !== "object" || obj === null) return obj;

      return Object.entries(obj)
        .map(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            return `${key}: { ${objectToString(value)} }`;
          }
          return `${key}: ${value}`;
        })
        .join(", ");
    };

    return (
      <div className="mt-2 text-left">
        <p className="text-sm text-gray-500">
          The following fields have been changed:
        </p>
        {version.changeLog.changes.map((change) => {
          // Check if old and new values are different
          if (
            JSON.stringify(change.oldValue) !== JSON.stringify(change.newValue)
          ) {
            const humanReadableField = getHumanReadableFieldName(change.field);
            return (
              <div
                key={change._id}
                className="flex items-center justify-between py-2 text-sm leading-6"
              >
                <div className="flex w-0 flex-1 flex-col">
                  {change.field.startsWith("documents") ? (
                    <>
                      {change.newValue &&
                        typeof change.newValue === "object" && (
                          <>
                            <div className="flex items-center">
                              <p>{`New ${change.newValue.category} document uploaded`}</p>
                            </div>
                            <div className="text-xs text-gray-400 mt-1">
                              Old Value:{" "}
                              {change.oldValue ? change.oldValue.key : "N/A"}
                            </div>
                            <div className="text-xs text-gray-400 mt-1">
                              New Value: {change.newValue.key}
                            </div>
                          </>
                        )}
                      {change.newValue === null && (
                        <>
                          <div className="flex items-center">
                            <p>{`${change.oldValue.category} document deleted`}</p>
                          </div>
                          <div className="text-xs text-gray-400 mt-1">
                            Old Value: {change.oldValue.key}
                          </div>
                          <div className="text-xs text-gray-400 mt-1">
                            New Value: N/A
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="flex items-center">
                        <p>{humanReadableField}</p>
                      </div>
                      <div className="text-xs text-gray-400 mt-1">
                        Old Value:{" "}
                        {typeof change.oldValue === "object"
                          ? objectToString(change.oldValue)
                          : change.oldValue
                          ? change.oldValue
                          : "N/A"}
                      </div>
                      <div className="text-xs text-gray-400 mt-1">
                        New Value:{" "}
                        {typeof change.newValue === "object"
                          ? objectToString(change.newValue)
                          : change.newValue
                          ? change.newValue
                          : "N/A"}
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          }
          return null; // Return null if old and new values are the same.
        })}
      </div>
    );
  };

  // To use it:
  // <ChangesList version={versionData} fileUrls={fileUrlsData} />

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5 ">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Beta - Change Log
                    </Dialog.Title>
                    <ChangesList version={version} />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Go back to dashboard
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
