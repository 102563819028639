import React, { useEffect, useState } from 'react';
import { FieldArray, Field, ErrorMessage, useFormikContext } from 'formik';
import { organizationService } from '../../../services/organizationService';
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ShareProjectInfo = (userContextData) => {
  const { values, setFieldValue } = useFormikContext();
  const [organizationMembers, setOrganizationMembers] = useState([]);

  const organizationId = userContextData.userContextData.organisation;

  useEffect(() => {
    const fetchOrganizationMembers = async () => {
      try {
        const organization = await organizationService.getOrganization(organizationId);
        const members = organization.members.map((member) => ({
          _id: member.userId._id,
          name: member.userId.name,
          email: member.userId.email,
          role: member.role,
        }));
        setOrganizationMembers(members);
      } catch (error) {
        console.error('Error fetching organization members:', error);
      }
    };

    fetchOrganizationMembers();
  }, [organizationId]);

  const handleSelectAll = (arrayHelpers) => {
    if (values.admins.length === organizationMembers.length) {
      // If all are selected, deselect all
      setFieldValue('admins', []);
    } else {
      // Select all
      const allAdmins = organizationMembers.map((member) => ({
        _id: member._id,
        name: member.name,
        email: member.email,
      }));
      setFieldValue('admins', allAdmins);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Share Project</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Select the admins for this project from your organization members. If you would like to add new organisation members, please go to organisation page.
        </p>
      </div>
      <div className="md:col-span-2">
        <div className="mt-0 flow-root">
          <FieldArray
            name="admins"
            render={(arrayHelpers) => (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-sm font-semibold text-gray-900">Organization Members</h3>
                  <button
                    type="button"
                    onClick={() => handleSelectAll(arrayHelpers)}
                    className="text-sm font-medium text-[#2A9D8F] hover:text-[#238276] focus:outline-none focus:underline"
                  >
                    {values.admins.length === organizationMembers.length ? 'Deselect All' : 'Select All'}
                  </button>
                </div>
                <ul role="list" className="divide-y divide-gray-200">
                  {organizationMembers.map((member) => {
                    const isChecked = values.admins.some((admin) => admin._id === member._id);
                    return (
                      <li key={member._id} className="py-4">
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <UserCircleIcon className="h-8 w-8 text-gray-300" aria-hidden="true" />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900">{member.email}</p>
                            <p className="truncate text-sm text-gray-500">{member.role}</p>
                          </div>
                          <div className="flex items-center">
                            <Field
                              type="checkbox"
                              id={`admin-${member._id}`}
                              name="admins"
                              value={member._id}
                              checked={isChecked}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push({
                                    _id: member._id,
                                    name: member.name,
                                    email: member.email,
                                  });
                                } else {
                                  const idx = values.admins.findIndex((admin) => admin._id === member._id);
                                  if (idx !== -1) arrayHelpers.remove(idx);
                                }
                              }}
                              className="h-5 w-5 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                            />
                            <label htmlFor={`admin-${member._id}`} className="ml-2 block text-sm font-medium text-gray-900 cursor-pointer">
                              {isChecked ? 'Remove' : 'Add'} as Admin
                            </label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          />
        </div>
        <ErrorMessage name="admins" component="div" className="mt-2 text-sm text-red-600" />
      </div>
    </div>
  );
};

export default ShareProjectInfo;
