
import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const noteService = {
  getNotes: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/notes/project/${projectId}/notes`);
      return response.data;
    } catch (error) {
      console.error('Error fetching notes:', error.message);
      throw error;
    }
  },

  createNote: async (noteData) => {
    try {
      const response = await api.post(`${API_URL}/api/notes/notes`, noteData);
      return response.data;
    } catch (error) {
      console.error('Error adding note:', error.message);
      throw error;
    }
  },

  updateNote: async (noteId, updatedData) => {
    try {
      const response = await api.put(`${API_URL}/api/notes/notes/${noteId}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating note:', error.message);
      throw error;
    }
  },

  deleteNote: async (noteId) => {
    try {
      await api.delete(`${API_URL}/api/notes/notes/${noteId}`);
    } catch (error) {
      console.error('Error deleting note:', error.message);
      throw error;
    }
  }
};

export default noteService;

export const { getNotes, createNote, updateNote, deleteNote } = noteService;