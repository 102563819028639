import React, { useState } from 'react';
import SectionHeading from '../../UI/SectionHeading';
import { CreateProjectForm } from '../../Projects/CreateProject';

const ProjectCreatePage = ({ fetchProjects, fetchSharedProjects }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        <SectionHeading title={'Create Project'} />
        <CreateProjectForm fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />
      </div>
    </div>
  );
};

export default ProjectCreatePage;
