import React from 'react';
import { Field } from 'formik';

const projectStyles = [
  { id: 'contemporary', title: 'Contemporary' },
  { id: 'classic', title: 'Classic' },
  { id: 'country', title: 'Country' },
  { id: 'notSure', title: 'Not Sure' },
];

const ProjectStyle = () => {
  return (
    <div className="flex flex-col gap-y-10 border-b border-gray-900/10 pb-12 md:flex-row md:items-start">
      <div className="w-full md:w-1/3">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Your Project Style
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Select an option on style
        </p>
      </div>
      <div className="flex flex-col gap-y-8 w-full md:w-2/3">
        <div className="w-full">
          <label
            htmlFor="projectStyle"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Please choose a style
          </label>
          <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {projectStyles.map((style, i) => (
              <div key={i} className="col-span-1">
                <div className="flex items-center">
                  <Field
                    type="radio"
                    id={style.id}
                    name="projectStyle"
                    value={style.id}
                    className="h-4 w-4 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#264653]"
                  />
                  <label
                    htmlFor={style.id}
                    className="ml-3 text-sm leading-6 text-gray-900"
                  >
                    {style.title}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="styleComments"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Style Comments
          </label>
          <Field
            as="textarea"
            name="styleComments"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectStyle;
