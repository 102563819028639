import React from 'react';
import { Link } from 'react-router-dom';

const SuccessMessage = ({ text, projectId }) => {
  return (
    <div className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50" role="alert">
      <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Info</span>
      <div className="flex-grow ">
        <span className="font-medium">{text}</span> Please go to all projects to see all of your projects.
        <br />
      </div>
      {projectId && (
        <Link to={`/dashboard/project/summary/${projectId}`}>
          <button className="rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#264653]">
            View Project
          </button>
        </Link>
      )}
    </div>
  );
};

export default SuccessMessage;
