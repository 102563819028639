// AddNoteButton.jsx
import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

const AddNoteButton = ({ onAddNote }) => {
  const handleClick = () => {
    onAddNote();
  };

  return (
    <button
      onClick={handleClick}
      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
    >
      <PlusIcon className="h-5 w-5 mr-2" />
      Add Note
    </button>
  );
};

export default AddNoteButton;
