import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../App';
import { Spinner } from 'flowbite-react';

const WithAuthentication = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [isUserFetched, setIsUserFetched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (isAuthenticated && user && !isUserFetched) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/signup`, user);

          const userData = {
            ...user,
            auth0_id: user.sub,
            user_id: user.sub,
            projects: response.data.projects,
            organisation: response.data.organisation,
            type: response.data.type,
            role: response.data.role,
            name: response.data.name,
          };

          if (response.status === 200) {
            userData['registered'] = true;
          }

          setUserContextData(userData);
          setIsUserFetched(true);

          if (!userData.type) {
            navigate('/select-organisation-type');
          }
        } catch (error) {
          console.error('WithAuthentication: Error fetching user', error);
          setIsUserFetched(true);
        }
      } else if (!isAuthenticated && !isLoading) {
        loginWithRedirect();
      }
    };

    fetchUser();
  }, [isAuthenticated, isLoading, user, loginWithRedirect, setUserContextData, isUserFetched, navigate]);

  if (isLoading || (isAuthenticated && !isUserFetched)) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner aria-label="Loading spinner" color="info" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default WithAuthentication;
