import { format } from 'date-fns';

import axios from 'axios';
import { useState, useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';

const renderStatusCell = (status) => {
  let backgroundColor = '';
  let textColor = '';
  let ringColor = '';
  let displayStatus = '';

  switch (status) {
    case 'active':
      backgroundColor = 'bg-green-50';
      textColor = 'text-green-700';
      ringColor = 'ring-green-600';
      displayStatus = 'Active';
      break;
    case 'completed':
      backgroundColor = 'bg-blue-50';
      textColor = 'text-blue-700';
      ringColor = 'ring-blue-600';
      displayStatus = 'Completed';
      break;
    case 'onHold':
      backgroundColor = 'bg-orange-50';
      textColor = 'text-orange-700';
      ringColor = 'ring-orange-600';
      displayStatus = 'On Hold';
      break;
    case 'cancelled':
      backgroundColor = 'bg-red-50';
      textColor = 'text-red-700';
      ringColor = 'ring-red-600';
      displayStatus = 'Cancelled';
      break;
    case 'clientApproved':
      backgroundColor = 'bg-teal-50';
      textColor = 'text-teal-700';
      ringColor = 'ring-teal-600';
      displayStatus = 'Client Approved';
      break;
    case 'clientRejected':
      backgroundColor = 'bg-pink-50';
      textColor = 'text-pink-700';
      ringColor = 'ring-pink-600';
      displayStatus = 'Client Rejected';
      break;
    case 'clientReview':
    default:
      backgroundColor = 'bg-yellow-50';
      textColor = 'text-yellow-700';
      ringColor = 'ring-yellow-600';
      displayStatus = 'Client To Review';
      break;
  }

  return (
    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
      <span className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}/20`}>
        {displayStatus}
      </span>
    </td>
  );
};

export default function AllProjectsTable({ projects, fetchProjects }) {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const handleRowClick = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };
  function capitalizeRoles(rolesString) {
    if (!rolesString) {
      return '';
    }

    const roles = rolesString.split(', ');
    const uniqueRoles = [];

    for (const role of roles) {
      const capitalizedRole = role.charAt(0).toUpperCase() + role.slice(1);
      if (!uniqueRoles.includes(capitalizedRole)) {
        uniqueRoles.push(capitalizedRole);
      }
    }

    return uniqueRoles.join(', ');
  }

  const handleChooseProject = (projectId, status, role) => {
    setUserContextData({
      ...userContextData,
      selected_project: projectId,
      selected_project_status: status,
    });
    const routePath =
      status === 'clientReview' && (role === 'client' || role === 'Client' || role === 'client, client')
        ? `/dashboard/edit-project/${projectId}`
        : `/dashboard/project/summary/${projectId}`;

    navigate(routePath);
  };

  const filteredProjects = projects
    .filter(
      (project) =>
        project.jobReference.toLowerCase().includes(searchQuery.toLowerCase()) || project.clientName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      const getNumericPart = (jobRef) => parseInt(jobRef.replace(/\D/g, ''), 10);
      return getNumericPart(a.jobReference) - getNumericPart(b.jobReference);
    });

  return (
    <div className="h-screen">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Shared Projects</h1>
            <p className="mt-2 text-sm text-gray-700">These projects have been shared with you.</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-4 sm:w-1/2">
            <input
              type="text"
              placeholder="Search by Job Ref. or Client Name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" style={{ overflow: 'visible' }}>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 hidden sm:table-cell">
                      Your Role
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Job Ref.
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                      Client
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Project Manager
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                      Stage
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredProjects.map((project, index) => {
                    const startDate = new Date(project.projectStartDate);
                    const endDate = new Date(project.projectEndDate);
                    const formattedStartDate = format(startDate, 'dd MMM yyyy');
                    const formattedEndDate = format(endDate, 'dd MMM yyyy');

                    return (
                      <tr
                        key={index}
                        className="hover:bg-slate-100 cursor-pointer"
                        onClick={() => handleChooseProject(project._id, project.projectStatus, project.userRoles)}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0 hidden sm:table-cell">
                          {capitalizeRoles(project.userRoles)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{project.jobReference}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {project.clients && project.clients.length > 0 ? (
                            <ul>
                              {project.clients.map((client, clientIndex) => (
                                <li key={clientIndex}>{client.name}</li>
                              ))}
                            </ul>
                          ) : (
                            <span className="text-gray-400 italic">No clients</span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.projectManager}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">{project.projectStage}</td>
                        <td>{renderStatusCell(project.projectStatus)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
