import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { debounce } from 'lodash';
import axios from 'axios';
import imageService from '../../../services/imageService';
import Category from '../../Projects/Images/Category';
import Modal from '../../Projects/Images/Modal';
import ImageUploader from '../../Projects/Images/ImageUploader';
import api from '../../../utils/api';
import { Spinner } from 'flowbite-react';

const ProjectImagesPage = () => {
  const [categories, setCategories] = useState({
    Uncategorized: [],
    Inspiration: [],
    'Work in Progress': [],
    Completed: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const { projectId } = useParams();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    async function fetchImages() {
      try {
        const images = await imageService.getImages(projectId);
        const categorizedImages = {
          Uncategorized: [],
          Inspiration: [],
          'Work in Progress': [],
          Completed: [],
        };

        images.forEach((image) => {
          const category = image.category || 'Uncategorized';
          categorizedImages[category].push(image);
        });

        setCategories(categorizedImages);
      } catch (error) {
        console.error('Failed to fetch images:', error);
      }
    }

    fetchImages();
  }, [projectId]);

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentImage(null);
    setShowModal(false);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);

      setIsUploading(true);
      try {
        const newImage = await imageService.uploadImage(projectId, formData);
        setCategories((prevCategories) => ({
          ...prevCategories,
          Uncategorized: [...prevCategories.Uncategorized, newImage],
        }));
        window.location.reload();
      } catch (error) {
        console.error('Failed to upload image:', error);
        // Add error handling here, e.g., show an error message to the user
      } finally {
        setIsUploading(false);
      }
    },
    [projectId]
  );

  const handleDeleteImage = async (imageId) => {
    try {
      await imageService.deleteImage(imageId);
      setCategories((prevCategories) => {
        const updatedCategories = { ...prevCategories };
        for (const key in updatedCategories) {
          updatedCategories[key] = updatedCategories[key].filter((image) => image._id !== imageId);
        }
        return updatedCategories;
      });
    } catch (error) {
      console.error(`Failed to delete image with id: ${imageId}`, error);
    }
  };

  const handleDropImage = async (imageId, newCategory) => {
    let image;
    let oldCategory;

    setCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories };

      for (const key in updatedCategories) {
        const foundImage = updatedCategories[key].find((img) => img._id === imageId);
        if (foundImage) {
          image = foundImage;
          oldCategory = key;
          break;
        }
      }

      if (!image || oldCategory === newCategory) return prevCategories;

      updatedCategories[oldCategory] = updatedCategories[oldCategory].filter((img) => img._id !== imageId);

      updatedCategories[newCategory].push(image);

      return updatedCategories;
    });

    try {
      await imageService.updateImageCategory(projectId, imageId, newCategory);
    } catch (error) {
      console.error('Failed to update image category:', error);
    }
  };

  const debouncedUpdateOrder = useCallback(
    debounce(async (projectId, imageId, newOrder) => {
      try {
        await api.put(`${process.env.REACT_APP_SERVER_URL}/api/images/images/${projectId}/${imageId}/category-order`, { order: newOrder });
      } catch (error) {
        console.error('Failed to update image order:', error);
      }
    }, 500),
    []
  );

  const moveImage = (fromIndex, toIndex, fromCategory, toCategory) => {
    setCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories };
      const movedImage = updatedCategories[fromCategory].splice(fromIndex, 1)[0];
      updatedCategories[toCategory].splice(toIndex, 0, movedImage);

      if (fromCategory === toCategory) {
        updatedCategories[toCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
      } else {
        updatedCategories[fromCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
        updatedCategories[toCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
      }

      return updatedCategories;
    });
  };

  const handleSelectImage = async (imageId) => {
    try {
      await api.put(`${process.env.REACT_APP_SERVER_URL}/api/images/images/${imageId}`, {
        projectId,
        selected: true,
      });
      setCategories((prevCategories) => {
        const updatedCategories = { ...prevCategories };
        for (const key in updatedCategories) {
          updatedCategories[key] = updatedCategories[key].map((image) => ({
            ...image,
            selected: image._id === imageId,
          }));
        }
        return updatedCategories;
      });
    } catch (error) {
      console.error('Failed to select image:', error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-8 bg-gray-100 min-h-screen">
        {showModal && <Modal currentImage={currentImage} handleCloseModal={handleCloseModal} />}

        <div className="max-w-4xl mx-auto bg-white p-6 rounded-xl shadow-md">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900 mb-5 border-b pb-2">Project Images</h2>
          <ImageUploader onDrop={onDrop} isDragActive={false} />
          {isUploading && <Spinner />}

          <div className="grid grid-cols-1 gap-6">
            {Object.keys(categories).map((category) => (
              <Category
                key={category}
                name={category}
                images={categories[category]}
                onDropImage={handleDropImage}
                onImageClick={handleImageClick}
                onDeleteImage={handleDeleteImage}
                onSelectImage={handleSelectImage}
                moveImage={moveImage}
              />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ProjectImagesPage;
