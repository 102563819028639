import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../App';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SubMilestoneForm, MilestoneForm, MilestoneList, CSVUploadForm } from '../../Projects/Milestones';
import { PlusIcon } from '@heroicons/react/20/solid';
import useGetProjectMilestones from '../../Hooks/useGetProjectMilestones';
import {
  createMilestone,
  updateMilestone,
  deleteMilestone,
  createSubMilestone,
  updateSubMilestone,
  deleteSubMilestone,
  uploadMilestones,
} from '../../../services/milestoneService';

// Define the stages
export const stages = ['Creation Pack', 'Home Design Pack', 'Construction Pack', 'Planning Permission', 'Onsite Construction'];

export const categories = ['Design Milestones', 'Permissions Milestones', 'Support Milestones', 'Onsite Milestones'];

function formatDate(inputDate) {
  if (!inputDate) return 'Not completed';

  const date = new Date(inputDate);
  if (isNaN(date.getTime())) return 'Invalid date';

  const day = String(date.getDate()).padStart(2, '0');
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

const ProjectMilestonesPage = () => {
  const { projectId } = useParams();
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);
  const { userContextData } = useContext(UserContext);
  const { loading, error, milestones: initialMilestones, refetch: fetchMilestones } = useGetProjectMilestones(projectId);
  const [localMilestones, setLocalMilestones] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [newMilestoneTitle, setNewMilestoneTitle] = useState('');
  const [newMilestoneCategory, setNewMilestoneCategory] = useState('');
  const [newMilestoneStage, setNewMilestoneStage] = useState('');
  const [newMilestoneDeadline, setNewMilestoneDeadline] = useState('');
  const [keyMilestoneId, setKeyMilestoneId] = useState('');
  const [newSubMilestoneTitle, setNewSubMilestoneTitle] = useState('');
  const [newSubMilestoneStage, setNewSubMilestoneStage] = useState('');
  const [showUploadForm, setShowUploadForm] = useState(false);

  useEffect(() => {
    if (!loading && !error) {
      setLocalMilestones(initialMilestones);
    }
  }, [loading, error, initialMilestones]);

  const updateLocalMilestone = (updatedMilestone) => {
    setLocalMilestones((prevMilestones) => prevMilestones.map((milestone) => (milestone._id === updatedMilestone._id ? updatedMilestone : milestone)));
  };

  const handleStatusChange = async (milestoneId, subMilestoneId = null) => {
    try {
      let result;
      const currentDate = new Date().toISOString();

      if (subMilestoneId) {
        const milestone = localMilestones.find((m) => m._id === milestoneId);
        const subMilestone = milestone.subMilestones.find((sm) => sm._id === subMilestoneId);
        const newStatus = !subMilestone.status;
        result = await updateSubMilestone(milestoneId, subMilestoneId, {
          status: newStatus,
          completedOn: newStatus ? currentDate : null,
        });
      } else {
        const milestone = localMilestones.find((m) => m._id === milestoneId);
        const newStatus = !milestone.status;

        result = await updateMilestone(milestoneId, {
          status: newStatus,
          completedOn: newStatus ? currentDate : null,
        });
      }

      if (!result) {
        throw new Error('No result returned from update operation');
      }

      setLocalMilestones((prevMilestones) =>
        prevMilestones.map((milestone) =>
          milestone._id === milestoneId
            ? {
                ...milestone,
                ...(subMilestoneId
                  ? {
                      subMilestones: milestone.subMilestones.map((subMilestone) =>
                        subMilestone._id === subMilestoneId
                          ? { ...subMilestone, status: !subMilestone.status, completedOn: !subMilestone.status ? currentDate : null }
                          : subMilestone
                      ),
                    }
                  : { status: !milestone.status, completedOn: !milestone.status ? currentDate : null }),
              }
            : milestone
        )
      );
    } catch (error) {
      console.error('Failed to update status:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      setErrorMessage('Failed to update status: ' + error.message);
    }
  };

  const handleAddMilestone = async () => {
    try {
      const newMilestone = await createMilestone({
        title: newMilestoneTitle,
        category: newMilestoneCategory,
        stage: newMilestoneStage,
        projectId: projectId,
        deadline: newMilestoneDeadline,
      });
      setLocalMilestones([...localMilestones, newMilestone]);
      setNewMilestoneTitle('');
      setNewMilestoneStage('');
      setNewMilestoneCategory('');
      setNewMilestoneDeadline('');
      await fetchMilestones();
    } catch (error) {
      setErrorMessage('Failed to add milestone');
    }
  };

  const handleAddSubMilestone = async () => {
    if (!keyMilestoneId) {
      setErrorMessage('Please select a key milestone.');
      return;
    }
    try {
      await createSubMilestone(keyMilestoneId, {
        title: newSubMilestoneTitle,
      });
      await fetchMilestones();
      setNewSubMilestoneTitle('');
      setKeyMilestoneId('');
    } catch (error) {
      setErrorMessage('Failed to add sub-milestone');
    }
  };

  const handleDeleteMilestone = async (milestoneId) => {
    try {
      await deleteMilestone(milestoneId);
      await fetchMilestones();
    } catch (error) {
      setErrorMessage('Failed to delete milestone');
    }
  };

  const handleDeleteSubMilestone = async (milestoneId, subMilestoneId) => {
    try {
      await deleteSubMilestone(milestoneId, subMilestoneId);
      await fetchMilestones();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCSVUpload = async (file) => {
    try {
      await uploadMilestones(projectId, file);
      await fetchMilestones();
    } catch (error) {
      setErrorMessage('Failed to upload CSV');
    }
  };

  if (loading) {
    return <div>Loading milestones...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {!loading && !error && (
        <>
          <MilestoneList
            categories={categories}
            milestones={localMilestones}
            handleStatusChange={handleStatusChange}
            handleDeleteMilestone={handleDeleteMilestone}
            handleDeleteSubMilestone={handleDeleteSubMilestone}
            formatDate={formatDate}
            updateMilestone={updateLocalMilestone}
          />

          <div className="relative my-10">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <button
                type="button"
                onClick={() => setShowUploadForm(!showUploadForm)}
                className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {showUploadForm ? 'Hide Upload Form' : 'Upload New Milestone(s)'}
              </button>
            </div>
          </div>

          {showUploadForm && (
            <>
              <div className="flex mt-10">
                <div className="w-1/2 pr-4">
                  <div className="mb-4 p-4 bg-white rounded-lg shadow-md">
                    <MilestoneForm
                      handleAddMilestone={handleAddMilestone}
                      newMilestoneTitle={newMilestoneTitle}
                      setNewMilestoneTitle={setNewMilestoneTitle}
                      newMilestoneCategory={newMilestoneCategory}
                      setNewMilestoneCategory={setNewMilestoneCategory}
                      newMilestoneStage={newMilestoneStage}
                      setNewMilestoneStage={setNewMilestoneStage}
                      newMilestoneDeadline={newMilestoneDeadline}
                      setNewMilestoneDeadline={setNewMilestoneDeadline}
                    />
                  </div>
                </div>

                {localMilestones.length > 0 && (
                  <div className="w-1/2 pl-4">
                    <div className="mb-4 p-4 bg-white rounded-lg shadow-md">
                      <SubMilestoneForm
                        milestones={localMilestones}
                        handleAddSubMilestone={handleAddSubMilestone}
                        newSubMilestoneTitle={newSubMilestoneTitle}
                        setNewSubMilestoneTitle={setNewSubMilestoneTitle}
                        keyMilestoneId={keyMilestoneId}
                        setKeyMilestoneId={setKeyMilestoneId}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-10">
                <CSVUploadForm onFileUpload={handleCSVUpload} projectId={projectId} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectMilestonesPage;
