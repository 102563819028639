import { useState, useEffect } from "react";
import axios from "axios";
import api from "../../utils/api";

function useGetDefaultTasks(userId) {
  const [defaultTasks, setDefaultTasks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDefaultTasks = async () => {
    try {
      // Update this URL to point to your backend API endpoint for fetching user default tasks
      const response = await api.get(
        `${process.env.REACT_APP_SERVER_URL}/api/users/user/${userId}/defaultTasks`
      );

      // Assuming the server response contains the defaultTasks
      setDefaultTasks(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch default tasks if userId is available
    if (userId) {
      fetchDefaultTasks();
    }
  }, [userId]);

  return { defaultTasks, loading, error, fetchDefaultTasks };
}

export default useGetDefaultTasks;
