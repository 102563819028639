import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';

const PropertyInfo = ({ disableUpdateEmails }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [displayBudgets, setDisplayBudgets] = useState([]);

  // Function to format number with commas
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const newDisplayBudgets = values.phases.map((phase) => {
      return phase.budget ? numberWithCommas(phase.budget) : '';
    });
    setDisplayBudgets(newDisplayBudgets);
  }, [values.phases]);

  const handleBudgetChange = (e, index) => {
    const numValue = parseInt(e.target.value.replace(/,/g, ''), 10);
    setFieldValue(`phases.${index}.budget`, isNaN(numValue) ? '' : numValue);
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  useEffect(() => {
    let numberOfPhases = values.numberOfPhases || 0;
    if (numberOfPhases < 1) {
      numberOfPhases = 1;
    }

    if (values.phases.length < numberOfPhases) {
      const diff = numberOfPhases - values.phases.length;
      for (let i = 0; i < diff; i++) {
        values.phases.push({ name: '', budget: '' });
      }
    } else if (values.phases.length > numberOfPhases) {
      values.phases = values.phases.slice(0, numberOfPhases);
    }
  }, [values.numberOfPhases]);

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Project Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 max-w-sm">
          This will act as a guidance for where your project will roughly sit.
          Your project budget will be for construction, refurbishment and
          decoration costs. It is exclusive of VAT and does not include design
          or professional services.
        </p>
      </div>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-6">
          <label
            htmlFor="propertyAddress"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Property Address
          </label>
          <Field
            as="textarea"
            placeholder="Enter the property address here"
            name="propertyAddress"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="propertyAddress"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="type"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Property Type
          </label>
          <Field
            as="select"
            name="type"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          >
            <option value="">Select Type...</option>
            <option value="Detached">Detached</option>
            <option value="Semi-detached">Semi-detached</option>
            <option value="Terraced">Terraced</option>
            <option value="Flat">Flat</option>
            <option value="Bungalow">Bungalow</option>
            <option value="Commercial">Commercial</option>
          </Field>
          <ErrorMessage
            name="type"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="bedrooms"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Number of Bedrooms
          </label>
          <Field
            type="number"
            name="bedrooms"
            onWheel={handleWheel}
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="bedrooms"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="age"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Age of Property
          </label>
          <Field
            type="text"
            name="age"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="age"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="style"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Style of Property
          </label>
          <Field
            type="text"
            name="style"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="style"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="condition"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Property Condition
          </label>
          <Field
            as="textarea"
            placeholder="Enter the condition of the property here"
            name="condition"
            className=" mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="condition"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        {/* totalBudget */}
        <div className="sm:col-span-6">
          <label
            htmlFor="totalBudget"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Total Budget (£)
          </label>
          <Field
            type="number"
            name="totalBudget"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
            onWheel={handleWheel} // Add this line to disable scrolling on the input
          />
          <p className="mt-1 text-xs text-gray-500 italic">
            Please leave blank if the budget is not set.
          </p>
          <ErrorMessage
            name="totalBudget"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        {/* // furtherInformation on Budget */}
        <div className="sm:col-span-6">
          <label
            htmlFor="furtherBudgetInformation"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Further Budget Information
          </label>
          <Field
            as="textarea"
            placeholder="Enter further information on the budget here"
            name="furtherBudgetInformation"
            className=" mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="furtherBudgetInformation"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-6">
          <label
            htmlFor="numberOfPhases"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Number of Phases
          </label>
          <Field
            as="select"
            name="numberOfPhases"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          >
            {/* <option value="">Select...</option> */}
            {[...Array(8)].map((e, i) => (
              <option key={i} value={i + 1}>
                {i + 1 === 1 ? `${i + 1} Phase` : `${i + 1} Phases`}
              </option>
            ))}
          </Field>
          <p className="mt-1 text-xs text-gray-500 italic">
            Do you plan to undertake this project in one or multiple phases? You
            may wish to complete part of the project.
          </p>
          <ErrorMessage
            name="numberOfPhases"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>

        {/* Description of phases */}
        <div className="sm:col-span-6">
          <label
            htmlFor="phasesDescription"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Description of Phases
          </label>
          <Field
            as="textarea"
            placeholder="Enter the description of the phases here"
            name="phasesDescription"
            className=" mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="phasesDescription"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>

        {/* <FieldArray name="phases">
          {({ push, remove }) => (
            <div className="sm:col-span-6 gap-x-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6 md:col-span-2">
                {values.phases && values.phases.length > 0
                  ? values.phases.map((phase, index) => (
                      <div
                        key={index}
                        className="sm:col-span-6 flex justify-between space-x-2"
                      >
                        <div className="flex-1">
                          <label
                            htmlFor={`phases.${index}.name`}
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Phase {index + 1}: Name
                          </label>
                          <Field
                            type="text"
                            name={`phases.${index}.name`}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
                          />
                          <ErrorMessage
                            name={`phases.${index}.name`}
                            component="div"
                            className="text-xs text-red-500 italic"
                          />
                        </div>
                        <div className="flex-1">
                          <label
                            htmlFor={`phases.${index}.budget`}
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Phase {index + 1}: Budget (£)
                          </label>
                          <input
                            type="text"
                            value={displayBudgets[index] || ""}
                            onChange={(e) => {
                              const cleanValue = e.target.value.replace(
                                /,/g,
                                ""
                              );
                              if (
                                /^\d+$/.test(cleanValue) ||
                                cleanValue === ""
                              ) {
                                handleBudgetChange(e, index);
                                setDisplayBudgets({
                                  ...displayBudgets,
                                  [index]: numberWithCommas(cleanValue),
                                });
                              }
                            }}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
                          />
                          <ErrorMessage
                            name={`phases.${index}.budget`}
                            component="div"
                            className="text-xs text-red-500 italic"
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}
        </FieldArray> */}
        <div className="sm:col-span-3">
          <label
            htmlFor="projectStartDate"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Project Start Date *
          </label>
          <Field
            type="date"
            name="projectStartDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <p className="mt-1 text-xs text-gray-500 italic">
            Please indicate in this section when you would like to begin
            building works on site, if known.
          </p>
          <ErrorMessage
            name="projectStartDate"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="projectEndDate"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Target Project Finish Date *
          </label>
          <Field
            type="date"
            name="projectEndDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />

          <p className="mt-1 text-xs text-gray-500 italic">
            Please indicate when you preference of project completion, if known.
          </p>
          <ErrorMessage
            name="projectEndDate"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
        <div className="sm:col-span-6">
          <label
            htmlFor="projectDescription"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Additional Information
          </label>
          <Field
            as="textarea"
            placeholder="Please provide any additional information that you think may be useful for us to know."
            name="projectDescription"
            className=" mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="projectDescription"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
