import React from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import StatusLabel from '../../UI/StatusLabel';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const SectionHeading = ({ project, title, fetchProjects }) => {
  const navigate = useNavigate();
  const handleProjectDelete = async (project) => {
    // confirm
    if (!window.confirm('Are you sure you want to delete this project?')) {
      return;
    }

    const res = await api.delete(process.env.REACT_APP_SERVER_URL + '/api/projects/deleteProject', {
      data: {
        projectId: project.projectId,
      },
    });

    // reload page
    if (res.status === 200) {
      navigate(`/dashboard/projects`);
      window.location.reload();
    }
  };

  const handleStatusUpdate = (field, newValue) => {
    project[field] = newValue;
    // You might want to trigger a re-render or update state here
    fetchProjects();
  };

  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <div className="flex">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
        <div className="ml-5">
          <StatusLabel project={project} status={project?.projectStatus} stage={project?.projectStage} onUpdate={handleStatusUpdate} />
        </div>
        <div className="ml-5">
          <button
            type="button"
            onClick={() => handleProjectDelete(project)}
            className="inline-flex items-center rounded-md bg-red-500 px-2 py-1.5 text-[8px] font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FolderIcon className="h-3 w-3 mr-2" aria-hidden="true" />
            Delete Project
          </button>
        </div>
      </div>
      <div className="mt-3 sm:ml-4 sm:mt-0">
        {/* <Link to={`/dashboard/edit-project/${project?._id}`}>
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FolderIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Edit Project
          </button>
        </Link> */}
      </div>
    </div>
  );
};

export default SectionHeading;
