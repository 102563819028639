import React from 'react';
import SharedProjectsTable from '../../Projects/Tables/SharedProjectsTable';
import EmptyProjectsMessage from '../../Projects/Common/EmptyProjectsMessage';

const ProjectSharedPage = ({ projects, fetchProjects }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        {projects?.length > 0 ? <SharedProjectsTable projects={projects} fetchProjects={fetchProjects} /> : <EmptyProjectsMessage />}
      </div>
    </div>
  );
};

export default ProjectSharedPage;
