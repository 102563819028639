import React from 'react';

const Modal = ({ currentImage, handleCloseModal }) => {
  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center transition-opacity duration-300">
      <div className="relative w-full h-full flex items-center justify-center">
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-white bg-red-600 rounded-full p-2 hover:bg-red-700 focus:outline-none transition"
        >
          Close
        </button>
        <img src={currentImage} alt="Full screen view" className="max-w-full max-h-full" />
      </div>
    </div>
  );
};

export default Modal;
