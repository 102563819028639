import React, { useState } from 'react';
import { UserCircleIcon, ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid';

export default function Clients({ clients }) {
  const [copiedEmail, setCopiedEmail] = useState(null);

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedEmail(email);
      setTimeout(() => setCopiedEmail(null), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Organisation Clients</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">List of organisation clients.</p>
      </div>
      <div className="border-t border-gray-200">
        {clients.length > 0 ? (
          <ul role="list" className="divide-y divide-gray-200">
            {clients.map((client) => (
              <li key={client._id} className="px-4 py-4 sm:px-6">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <UserCircleIcon className="h-8 w-8 text-gray-300" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">{client.name}</dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 text-sm text-gray-900 flex items-center">
                          {client.email}
                          <button
                            onClick={() => copyToClipboard(client.email)}
                            className="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                            title="Copy email to clipboard"
                          >
                            {copiedEmail === client.email ? (
                              <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" />
                            ) : (
                              <ClipboardDocumentIcon className="h-5 w-5" />
                            )}
                          </button>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Phone</dt>
                        <dd className="mt-1 text-sm text-gray-900">{client.phone || 'Not provided'}</dd>
                      </div>
                    </div>
                    <div className="mt-2">
                      <dt className="text-sm font-medium text-gray-500">Address</dt>
                      <dd className="mt-1 text-sm text-gray-900">{client.address || 'Not provided'}</dd>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="px-4 py-5 sm:px-6 text-sm text-gray-500">No clients found.</p>
        )}
      </div>
    </div>
  );
}
