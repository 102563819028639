// useGetProjectContacts.js

import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import api from "../../utils/api";

function useGetProjectContacts(projectId) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchContacts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_SERVER_URL}/api/contacts/project/${projectId}/contacts`
      );
      setContacts(response.data); 
      setError(null); 
    } catch (err) {
    
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchContacts();
    }
  }, [fetchContacts, projectId]);

  return { contacts, loading, error, refetch: fetchContacts };
}

export default useGetProjectContacts;
