import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import TaskStatusLabel from "./TaskStatusLabel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getStartDayOfMonth(month, year) {
  let day = new Date(year, month, 1).getDay();
  return day === 0 ? 6 : day - 1; // Adjusting Sunday to be 6 and others shifted by 1.
}

function getDaysInMonth(month, year) {
  if (month === 1 && isLeapYear(year)) {
    return 29;
  }
  return new Date(year, month + 1, 0).getDate();
}

function getDatesForMonth(month, year) {
  const today = new Date();
  const daysInMonth = getDaysInMonth(month, year);
  const daysArray = [];

  for (let i = 1; i <= daysInMonth; i++) {
    const dayDate = new Date(year, month, i);
    daysArray.push({
      date: `${year}-${String(month + 1).padStart(2, "0")}-${String(i).padStart(
        2,
        "0"
      )}`,
      isToday:
        dayDate.getDate() === today.getDate() &&
        dayDate.getMonth() === today.getMonth() &&
        dayDate.getFullYear() === today.getFullYear(),
      isCurrentMonth: true,
    });
  }

  return daysArray;
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

const isTaskOverdue = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison
  const givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);
  return today > givenDate;
};

export default function TasksCalendar({ tasks, fetchTasks }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  const [selectedDate, setSelectedDate] = useState(null);

  const upcomingTasks = tasks
    .filter((task) => new Date(task.endDate) >= currentDate)
    .sort((a, b) => new Date(a.endDate) - new Date(b.endDate));

  const getTasksForDate = (date) =>
    tasks.filter((task) => task.endDate === date);
  const hasTasksOnDate = (date) => tasks.some((task) => task.endDate === date);

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => {
      let newYear = prevDate.getFullYear();
      let newMonth = prevDate.getMonth() - 1;

      // If previous month was January, adjust to December of the previous year
      if (newMonth < 0) {
        newMonth = 11;
        newYear = newYear - 1;
      }

      return new Date(newYear, newMonth);
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      let newYear = prevDate.getFullYear();
      let newMonth = prevDate.getMonth() + 1;

      // If previous month was December, adjust to January of the next year
      if (newMonth > 11) {
        newMonth = 0;
        newYear = newYear + 1;
      }

      return new Date(newYear, newMonth);
    });
  };

  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let nextMonth = currentMonth + 1;
  let nextYear = currentYear;

  if (nextMonth > 11) {
    nextMonth = 0;
    nextYear = currentYear + 1;
  }

  const months = [
    {
      name: new Date(currentYear, currentMonth).toLocaleString("default", {
        month: "long",
      }),
      days: getDatesForMonth(currentMonth, currentYear),
    },
    {
      name: new Date(nextYear, nextMonth).toLocaleString("default", {
        month: "long",
      }),
      days: getDatesForMonth(nextMonth, nextYear),
    },
  ];

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison

  // Categorize tasks into missed, today, and next 7 days
  const missedTasks = tasks.filter(
    (task) => new Date(task.endDate) < new Date() && task.status !== "Completed"
  );
  const todayTasks = tasks.filter(
    (task) => new Date(task.endDate).toDateString() === today.toDateString()
  );
  const next7DaysTasks = tasks.filter((task) => {
    const taskDate = new Date(task.endDate);
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 7);
    return taskDate > today && taskDate <= oneWeekLater;
  });

  function getStatusColor(status) {
    switch (status) {
      case "Not Started":
        return "text-red-500";
      case "In Progress":
        return "text-yellow-500";
      case "Completed":
        return "text-green-500";
      default:
        return "text-gray-500";
    }
  }

  const handleStatusChange = (updatedTask) => {
    const updatedTasks = tasks.map((task) => {
      if (task._id === updatedTask._id) {
        return {
          ...updatedTask,
          completionDate:
            updatedTask.status === "Completed" ? new Date() : null,
        };
      }
      return task;
    });
  };

  return (
    <div>
      <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2">
        <button
          type="button"
          onClick={handlePreviousMonth}
          className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={handleNextMonth}
          className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {months.map((month, monthIdx) => (
          <section
            key={monthIdx}
            className={classNames(
              monthIdx === months.length - 1 && "hidden md:block",
              "text-center"
            )}
          >
            <h2 className="text-sm font-semibold text-gray-900">
              {month.name}
            </h2>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>Th</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {(() => {
                const startDay = getStartDayOfMonth(
                  currentDate.getMonth() + monthIdx,
                  currentDate.getFullYear()
                );

                let prevMonth = currentDate.getMonth() + monthIdx - 1;
                let prevYear = currentDate.getFullYear();
                if (prevMonth < 0) {
                  prevMonth = 11;
                  prevYear -= 1;
                }

                const prevMonthDays = getDatesForMonth(prevMonth, prevYear).map(
                  (day) => ({ ...day, isCurrentMonth: false })
                );

                let nextMonth = currentDate.getMonth() + monthIdx + 1;
                let nextYear = currentDate.getFullYear();
                if (nextMonth > 11) {
                  nextMonth = 0;
                  nextYear += 1;
                }

                const nextMonthDays = getDatesForMonth(nextMonth, nextYear).map(
                  (day) => ({ ...day, isCurrentMonth: false })
                );

                const daysFromPrevMonthToDisplay = prevMonthDays.slice(
                  -startDay
                );

                const daysFromNextMonthToDisplay =
                  42 - (daysFromPrevMonthToDisplay.length + month.days.length);

                const daysToDisplay = [
                  ...daysFromPrevMonthToDisplay,
                  ...month.days,
                  ...nextMonthDays.slice(0, daysFromNextMonthToDisplay),
                ];

                // const daysToDisplay = [
                //   ...prevMonthDays.slice(-startDay),
                //   ...month.days,
                //   ...nextMonthDays,

                // ].slice(0, 42);

                // const daysFromPrevMonthToDisplay = prevMonthDays.slice(
                //   -startDay
                // );

                // const daysFromNextMonthToDisplay =
                //   42 - (daysFromPrevMonthToDisplay.length + month.days.length);

                // const daysToDisplay = [
                //   ...daysFromPrevMonthToDisplay,
                //   ...month.days,
                //   ...nextMonthDays.slice(0, daysFromNextMonthToDisplay),
                // ];

                // daysToDisplay = daysToDisplay.slice(0, 42);

                return daysToDisplay.map((day, dayIdx) => (
                  <button
                    key={day.date}
                    type="button"
                    onClick={() => setSelectedDate(day.date)} // Set the selected date
                    className={classNames(
                      day.isCurrentMonth
                        ? "bg-white text-gray-900 relative"
                        : "bg-gray-50 text-gray-400 relative",
                      "relative py-1.5 hover:bg-gray-100 focus:z-10"
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={classNames(
                        day.isToday && day.isCurrentMonth
                          ? "bg-[#2A9D8F] font-semibold text-white"
                          : day.date === selectedDate
                          ? "border-green-500 border-2 bg-transparent text-gray-900"
                          : "",
                        "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                      )}
                    >
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>
                    {/* Display a little dot if the date has tasks */}
                    {hasTasksOnDate(day.date) && day.isCurrentMonth && (
                      <span className="absolute top-2.5 left-1/2 transform -translate-x-1/2 w-1 h-1 bg-green-500 rounded-full"></span>
                    )}
                  </button>
                ));
              })()}
            </div>
          </section>
        ))}
      </div>

      <div className="flex space-x-4 mt-12">
        {/* Render Missed Tasks */}
        <section className="w-1/3 p-4 border rounded-md shadow-lg bg-white">
          <h2 className="text-lg font-semibold leading-6 text-gray-900">
            Missed Tasks
          </h2>
          <ol className="mt-4 divide-y divide-gray-200">
            {missedTasks.length === 0 ? (
              <li className="py-4">
                <p className="text-sm text-gray-500">No missed tasks.</p>
              </li>
            ) : (
              missedTasks.map((task, index) => (
                <li key={task._id} className="py-4">
                  <time
                    dateTime={task.endDate}
                    className="block text-sm text-gray-500"
                  >
                    {new Date(task.endDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <p className="mt-2 text-gray-700">
                    <strong>{task.name}</strong> <br />
                    Job: {task.projectId.jobReference} | Client:{" "}
                    {task.projectId.clientName}
                  </p>

                  <div className="py-2 text-gray-700 flex spacex-2">
                    Status:
                    <div className="ml-2">
                      <TaskStatusLabel
                        task={task}
                        fetchTasks={fetchTasks} // you should pass down this function if it's not defined in this component
                        index={index}
                        totalCount={missedTasks.length}
                        onStatusChange={handleStatusChange}
                      />
                    </div>
                  </div>
                </li>
              ))
            )}
          </ol>
        </section>

        <section className="w-1/3 p-4 border rounded-md shadow-lg bg-white">
          <h2 className="text-lg font-semibold leading-6 text-gray-900">
            Today's Tasks
          </h2>
          <ol className="mt-4 divide-y divide-gray-200">
            {todayTasks.length === 0 ? (
              <li className="py-4">
                <p className="text-sm text-gray-500">
                  Nothing on today’s schedule
                </p>
              </li>
            ) : (
              todayTasks.map((task, index) => (
                <li key={task._id} className="py-4">
                  <time
                    dateTime={task.endDate}
                    className="block text-sm text-gray-500"
                  >
                    {new Date(task.endDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <p className="mt-2 text-gray-700">
                    <strong>{task.name}</strong> <br />
                    Job: {task.projectId.jobReference} | Client:{" "}
                    {task.projectId.clientName}
                  </p>

                  <div className="py-2 text-gray-700 flex spacex-2">
                    Status:
                    <div className="ml-2">
                      <TaskStatusLabel
                        task={task}
                        fetchTasks={fetchTasks} // you should pass down this function if it's not defined in this component
                        index={index}
                        totalCount={missedTasks.length}
                        onStatusChange={handleStatusChange}
                      />
                    </div>
                  </div>
                </li>
              ))
            )}
          </ol>
        </section>

        <section className="w-1/3 p-4 border rounded-md shadow-lg bg-white">
          <h2 className="text-lg font-semibold leading-6 text-gray-900">
            Next 7 Days Tasks
          </h2>
          <ol className="mt-4 divide-y divide-gray-200">
            {next7DaysTasks.length === 0 ? (
              <li className="py-4">
                <p className="text-sm text-gray-500">
                  No tasks in the next 7 days.
                </p>
              </li>
            ) : (
              next7DaysTasks.map((task, index) => (
                <li key={task._id} className="py-4">
                  <time
                    dateTime={task.endDate}
                    className="block text-sm text-gray-500"
                  >
                    {new Date(task.endDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <p className="mt-2 text-gray-700">
                    <strong>{task.name}</strong> <br />
                    Job: {task.projectId.jobReference} | Client:{" "}
                    {task.projectId.clientName}
                  </p>

                  <div className="py-2 text-gray-700 flex spacex-2">
                    Status:
                    <div className="ml-2">
                      <TaskStatusLabel
                        task={task}
                        fetchTasks={fetchTasks} // you should pass down this function if it's not defined in this component
                        index={index}
                        totalCount={missedTasks.length}
                        onStatusChange={handleStatusChange}
                      />
                    </div>
                  </div>
                </li>
              ))
            )}
          </ol>
        </section>

        {/* Render Today's Tasks */}
        {/* ... Similar to the above section ... */}

        {/* Render Next 7 Days Tasks */}
        {/* ... Similar to the above section ... */}
      </div>

      {selectedDate && (
        <section className="mt-12">
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Tasks for{" "}
            {new Date(selectedDate).toLocaleDateString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
            {getTasksForDate(selectedDate).length === 0 ? (
              <li className="py-4 sm:flex">
                <p className="mt-2 flex-auto sm:mt-0">
                  No tasks for this date.
                </p>
              </li>
            ) : (
              getTasksForDate(selectedDate).map((task) => (
                <li key={task.id} className="py-4 sm:flex">
                  <time dateTime={task.endDate} className="w-28 flex-none">
                    {new Date(task.endDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <p className="mt-2 flex-auto sm:mt-0">
                    {task.name} | Job: {task.projectId.jobReference} | Client:{" "}
                    {task.projectId.clientName}
                  </p>
                </li>
              ))
            )}
          </ol>
        </section>
      )}
      {/* Render Missed Tasks */}
      {/* <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Missed Tasks
        </h2>
        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          {missedTasks.length === 0 ? (
            <li className="py-4 sm:flex">
              <p className="mt-2 flex-auto sm:mt-0">
                Nothing on today’s schedule
              </p>
            </li>
          ) : (
            missedTasks.map((task) => (
              <li key={task._id} className="py-4 sm:flex">
                <time dateTime={task.endDate} className="w-28 flex-none">
                  {new Date(task.endDate).toLocaleDateString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  })}
                </time>
                <p className="mt-2 flex-auto sm:mt-0">
                  {task.name} | Job: {task.projectId.jobReference} | Client:{" "}
                  {task.projectId.clientName}
                </p>
              </li>
            ))
          )}
        </ol>
      </section> */}

      {/* Render Today's Tasks */}
      {/* <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Today's Tasks
        </h2>
        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          {todayTasks.length === 0 ? (
            <li className="py-4 sm:flex">
              <p className="mt-2 flex-auto sm:mt-0">
                Nothing on today’s schedule
              </p>
            </li>
          ) : (
            todayTasks.map((task) => (
              <li key={task._id} className="py-4 sm:flex">
                <time dateTime={task.endDate} className="w-28 flex-none">
                  {new Date(task.endDate).toLocaleDateString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  })}
                </time>
                <p className="mt-2 flex-auto sm:mt-0">
                  {task.name} | Job: {task.projectId.jobReference} | Client:{" "}
                  {task.projectId.clientName}
                </p>
              </li>
            ))
          )}
        </ol>
      </section> */}

      {/* Render Next 7 Days Tasks  */}
      {/* <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Next 7 Days Tasks
        </h2>
        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          {next7DaysTasks.length === 0 ? (
            <li className="py-4 sm:flex">
              <p className="mt-2 flex-auto sm:mt-0">
                Nothing on today’s schedule
              </p>
            </li>
          ) : (
            next7DaysTasks.map((task) => (
              <li key={task._id} className="py-4 sm:flex">
                <time dateTime={task.endDate} className="w-28 flex-none">
                  {new Date(task.endDate).toLocaleDateString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  })}
                </time>
                <p className="mt-2 flex-auto sm:mt-0">
                  {task.name} | Job: {task.projectId.jobReference} | Client:{" "}
                  {task.projectId.clientName}
                </p>
              </li>
            ))
          )}
        </ol>
      </section> */}
    </div>
  );
}
