import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const organizationService = {
  getOrganization: async (orgId) => {
    try {
      const response = await api.get(`${API_URL}/api/organisations/${orgId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching organization details:', error.message);
      throw error;
    }
  },

  getOrganizationContacts: async (orgId) => {
    try {
      const response = await api.get(`${API_URL}/api/contacts/contacts/getOrganisationContacts/${orgId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching organization contacts:', error.message);
      throw error;
    }
  },

  getOrganizationClients: async (orgId) => {
    try {
      const response = await api.get(`${API_URL}/api/organisations/${orgId}/clients`);
      return response.data;
    } catch (error) {
      console.error('Error fetching organization clients:', error.message);
      throw error;
    }
  },

  removeMember: async (orgId, userId) => {
    try {
      const response = await api.post(`${API_URL}/api/organisations/remove-member`, {
        organisationId: orgId,
        userId: userId,
      });
      return response.data;
    } catch (error) {
      console.error('Error removing member:', error.message);
      throw error;
    }
  }
};

export default organizationService;