import React, { useState, useEffect, useCallback } from 'react';
import { TrashIcon, PencilIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import SlideOver from './SlideOver';
import { deleteChangeRequest } from '../../../services/changeRequestService';

function ChangeRequestTable({ projectId, changeRequests: initialChangeRequests, refetch }) {
  const [changeRequests, setChangeRequests] = useState(initialChangeRequests);
  const [selectedChangeRequest, setSelectedChangeRequest] = useState(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);

  useEffect(() => {
    setChangeRequests(initialChangeRequests);
  }, [initialChangeRequests]);

  const handleRowClick = (changeRequest) => {
    setSelectedChangeRequest(changeRequest);
    setIsSlideOverOpen(true);
  };

  const handleDelete = async (e, changeRequest) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this change request?')) {
      try {
        await deleteChangeRequest(changeRequest._id);
        await refetch();
      } catch (error) {
        console.error('Error deleting change request:', error);
        alert('Failed to delete change request');
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Approved':
        return 'bg-green-100 text-green-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      case 'Completed':
        return 'bg-[#2A9D8F] text-white';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSlideOverClose = useCallback(async () => {
    setIsSlideOverOpen(false);
    await refetch();
  }, [refetch]);

  const handleChangeRequestUpdate = useCallback((updatedChangeRequest) => {
    setChangeRequests((prevChangeRequests) => prevChangeRequests.map((cr) => (cr._id === updatedChangeRequest._id ? updatedChangeRequest : cr)));
    setSelectedChangeRequest(updatedChangeRequest);
  }, []);

  const EmptyState = () => (
    <div className="text-center py-12">
      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No change requests</h3>
      <p className="mt-1 text-sm text-gray-500">Change requests for this project will appear here.</p>
    </div>
  );

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Change Requests</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">A list of all change requests for this project.</p>
      </div>
      <div className="border-t border-gray-200">
        {changeRequests.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Document
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action Items
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {changeRequests.map((changeRequest) => {
                const totalActionItems = changeRequest.actionItems.length;
                const completedCount = changeRequest.actionItems.filter((item) => item.status === 'Completed').length;

                return (
                  <tr key={changeRequest._id} onClick={() => handleRowClick(changeRequest)} className="hover:bg-gray-50 cursor-pointer">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {changeRequest.title.length > 30 ? `${changeRequest.title.slice(0, 30)}...` : changeRequest.title}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{changeRequest.isDocumentRelated ? 'Yes' : 'No'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{`${completedCount} / ${totalActionItems}`}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(changeRequest.status)}`}>
                        {changeRequest.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button onClick={(e) => handleDelete(e, changeRequest)} className="text-red-600 hover:text-red-900 mr-2">
                        <TrashIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(changeRequest);
                        }}
                        className="text-[#2A9D8F] hover:text-[#2A9D8F]"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyState />
        )}
      </div>
      <SlideOver
        open={isSlideOverOpen}
        setOpen={setIsSlideOverOpen}
        data={selectedChangeRequest}
        projectId={projectId}
        refetch={refetch}
        onClose={handleSlideOverClose}
        onUpdate={handleChangeRequestUpdate}
      />
    </div>
  );
}

export default ChangeRequestTable;
