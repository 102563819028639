// NoteSlideOver.jsx
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import { updateNote, createNote, deleteNote } from '../../../services/noteService';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../../App';

export default function NoteSlideOver({ open, setOpen, data, projectId, refetch, onClose, onUpdate }) {
  const [note, setNote] = useState(data || { title: '', content: '', changeRequested: false });
  const [isLoading, setIsLoading] = useState(false);
  const { userContextData } = useContext(UserContext);

  useEffect(() => {
    setNote(data || { title: '', content: '', changeRequested: false });
  }, [data]);

  const handleInputChange = (field, value) => {
    setNote((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const noteData = {
        title: note.title,
        content: note.content,
        projectId,
        authorId: userContextData.user_id,
        changeRequested: note.changeRequested,
      };

      let updatedNote;
      if (note._id) {
        updatedNote = await updateNote(note._id, noteData);
        toast.success('Note updated successfully');
      } else {
        updatedNote = await createNote(noteData);
        toast.success('Note created successfully');
      }
      onUpdate(updatedNote);
      await refetch();
    } catch (error) {
      console.error('Error saving note:', error);
      toast.error(`Failed to ${note._id ? 'update' : 'create'} note`);
    } finally {
      setIsLoading(false);
      setOpen(false);
      onClose();
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      setIsLoading(true);
      try {
        await deleteNote(note._id);
        toast.success('Note deleted successfully');
        await refetch();
      } catch (error) {
        console.error('Error deleting note:', error);
        toast.error('Failed to delete note');
      } finally {
        setIsLoading(false);
        setOpen(false);
        onClose();
      }
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{note._id ? 'Edit Note' : 'Add New Note'}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2"
                            onClick={() => {
                              setOpen(false);
                              onClose();
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="space-y-6">
                        <div>
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={note.title}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                          />
                        </div>
                        <div>
                          <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                            Content
                          </label>
                          <textarea
                            name="content"
                            id="content"
                            rows={4}
                            value={note.content}
                            onChange={(e) => handleInputChange('content', e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                          />
                        </div>
                        <div className="flex items-center">
                          <input
                            id="changeRequested"
                            name="changeRequested"
                            type="checkbox"
                            checked={note.changeRequested}
                            onChange={(e) => handleInputChange('changeRequested', e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                          />
                          <label htmlFor="changeRequested" className="ml-2 block text-sm text-gray-900">
                            Change Requested
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6 px-4 sm:px-6">
                      {note._id && (
                        <button
                          type="button"
                          onClick={handleDelete}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <TrashIcon className="h-4 w-4 mr-2" />
                          Delete
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={handleSave}
                        disabled={isLoading}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                      >
                        <CheckIcon className="h-4 w-4 mr-2" />
                        {note._id ? 'Update' : 'Create'}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
