import { Formik, Form } from 'formik';
import axios from 'axios';
import { useContext, useState } from 'react';
import { UserContext } from '../../../App';
import ClientInfoSection from '../CreateProject/ClientInformation';
import PropertyInfo from '../CreateProject/PropertyInfo';
import OnlineInfo from '../CreateProject/OnlineInfo';
import InternalInfo from '../CreateProject/InternalInfo';
import EditSuccessMessage from '../../UI/EditSuccessMessage';
import ShareProjectInfo from '../CreateProject/ShareProjectInfo';
import DocumentsSection from '../CreateProject/DocumentsSection';
import SubmitButton from '../CreateProject/SubmitButton';
import useGetUserRole from '../../Hooks/useGetUserRole';
import { updateProject } from '../../../services/projectService';

// Edit Specific Imports
import ProjectStyle from './ProjectStyle';
import AdditionalInfo from './AdditionalInfo';
import { useLocation } from 'react-router-dom';

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    // 'clientName',
    // 'projectStatus',
    // 'projectManager',
    // 'projectStartDate',
    // 'projectEndDate',
    // 'jobReference',
  ];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const EditProjectForm = ({ project, fetchProjects, fetchSharedProjects }) => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [disableUpdateEmails, setDisableUpdateEmails] = useState(true);
  const [enableClientUpdates, setEnableClientUpdates] = useState(true);

  const { role: userRole, loading, error } = useGetUserRole(project._id, userContextData.user_id);

  const initialValues = project
    ? {
        ...project, // spread the existing project properties
        clients: project.clients || [{ name: '', email: '', address: '', phone: '' }],
        phases: project.phases || [{ name: '', budget: '' }],
        shareWithEmails: project.shareWithEmails || [{ email: '', role: 'admin' }],
        projectStartDate: formatDate(project.projectStartDate),
        projectEndDate: formatDate(project.projectEndDate),
      }
    : {};

  const handleSubmit = async (values) => {
    const formData = new FormData();

    ['plansKey', 'specificationsKey', 'permitsKey', 'contractsKey'].forEach((field) => {
      if (values[field]) {
        formData.append(field, values[field]);
      }
    });

    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('projectInfo', JSON.stringify(values));
    formData.append('projectProfileForm', userRole === 'client' || userRole === 'Client' || userRole === 'client, client' ? true : false);

    try {
      const response = await updateProject(project._id, formData);

      if (response.status === 200) {
        // Refetch projects upon successful edit
        await fetchProjects();
        await fetchSharedProjects();

        setShowSuccessMessage(true);
        setUserContextData({
          ...userContextData,
          selected_project_status: 'active',
        });
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error updating project:', error);
      // You might want to set an error state here and display it to the user
    }
  };

  const handleUpdateClients = async (clientValues) => {
    const formData = new FormData();

    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('projectInfo', JSON.stringify({ ...project, clients: clientValues }));
    formData.append('projectProfileForm', 'false');

    try {
      const response = await updateProject(project._id, formData);

      if (response.status === 200) {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error updating clients:', error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
      }}
    >
      <Form>
        <div className="space-y-12">
          <ClientInfoSection
            disableUpdateEmails={disableUpdateEmails}
            onUpdateClients={(clientValues) => handleUpdateClients(clientValues)}
            projectId={project._id}
            enableClientUpdates={enableClientUpdates}
          />
          {userRole === 'admin' && <ShareProjectInfo userContextData={userContextData} />}
          {userRole === 'admin' && <PropertyInfo />}
          {userRole === 'admin' && <OnlineInfo />}
          {userRole === 'admin' && <InternalInfo />}

          {/* {userRole === "admin" && <DocumentsSection project={project} />} */}

          <ProjectStyle />
          <PropertyInfo />
          <AdditionalInfo />
          {showSuccessMessage && <EditSuccessMessage text={'Project Updated!'} />}

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SubmitButton showSuccessMessage={showSuccessMessage} text={'Save Project'} />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default EditProjectForm;
