import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = {
  IMAGE: 'image',
};

const Image = ({ image, onImageClick, onDeleteImage, onSelectImage, index, moveImage, category }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.IMAGE,
    item: { id: image._id, index, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemType.IMAGE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index || draggedItem.category !== category) {
        moveImage(draggedItem.index, index, draggedItem.category, category);
        draggedItem.index = index;
        draggedItem.category = category;
      }
    },
  }));

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`relative group hover:shadow-lg transition rounded-md overflow-hidden cursor-pointer ${isDragging ? 'opacity-50' : ''}`}
    >
      <img src={image.signedUrl} alt="Project" className="w-full h-40 object-cover" onClick={() => onImageClick(image.signedUrl)} />
      <button
        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none opacity-0 group-hover:opacity-100 transition"
        onClick={() => onDeleteImage(image._id)}
      >
        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <button
        className={`absolute text-xs p-1 bottom-2 right-2 rounded-full focus:outline-none transition ${
          image.selected ? 'bg-green-500 text-white' : 'bg-gray-300 text-black'
        }`}
        onClick={() => onSelectImage(image._id)}
      >
        Select Project Image
      </button>
    </div>
  );
};

export default Image;
