import React, { useState, useContext } from 'react';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { UserContext } from '../../../App';
import { updateProject } from '../../../services/projectService'; // Import the updateProject function
import { ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

const ClientInfoSection = ({ disableUpdateEmails, onUpdateClients, projectId, project, enableClientUpdates }) => {
  const { values, setFieldValue } = useFormikContext();
  const { userContextData, userRole } = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  React.useEffect(() => {
    if (disableUpdateEmails) {
      return;
    }

    const clientEmails = values.clients
      .filter((client) => client.email) // Only include clients with non-null emails
      .map((client) => client.email);
    setFieldValue(
      'shareWithEmails',
      clientEmails.map((email) => ({ email, role: 'client' }))
    );
  }, [values.clients, disableUpdateEmails, setFieldValue]);

  const handleSaveClients = async () => {
    try {
      await onUpdateClients(values.clients);
      setShowSuccess(true);
      setShowWarning(false);
      setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
    } catch (error) {
      console.error('Error saving clients:', error);
      setShowWarning(true);
    }
  };

  React.useEffect(() => {
    if (showWarning) {
      setShowSuccess(false);
    }
  }, [showWarning]);

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Please enter the client's information.</p>
      </div>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-6">
          <FieldArray
            name="clients"
            render={(arrayHelpers) => (
              <div>
                {values.clients &&
                  values.clients.length > 0 &&
                  values.clients.map((client, index) => {
                    // Skip rendering if it's an empty client (only has null email)
                    if (index > 0 && Object.keys(client).length === 1 && client.email === null) {
                      return null;
                    }
                    return (
                      <div key={index} className="mb-4 p-4 border rounded">
                        <h3 className="text-lg font-semibold mb-2">Client Information</h3>
                        {['name', 'email', 'phone', 'address'].map((field) => (
                          <div key={field} className="mb-2">
                            <label htmlFor={`clients.${index}.${field}`} className="block text-sm font-medium leading-6 text-gray-900">
                              {field.charAt(0).toUpperCase() + field.slice(1)} {field === 'name' || field === 'email' ? '*' : ''}
                            </label>
                            <Field
                              name={`clients.${index}.${field}`}
                              type={field === 'email' ? 'email' : 'text'}
                              placeholder={getPlaceholder(field)}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name={`clients.${index}.${field}`} component="div" className="text-xs text-red-500 italic" />
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="mt-2 w-full sm:w-auto flex-grow-0 px-4 py-2 text-gray-900 border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#264653] hover:bg-gray-200 rounded sm:text-sm sm:leading-6"
                        >
                          Remove Client
                        </button>
                      </div>
                    );
                  })}
                <div className="mt-4 space-y-4">
                  <div className="flex flex-row space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({
                          name: '',
                          email: '',
                          phone: '',
                          address: '',
                        });
                        setShowWarning(true);
                      }}
                      className="w-auto flex-grow-0 px-4 py-2 text-gray-900 border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#264653] hover:bg-gray-200 rounded sm:text-sm sm:leading-6"
                    >
                      Add Another Client
                    </button>
                    {enableClientUpdates && (
                      <button
                        type="button"
                        onClick={handleSaveClients}
                        className="w-auto flex-grow-0 px-4 py-2 text-white bg-[#2A9D8F] border-0 shadow-sm focus:ring-2 focus:ring-inset focus:ring-[#264653] hover:bg-[#1c3640] rounded sm:text-sm sm:leading-6"
                      >
                        Save Clients
                      </button>
                    )}
                  </div>
                  {enableClientUpdates && (
                    <>
                      {showWarning && (
                        <div className="rounded-md bg-yellow-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                              <div className="mt-2 text-sm text-yellow-700">
                                <p>You have unsaved changes. Please click "Save Clients" to save your changes.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {showSuccess && (
                        <div className="rounded-md bg-green-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-green-800">Success</h3>
                              <div className="mt-2 text-sm text-green-700">
                                <p>Client information has been successfully saved.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const getPlaceholder = (field) => {
  switch (field) {
    case 'name':
      return 'James Smith';
    case 'email':
      return 'client@example.com';
    case 'phone':
      return '+447888563707';
    case 'address':
      return '10 Downing Street, London, UK';
    default:
      return '';
  }
};

export default ClientInfoSection;
