import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "./index.css";

const root = createRoot(document.getElementById("root"));

root.render(
<Auth0Provider
  domain={process.env.REACT_APP_AUTH0_DOMAIN}
  clientId={process.env.REACT_APP_AUTH0_CLIENTID}
  authorizationParams={{
    redirect_uri: window.location.origin + "/dashboard",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: "openid profile email offline_access"
  }}
  useRefreshTokens={true}
  cacheLocation="localstorage"
>
  <App />
</Auth0Provider>
);
