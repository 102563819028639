import { format } from 'date-fns';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import SummaryOfChanges from './VersionControlTable.SummaryOfChanges';

export default function VersionControlTable({ versions }) {
  const [open, setOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const { userContextData } = useContext(UserContext);

  const userRole = userContextData?.projects?.length > 0 ? userContextData.projects[0].role : null;

  const handleRowClick = (version) => {
    setSelectedVersion(version);
    setOpen(true);
  };

  return (
    <div className="h-screen ">
      <div className=" ">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Version Control Table</h1>
            <p className="mt-2 text-sm text-gray-700">View all versions of the project.</p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" style={{ overflow: 'visible' }}>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Version ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Timestamp
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Changed By
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Project Stage
                    </th>

                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Number Of Changes
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Details
                    </th>

                    {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {versions.map((version, index) => {
                    const versionId = version.versionId;
                    const timestamp = format(new Date(version.timestamp), 'yyyy-MM-dd HH:mm');

                    const projectStage = version.project.projectStage;
                    const numberOfChanges = version.changeLog.changes.length;
                    const details = version.changeLog.changes.map((change) => change.field).join(', ');

                    const changedBy = version.changeLog.email;

                    return (
                      <tr key={index} className="hover:bg-slate-100" onClick={() => handleRowClick(version)}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{versionId}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{timestamp}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{changedBy}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{projectStage}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{numberOfChanges}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">View Details</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SummaryOfChanges open={open} setOpen={setOpen} version={selectedVersion} />
    </div>
  );
}
