import { useState, useEffect } from "react";
import { getUserRole } from "../../services/projectService";

function useGetUserRole(projectId, userId) {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const role = await getUserRole(projectId, userId);
        setRole(role);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (projectId && userId) {
      fetchUserRole();
    }
  }, [projectId, userId]);

  return { role, loading, error };
}

export default useGetUserRole;