import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const contactService = {
  getProjectContacts: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/contacts/project/${projectId}/contacts`);
      return response.data;
    } catch (error) {
      console.error('Error fetching project contacts:', error.message);
      throw error;
    }
  },

  getOrganisationContacts: async (organisationId) => {
    try {
      const response = await api.get(`${API_URL}/api/contacts/contacts/getOrganisationContacts/${organisationId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching organisation contacts:', error.message);
      throw error;
    }
  },

  createContact: async (contactData) => {
    try {
      const response = await api.post(`${API_URL}/api/contacts/contacts`, contactData);
      return response.data;
    } catch (error) {
      console.error('Error adding contact:', error.message);
      throw error;
    }
  },

  updateContact: async (contactId, updatedData) => {
    try {
      const response = await api.put(`${API_URL}/api/contacts/contacts/${contactId}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating contact:', error.message);
      throw error;
    }
  },

  deleteContact: async (contactId) => {
    try {
      await api.delete(`${API_URL}/api/contacts/contacts/${contactId}`);
    } catch (error) {
      console.error('Error deleting contact:', error.message);
      throw error;
    }
  }
};

export default contactService;

export const { getProjectContacts, getOrganisationContacts, createContact, updateContact, deleteContact } = contactService;