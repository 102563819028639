import React, { useState } from 'react';
import { updateProject } from '../../services/projectService';
import { useContext } from 'react';
import { UserContext } from '../../App';
import { BarsArrowDownIcon } from '@heroicons/react/24/outline';
import useProjects from '../Hooks/useProjects';

const getHumanReadableStatus = (status) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'completed':
      return 'Completed';
    case 'onHold':
      return 'On Hold';
    case 'cancelled':
      return 'Cancelled';
    case 'clientApproved':
      return 'Client Approved';
    case 'clientRejected':
      return 'Client Rejected';
    case 'clientReview':
    default:
      return 'Client To Review';
  }
};

const StatusLabel = ({ project, status, stage, onUpdate }) => {
  const { userContextData } = useContext(UserContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const { fetchProjects } = useProjects(userContextData);

  let backgroundColor = '';
  let textColor = '';
  let ringColor = '';
  let displayStatus = '';

  switch (status) {
    case 'active':
      backgroundColor = 'bg-green-50';
      textColor = 'text-green-700';
      ringColor = 'ring-green-600';
      displayStatus = 'Active';
      break;
    case 'completed':
      backgroundColor = 'bg-blue-50';
      textColor = 'text-blue-700';
      ringColor = 'ring-blue-600';
      displayStatus = 'Completed';
      break;
    case 'onHold':
      backgroundColor = 'bg-orange-50';
      textColor = 'text-orange-700';
      ringColor = 'ring-orange-600';
      displayStatus = 'On Hold';
      break;
    case 'cancelled':
      backgroundColor = 'bg-red-50';
      textColor = 'text-red-700';
      ringColor = 'ring-red-600';
      displayStatus = 'Cancelled';
      break;
    case 'clientApproved':
      backgroundColor = 'bg-teal-50';
      textColor = 'text-teal-700';
      ringColor = 'ring-teal-600';
      displayStatus = 'Client Approved';
      break;
    case 'clientRejected':
      backgroundColor = 'bg-pink-50';
      textColor = 'text-pink-700';
      ringColor = 'ring-pink-600';
      displayStatus = 'Client Rejected';
      break;
    case 'clientReview':
    default:
      backgroundColor = 'bg-yellow-50';
      textColor = 'text-yellow-700';
      ringColor = 'ring-yellow-600';
      displayStatus = 'Client To Review';
      break;
  }

  const stageColors = {
    'Creation Pack': { bg: 'bg-gray-50', text: 'text-gray-700', ring: 'ring-gray-600' },
    'Home Design Pack': { bg: 'bg-blue-50', text: 'text-blue-700', ring: 'ring-blue-600' },
    'Construction Pack': { bg: 'bg-yellow-50', text: 'text-yellow-700', ring: 'ring-yellow-600' },
    Onsite: { bg: 'bg-green-50', text: 'text-green-700', ring: 'ring-green-600' },
    'On Hold': { bg: 'bg-orange-50', text: 'text-orange-700', ring: 'ring-orange-600' },
    Cancelled: { bg: 'bg-red-50', text: 'text-red-700', ring: 'ring-red-600' },
    Complete: { bg: 'bg-green-50', text: 'text-green-700', ring: 'ring-green-600' },
  };
  const defaultStageColor = { bg: 'bg-gray-50', text: 'text-gray-700', ring: 'ring-gray-600' };

  const updateProjectField = async (field, newValue) => {
    try {
      const formData = new FormData();
      formData.append('userInfo', JSON.stringify(userContextData));
      formData.append(
        'projectInfo',
        JSON.stringify({
          ...project,
          [field]: newValue,
        })
      );

      const response = await updateProject(project._id, formData);

      if (response.status === 200) {
        onUpdate(field, newValue);
        await fetchProjects();
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error(`Error updating project ${field}:`, error);
    }
  };

  const handleUpdate = (field, newValue) => {
    updateProjectField(field, newValue);
    setShowDropdown(false);
  };

  return (
    <div className="relative flex space-x-2">
      <span
        onClick={() => {
          setShowDropdown(!showDropdown);
          setActiveField('projectStatus');
        }}
        className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}/20 cursor-pointer`}
      >
        <div className="flex items-center justify-center">
          {displayStatus} <BarsArrowDownIcon className="ml-2 h-3 w-3" />
        </div>
      </span>
      <span
        onClick={() => {
          setShowDropdown(!showDropdown);
          setActiveField('projectStage');
        }}
        className={`inline-flex items-center rounded-md ${stageColors[stage]?.bg || defaultStageColor.bg} px-2 py-1 text-xs font-medium ${
          stageColors[stage]?.text || defaultStageColor.text
        } ring-1 ring-inset ${stageColors[stage]?.ring || defaultStageColor.ring}/20 cursor-pointer`}
      >
        <div className="flex items-center justify-center">
          {stage || 'No Stage'} <BarsArrowDownIcon className="ml-2 h-3 w-3" />
        </div>
      </span>
      {showDropdown && (
        <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          {activeField === 'projectStatus'
            ? ['active', 'clientReview'].map((statusOption) => (
                <div key={statusOption} onClick={() => handleUpdate('projectStatus', statusOption)} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                  {getHumanReadableStatus(statusOption)}
                </div>
              ))
            : Object.keys(stageColors).map((stageOption) => (
                <div key={stageOption} onClick={() => handleUpdate('projectStage', stageOption)} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                  {stageOption}
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default StatusLabel;
