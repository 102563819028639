import { PaperClipIcon } from '@heroicons/react/24/outline';

export default function ClientInformation({ project }) {
  // Function to check if a client object is empty or has only null values
  const isClientEmpty = (client) => {
    return (
      !client ||
      Object.values(client).every(
        (value) => value === null || value === undefined || value === ''
      )
    );
  };

  // Filter out empty client objects
  const validClients = project.clients
    ? project.clients.filter((client) => !isClientEmpty(client))
    : [];

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Client Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Details about the clients involved in the project.
        </p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {validClients.length > 0 ? (
            validClients.map((client, index) => (
              <div
                key={index}
                className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Client {index + 1}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">Name:</span>
                          <span className="flex-shrink-0">
                            {client.name || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">Email:</span>
                          <span className="flex-shrink-0">
                            {client.email || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">Phone:</span>
                          <span className="flex-shrink-0">
                            {client.phone || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">Address:</span>
                          <span className="flex-shrink-0">
                            {client.address || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            ))
          ) : (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Client Information
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                No client information available
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}
