import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';

export default function Sidebar({ navigation, selectedProjectNavigation, advancedNavigation }) {
  const [activeNav, setActiveNav] = useState(null);
  const [activeTeam, setActiveTeam] = useState(null);
  const [activeAdvanced, setActiveAdvanced] = useState(null);

  // Use useLocation to access the current location
  const location = useLocation();

  useEffect(() => {
    // Find the active navigation item based on the current location
    const activeNavIndex = navigation.findIndex((item) => location.pathname === item.href);
    setActiveNav(activeNavIndex !== -1 ? activeNavIndex : null);

    // Similar logic for selectedProjectNavigation and advancedNavigation
    const activeTeamIndex = selectedProjectNavigation.findIndex((team) => location.pathname.includes(team.href));
    setActiveTeam(activeTeamIndex !== -1 ? activeTeamIndex : null);

    const activeAdvancedIndex = advancedNavigation.findIndex((item) => location.pathname.includes(item.href));
    setActiveAdvanced(activeAdvancedIndex !== -1 ? activeAdvancedIndex : null);
  }, [location, navigation, selectedProjectNavigation, advancedNavigation]);

  const handleNavClick = (index) => {
    setActiveNav(index);
  };

  const handleTeamClick = (index) => {
    setActiveTeam(index);
  };

  const handleAdvancedClick = (index) => {
    setActiveAdvanced(index);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <Link to="/dashboard/projects">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-12 w-auto" src={logo} alt="Design Vault Logo" />
          </div>
        </Link>
        <div className="items-center">
          <p className="text-[#2A9D8F] text-sm font-medium ">Beta Version</p>

          <p className="text-xs  text-gray-400 "> This is an experimental software. Your feedback is welcome. </p>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-2">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item, index) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      onClick={() => handleNavClick(index)}
                      className={classNames(
                        item.current ? 'bg-gray-50 text-[#2A9D8F]' : 'text-gray-700 hover:text-[#2A9D8F] hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(item.current ? 'text-[#2A9D8F]' : 'text-gray-400 group-hover:text-[#2A9D8F]', 'h-6 w-6 shrink-0')}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {selectedProjectNavigation.length > 0 && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Selected Project</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {selectedProjectNavigation.map((team, index) => (
                    <li key={team.name}>
                      <Link
                        to={team.href}
                        onClick={() => handleTeamClick(index)}
                        className={classNames(
                          activeTeam === index ? 'bg-gray-50 text-[#2A9D8F]' : 'text-gray-700 hover:text-[#2A9D8F] hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <span
                          className={classNames(
                            activeTeam === index
                              ? 'text-[#2A9D8F] border-[#2A9D8F]'
                              : 'text-gray-400 border-gray-200 group-hover:border-[#2A9D8F] group-hover:text-[#2A9D8F]',
                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                          )}
                        >
                          {team.initial}
                        </span>
                        <span className="truncate">{team.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}

            {advancedNavigation && advancedNavigation.length > 0 && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400 mt-4">Advanced</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {advancedNavigation.map((item, index) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => handleAdvancedClick(index)}
                        className={classNames(
                          activeAdvanced === index ? 'bg-gray-50 text-[#2A9D8F]' : 'text-gray-700 hover:text-[#2A9D8F] hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <span
                          className={classNames(
                            activeAdvanced === index
                              ? 'text-[#2A9D8F] border-[#2A9D8F]'
                              : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-[#2A9D8F]',
                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                          )}
                        >
                          {item.initial}
                        </span>
                        <span className="truncate">{item.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}

            {/* Settings TBD */}
            {/* <li className="mt-auto">
              <Link
                to="#"
                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#2A9D8F]"
              >
                <Cog6ToothIcon
                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#2A9D8F]"
                  aria-hidden="true"
                />
                Settings
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
}
