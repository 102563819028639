import React from 'react';
import { useParams } from 'react-router-dom';
import NotesTable from './Table';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

function NotesSummaryV2({ notes, refetch }) {
  const { projectId } = useParams();

  const EmptyState = () => (
    <div className="text-center py-12">
      <ChatBubbleLeftRightIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No project notes</h3>
      <p className="mt-1 text-sm text-gray-500">Notes for this project will appear here.</p>
    </div>
  );

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Project Notes</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">A list of all notes for this project.</p>
      </div>
      <div className="border-t border-gray-200">{notes.length > 0 ? <NotesTable projectId={projectId} notes={notes} refetch={refetch} /> : <EmptyState />}</div>
    </div>
  );
}

export default NotesSummaryV2;
