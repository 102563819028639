import React, { useState } from 'react';
import api from '../../utils/api';

export default function AddNewMember({ userContextData, setOrganisation }) {
  const [newMember, setNewMember] = useState({ email: '', roles: [] });

  const handleMemberChange = (event) => {
    const { name, value } = event.target;
    if (name === 'roles') {
      setNewMember((prevDetails) => ({
        ...prevDetails,
        roles: [value],
      }));
    } else {
      setNewMember((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleAddMember = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post(`${process.env.REACT_APP_SERVER_URL}/api/organisations/add-member`, {
        organisationId: userContextData.organisation,
        email: newMember.email,
        roles: newMember.roles,
      });

      setOrganisation(response.data);
      setNewMember({ email: '', roles: [] });
    } catch (error) {
      console.error('Error adding new member:', error);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Member</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Add a new member to your organisation. Only owners can add new members.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        <form onSubmit={handleAddMember} className="space-y-6">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
            <div className="col-span-1 sm:col-span-2">
              <label htmlFor="newMemberEmail" className="block text-sm font-medium text-gray-700">
                Member Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="newMemberEmail"
                  value={newMember.email}
                  onChange={handleMemberChange}
                  className="shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
            </div>

            <div className="col-span-1 sm:col-span-2">
              <label htmlFor="roles" className="block text-sm font-medium text-gray-700">
                Member Role
              </label>
              <div className="mt-1">
                <select
                  id="roles"
                  name="roles"
                  value={newMember.roles[0] || ''}
                  onChange={handleMemberChange}
                  className="shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full sm:text-sm border-gray-300 rounded-md"
                  required
                >
                  <option value="">Select a role</option>
                  <option value="owner">Owner</option>
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                </select>
              </div>
            </div>
          </div>

          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Note on user rights</h3>
                <div className="mt-2 text-sm text-green-700">
                  <ul className="list-disc pl-5 space-y-1">
                    <li>
                      <span className="font-bold">Owners</span> can add and remove members, and view and edit all projects.
                    </li>
                    <li>
                      <span className="font-bold">Admins</span> can view and edit all projects, but cannot add new members.
                    </li>
                    <li>
                      <span className="font-bold">Members</span> can only view and edit projects that they create.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
            >
              Add Member
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
