import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "../../App";
import api from "../../utils/api";

const useProjectVersions = (projectId) => {
  const [projectVersions, setProjectVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userContextData = useContext(UserContext);

  useEffect(() => {
    const fetchProjectVersions = async () => {
      try {
        const res = await api.get(
          `${process.env.REACT_APP_SERVER_URL}/api/projects/projects/${projectId}/versions`,
          {
            params: {
              userInfo: userContextData,
            },
          }
        );

        setProjectVersions(res.data.projectVersions);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectVersions();
  }, [projectId, userContextData]);

  return { projectVersions, isLoading, error };
};

export default useProjectVersions;
