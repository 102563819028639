import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';

const MobileSidebar = ({
  navigation,
  selectedProjectNavigation,
  advancedNavigation,
  sidebarOpen,
  setSidebarOpen,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [activeNav, setActiveNav] = useState(null);
  const [activeTeam, setActiveTeam] = useState(null);
  const [activeAdvanced, setActiveAdvanced] = useState(null);

  // Use useLocation to access the current location
  const location = useLocation();

  useEffect(() => {
    // Find the active navigation item based on the current location
    const activeNavIndex = navigation.findIndex(
      (item) => location.pathname === item.href
    );
    setActiveNav(activeNavIndex !== -1 ? activeNavIndex : null);

    // Similar logic for selectedProjectNavigation and advancedNavigation
    const activeTeamIndex = selectedProjectNavigation.findIndex((team) =>
      location.pathname.includes(team.href)
    );
    setActiveTeam(activeTeamIndex !== -1 ? activeTeamIndex : null);

    const activeAdvancedIndex = advancedNavigation.findIndex((item) =>
      location.pathname.includes(item.href)
    );
    setActiveAdvanced(activeAdvancedIndex !== -1 ? activeAdvancedIndex : null);
  }, [location, navigation, selectedProjectNavigation, advancedNavigation]);

  const handleNavClick = (index) => {
    setActiveNav(index);
    setSidebarOpen(false); // Close the sidebar
  };

  const handleTeamClick = (index) => {
    setActiveTeam(index);
  };

  const handleAdvancedClick = (index) => {
    setActiveAdvanced(index);
  };

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img className="h-8 w-auto" src={logo} alt="Your Company" />
                </div>
                <div className="items-center">
                  <p className="text-[#2A9D8F] text-sm font-medium ">
                    Beta Version
                  </p>

                  <p className="text-xs  text-gray-400 ">
                    {' '}
                    This is an experimental software. Your feedback is welcome.{' '}
                  </p>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item, index) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              onClick={() => handleNavClick(index)}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-50 text-[#2A9D8F]'
                                  : 'text-gray-700 hover:text-[#2A9D8F] hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? 'text-[#2A9D8F]'
                                    : 'text-gray-400 group-hover:text-[#2A9D8F]',
                                  'h-6 w-6 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {selectedProjectNavigation.length > 0 && (
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Selected Project
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {selectedProjectNavigation.map((item, index) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                onClick={() => handleNavClick(index)}
                                className={classNames(
                                  activeAdvanced === index
                                    ? 'bg-gray-50 text-[#2A9D8F]'
                                    : 'text-gray-700 hover:text-[#2A9D8F] hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <span
                                  className={classNames(
                                    activeAdvanced === index
                                      ? 'text-[#2A9D8F] border-indigo-600'
                                      : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-[#2A9D8F]',
                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                  )}
                                >
                                  {item.initial}
                                </span>
                                <span className="truncate">{item.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}

                    {/* <li className="mt-auto">
                      <Link
                        to="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#2A9D8F]"
                      >
                        <Cog6ToothIcon
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#2A9D8F]"
                          aria-hidden="true"
                        />
                        Settings
                      </Link>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileSidebar;
