import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import LoginButton from './Auth/LoginButton';
import logoLogInScreen from '../images/logoLogInScreen.png';

const LoginPageWithDescription = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [inviteEmail, setInviteEmail] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    setInviteEmail(email || '');
  }, [location]);

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="w-full max-w-md px-6 py-8 bg-white rounded-lg shadow-md">
        <div className="text-center">
          <img src={logoLogInScreen} alt="DesignVault logo" className="h-20 mx-auto mb-6" />
          {inviteEmail ? (
            <div className="space-y-2">
              <p className="text-sm text-gray-600 ">
                To ensure you get access to the correct projects, please log in or sign up with the email address you were invited with:
              </p>
              <p className="text font-medium text-[#2A9D8F] ">{inviteEmail}</p>
            </div>
          ) : (
            <p className="text-gray-600">Welcome to DesignVault. Please log in to access your projects.</p>
          )}
        </div>

        <div className="mt-8 flex justify-center">
          <LoginButton inviteEmail={inviteEmail} />
        </div>
      </div>
    </div>
  );
};

export default LoginPageWithDescription;
