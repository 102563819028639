import React, { useEffect, useState } from 'react';
import VersionControlTable from '../../Projects/VersionControl/VersionControlTable';
// import EmptyVersionsMessage from "../Projects/EmptyVersionsMessage";
import { useParams } from 'react-router-dom';
import useProjectVersions from '../../Hooks/useProjectVersions';
import axios from 'axios';

const ProjectVersionsTablePage = () => {
  const { projectId } = useParams();

  const { projectVersions: versions, isLoading, error } = useProjectVersions(projectId);

  if (versions.length === 0) {
    return (
      <div className="text-center">
        <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">No Updates</h3>
        <p className="mt-1 text-sm text-gray-500">
          No updates have been made to this project. <br />
          You will see new updates here.
        </p>
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        {versions.length > 0 ? (
          <VersionControlTable versions={versions} />
        ) : //   <EmptyVersionsMessage />
        null}
      </div>
    </div>
  );
};

export default ProjectVersionsTablePage;
