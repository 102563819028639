import React, { useState } from 'react';
import SectionHeading from '../../UI/SectionHeading';
import Form from '../../Projects/EditProject/Form';
import { useParams } from 'react-router-dom';
import useGetProject from '../../Hooks/useGetProject';
import { Spinner } from 'flowbite-react';

const ProjectEditPage = ({ projects, fetchProjects, fetchSharedProjects }) => {
  const { projectId } = useParams();

  const { project, loading, error } = useGetProject(projectId);

  if (loading) {
    return (
      <div>
        <Spinner aria-label="Info spinner example" color="info" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // check if project.projectStatus is clientReview
  const clientReview = project.projectStatus === 'clientReview';

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        <SectionHeading title={clientReview ? 'Please Add Project Details' : 'Edit Project'} />
        <Form project={project} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />
      </div>
    </div>
  );
};

export default ProjectEditPage;
