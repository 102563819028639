import React from "react";
import { Field, ErrorMessage } from "formik";

const OnlineInfo = () => {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Online Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Please provide a link to the online information (e.g. Rightmove).
        </p>
      </div>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-6">
          <label
            htmlFor="onlineLink"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Rightmove Link
          </label>
          <Field
            type="text"
            placeholder="https://rightmove.co.uk"
            name="onlineLink"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="onlineLink"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
      </div>
    </div>
  );
};

export default OnlineInfo;
