import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { UserContext } from '../../../App';
import { Form, Table } from '../../Projects/Contacts/index';
import useGetProjectContacts from '../../Hooks/useGetProjectContacts';
import { getOrganisationContacts, createContact, updateContact, deleteContact } from '../../../services/contactService';

const ProjectContactsPage = () => {
  const { projectId } = useParams();
  const { contacts, loading, error, refetch } = useGetProjectContacts(projectId);
  const [showContactForm, setShowContactForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentContactId, setCurrentContactId] = useState(null);
  const [contactFields, setContactFields] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    contactType: '',
  });
  const [organisationContacts, setOrganisationContacts] = useState([]);

  const { userContextData, setUserContextData } = useContext(UserContext);

  const { organisation } = userContextData;

  useEffect(() => {
    const fetchOrganisationContacts = async () => {
      try {
        const data = await getOrganisationContacts(organisation);
        setOrganisationContacts(data);
      } catch (error) {
        console.error('Error fetching organisation contacts:', error);
      }
    };

    if (organisation) {
      fetchOrganisationContacts();
    }
  }, [organisation]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactData = {
      ...contactFields,
      type: contactFields.contactType,
      projectId,
      organisation,
    };

    try {
      if (editMode) {
        await updateContact(currentContactId, contactData);
      } else {
        await createContact(contactData);
      }
      refetch();
      resetForm();
    } catch (error) {
      console.error('Failed to process contact:', error.message);
    }
  };

  const handleExistingContactSelect = (e) => {
    const selectedContactId = e.target.value;
    if (selectedContactId) {
      const selectedContact = organisationContacts.find((contact) => contact._id === selectedContactId);
      setContactFields({
        name: selectedContact.name,
        email: selectedContact.email,
        phone: selectedContact.phone,
        company: selectedContact.company,
        contactType: selectedContact.type,
      });
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setShowContactForm(false);
    setEditMode(false);
    setCurrentContactId(null);
    setContactFields({
      contactType: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      link: '',
    });
  };

  const startEditing = (contact) => {
    setContactFields({
      contactType: contact.type,
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      company: contact.company,
      link: contact.link,

      // Assume contact.type and other fields are available
    });
    setEditMode(true);
    setCurrentContactId(contact._id);
    setShowContactForm(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await deleteContact(contactId);
      refetch();
    } catch (error) {
      console.error('Failed to delete contact:', error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Project Contacts</h2>
      <Table contacts={contacts} deleteContact={handleDeleteContact} startEditing={startEditing} />

      <div className="relative my-10">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => setShowContactForm(!showContactForm)}
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            {showContactForm ? 'Hide Contact Form' : 'Add New Contact'}
          </button>
        </div>
      </div>
      {showContactForm && (
        <Form
          contactFields={contactFields}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          editMode={editMode}
          organisationContacts={organisationContacts}
          handleExistingContactSelect={handleExistingContactSelect}
        />
      )}
    </div>
  );
};

export default ProjectContactsPage;
