import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const milestoneService = {
  getMilestones: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/milestones/project/${projectId}/milestones`);
      return response.data;
    } catch (error) {
      console.error('Error fetching milestones:', error.message);
      throw error;
    }
  },

  createMilestone: async (milestoneData) => {
    try {
      const response = await api.post(`${API_URL}/api/milestones/milestones`, milestoneData);
      return response.data;
    } catch (error) {
      console.error('Error adding milestone:', error.message);
      throw error;
    }
  },

  updateMilestone: async (milestoneId, updatedData) => {
  
    const url = `${API_URL}/api/milestones/milestones/${milestoneId}`;

    try {
      const response = await api.put(url, updatedData);

      return response.data;
    } catch (error) {
      console.error('updateMilestone error:', error);
      throw error;
    }
  },

  deleteMilestone: async (milestoneId) => {
    try {
      await api.delete(`${API_URL}/api/milestones/milestones/${milestoneId}`);
    } catch (error) {
      console.error('Error deleting milestone:', error.message);
      throw error;
    }
  },

  createSubMilestone: async (milestoneId, subMilestoneData) => {
    try {
      const response = await api.post(`${API_URL}/api/milestones/milestones/${milestoneId}/submilestones`, subMilestoneData);
      return response.data;
    } catch (error) {
      console.error('Error adding sub-milestone:', error.message);
      throw error;
    }
  },

  updateSubMilestone: async (milestoneId, subMilestoneId, updatedData) => {
 
    try {
      const response = await api.put(`${API_URL}/api/milestones/milestones/${milestoneId}/submilestones/${subMilestoneId}`, updatedData);
  
      return response.data;
    } catch (error) {
      console.error('Error updating sub-milestone:', error);
      throw error;
    }
  },

  deleteSubMilestone: async (milestoneId, subMilestoneId) => {
    try {
      await api.delete(`${API_URL}/api/milestones/milestones/${milestoneId}/submilestones/${subMilestoneId}`);
    } catch (error) {
      console.error('Error deleting sub-milestone:', error.message);
      throw error;
    }
  },

  uploadMilestones: async (projectId, file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('projectId', projectId);
      const response = await api.post(`${API_URL}/api/milestones/uploadMilestones`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading milestones:', error.message);
      throw error;
    }
  },
};

export default milestoneService;

export const { getMilestones, createMilestone, updateMilestone, deleteMilestone, createSubMilestone, updateSubMilestone, deleteSubMilestone, uploadMilestones } = milestoneService;