import React from 'react';

export const SubMilestoneForm = ({ milestones, handleAddSubMilestone, newSubMilestoneTitle, setNewSubMilestoneTitle, keyMilestoneId, setKeyMilestoneId }) => {
  return (
    <div>
      <h2 className="font-medium text-gray-900">Add New Sub-Milestone</h2>
      <p className="mt-1 text-sm text-gray-600">Enter details for the submilestone</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddSubMilestone();
        }}
      >
        <div className="grid grid-cols-1 gap-y-4 mt-5">
          <div>
            <label className="block text-sm font-medium text-gray-700">Key Milestone *</label>
            <select
              value={keyMilestoneId}
              onChange={(e) => setKeyMilestoneId(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="">Select a Key Milestone</option>
              {milestones.map((milestone) => (
                <option key={milestone._id} value={milestone._id}>
                  {milestone.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Sub-Milestone Title *</label>
            <input
              type="text"
              value={newSubMilestoneTitle}
              onChange={(e) => setNewSubMilestoneTitle(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 bg-[#2A9D8F] text-white font-medium rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-[#264653] focus:ring-offset-2 transition duration-300"
            >
              Add Sub-Milestone
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubMilestoneForm;
