import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NotesTable from '../../Projects/Notes/Table';
import { getNotes } from '../../../services/noteService';

const ProjectNotesPage = () => {
  const { projectId } = useParams();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotes = async () => {
    setIsLoading(true);
    try {
      const fetchedNotes = await getNotes(projectId);
      setNotes(fetchedNotes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [projectId]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">{isLoading ? <p>Loading notes...</p> : <NotesTable projectId={projectId} notes={notes} refetch={fetchNotes} />}</div>
    </div>
  );
};

export default ProjectNotesPage;
