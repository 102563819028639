import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { addCommentToDocument, sendApprovalReminder } from '../../../services/documentService';

export default function SlideOver({ open, setOpen, data, projectId, userContextData, userRole }) {
  const [newComment, setNewComment] = useState('');

  const handleAddComment = async (event) => {
    event.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await addCommentToDocument(projectId, data._id, newComment, userContextData);

      // Update the comments locally for immediate feedback
      data.comments.push({
        comment: newComment,
        userId: userContextData,
        createdAt: new Date().toISOString(),
      });
      setNewComment(''); // Clear the comment input field
    } catch (error) {
      console.error('There was an error adding the comment:', error);
      alert('Error adding comment: ' + (error.message || 'Unknown error'));
    }
  };

  const handleSendApprovalReminder = async () => {
    try {
      await sendApprovalReminder(projectId, data._id);
      alert('Reminder email sent successfully.');
    } catch (error) {
      console.error('Error sending reminder email:', error);
      alert('Error sending reminder email: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Document Details</Dialog.Title>
                    </div>

                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Document Content */}
                      {data && (
                        <div className="space-y-6">
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Document Name:</span>
                            <span className="text-sm text-gray-900">{data.name}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Document Version:</span>
                            <span className="text-sm text-gray-900">{data?.version || 'Not Specified'}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Document Importants:</span>
                            <span className="text-sm text-gray-900">{data.isImportant ? 'Important' : 'Not Important'}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Approved By Client:</span>
                            <span className="text-sm text-gray-900">{data.approvedByClient ? 'Approved' : 'Not Approved'}</span>
                            {userRole === 'admin' && !data.approvedByClient && (
                              <button
                                onClick={handleSendApprovalReminder}
                                className="mt-2 inline-flex text-[10px] items-center px-2 py-1 bg-[#2A9D8F] text-white font-medium text-sm leading-5 rounded-md shadow hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] hover:cursor-pointer transition ease-in-out duration-150"
                                style={{ alignSelf: 'flex-start' }} // Ensures left alignment
                              >
                                Send Approval Reminder
                              </button>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Download Link:</span>
                            <a href={data.signedUrl} className="text-sm text-[#2A9D8F] hover:text-[#2A9D8F]" target="_blank" rel="noopener noreferrer">
                              Download
                            </a>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Category:</span>
                            <span className="text-sm text-gray-900">{data.category}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Uploaded By:</span>
                            <span className="text-sm text-gray-900">{data.uploadedBy.email}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Uploaded Date:</span>
                            <span className="text-sm text-gray-900">{new Date(data.uploadedDate).toLocaleDateString()}</span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Comments:</span>
                            {data &&
                              data.comments.map((comment, index) => (
                                <div key={index} className="mt-2">
                                  <span className="text-sm text-gray-900">{comment.comment}</span>
                                  <div className="text-sm text-gray-500">
                                    By {comment.userId.email} on {new Date(comment.createdAt).toLocaleDateString()}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="flex flex-col">
                            <form onSubmit={handleAddComment} className="mt-4">
                              <div className="flex flex-col">
                                <label htmlFor="newComment" className="text-sm font-medium text-gray-700">
                                  Add a comment
                                </label>
                                <textarea
                                  id="newComment"
                                  name="newComment"
                                  rows={3}
                                  className="mt-1 block w-full shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm border border-gray-300 rounded-md"
                                  placeholder="Write your comment here..."
                                  value={newComment}
                                  onChange={(e) => setNewComment(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="mt-2 flex justify-end">
                                <button
                                  type="submit"
                                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                >
                                  Submit Comment
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
