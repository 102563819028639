import React from 'react';
import { UserCircleIcon, ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid';

export default function Members({ organisation, isOwner, userContextData, handleRemoveMember }) {
  const [copiedEmail, setCopiedEmail] = React.useState(null);

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedEmail(email);
      setTimeout(() => setCopiedEmail(null), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Organisation Members</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">List of members in your organisation.</p>
      </div>
      <div className="border-t border-gray-200">
        {!isOwner && (
          <div className="px-4 py-5 sm:px-6">
            <div className="p-4 bg-green-50 rounded-md">
              <p className="text-sm text-[#2A9D8F]">Only owners can add and remove new users & members.</p>
            </div>
          </div>
        )}

        {organisation.members.length > 0 ? (
          <ul role="list" className="divide-y divide-gray-200">
            {organisation.members.map((member) => (
              <li key={member.userId._id} className="px-4 py-4 sm:px-6">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <UserCircleIcon className="h-8 w-8 text-gray-300" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 text-sm text-gray-900 flex items-center">
                          {member.userId.email}
                          <button
                            onClick={() => copyToClipboard(member.userId.email)}
                            className="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                            title="Copy email to clipboard"
                          >
                            {copiedEmail === member.userId.email ? (
                              <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" />
                            ) : (
                              <ClipboardDocumentIcon className="h-5 w-5" />
                            )}
                          </button>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Role</dt>
                        <dd className="mt-1 text-sm text-gray-900">{member.role}</dd>
                      </div>
                      {isOwner && member.userId._id !== userContextData.user_id && (
                        <div className="flex items-center justify-end">
                          <button
                            type="button"
                            onClick={() => handleRemoveMember(member.userId._id)}
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="px-4 py-5 sm:px-6 text-sm text-gray-500">No members found.</p>
        )}
      </div>
    </div>
  );
}
