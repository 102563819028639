import React, { useState, useEffect, useContext } from 'react';
import TasksEditModal from './TasksEditModal';
import TaskStatusLabel from './TaskStatusLabel';
import axios from 'axios';
import { UserContext } from '../../../App';
import useGetUserRole from '../../Hooks/useGetUserRole';
import api from '../../../utils/api';

const areAllTasksCompleted = (tasksForStage) => {
  return tasksForStage.every((task) => task.status === 'Completed');
};

const TasksTable = ({ projectId, initialTasks, fetchTasks }) => {
  const [open, setOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [tasks, setTasks] = useState(initialTasks);

  const { userContextData } = useContext(UserContext);

  const { role: userRole, loading, error } = useGetUserRole(projectId, userContextData.user_id);

  const isNotAdmin = userRole !== 'admin';

  const isNotAssignedToUser = (task) => task.assignedTo !== userContextData.email; // Ensure userContextData has email

  useEffect(() => {
    // Filter out tasks with 'Lead' and 'Sale' stages
    const filteredTasks = initialTasks.filter((task) => task.stage !== 'Lead' && task.stage !== 'Sale');
    setTasks(filteredTasks);
  }, [initialTasks]);

  const handleEditClick = (task) => {
    setCurrentTask(task);
    setOpen(true);
  };

  const groupedTasksByStage = tasks.reduce((acc, task) => {
    if (!acc[task.stage]) {
      acc[task.stage] = [];
    }
    acc[task.stage].push(task);
    return acc;
  }, {});

  const completedStages = Object.entries(groupedTasksByStage).reduce((acc, [stage, tasksForStage]) => {
    const allCompleted = tasksForStage.every((task) => task.status === 'Completed');
    if (allCompleted) acc.push(stage);
    return acc;
  }, []);

  const daysLeft = (endDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // set time to midnight
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0); // set time to midnight

    return Math.floor((end - today) / 86400000); // 86400000 milliseconds in a day
  };

  const handleStatusChange = (updatedTask) => {
    const updatedTasks = tasks.map((task) => {
      if (task._id === updatedTask._id) {
        return {
          ...updatedTask,
          completionDate: updatedTask.status === 'Completed' ? new Date() : null,
        };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleDateChange = async (task, newDate) => {
    try {
      const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/tasks/task/${task._id}/update`, {
        endDate: newDate,
      });
      if (response.data.message === 'Task updated successfully') {
        // <-- 2. When updating the date of a task, also update the tasks in the state
        const updatedTasks = tasks.map((t) => (t._id === task._id ? { ...t, endDate: newDate } : t));
        setTasks(updatedTasks);
        window.location.reload();
      } else {
        console.error('Failed to update task due date');
      }
    } catch (error) {
      console.error('Error updating task due date:', error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-20">
      {Object.entries(groupedTasksByStage).map(([stage, tasksForStage], stageIndex) => {
        const isPreviousStageCompleted = stageIndex === 0 || completedStages.includes(Object.keys(groupedTasksByStage)[stageIndex - 1]);

        return (
          <div key={stage}>
            <div className="sm:flex sm:items-center mb-4">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">{stage} Stage</h2>
            </div>
            <div className="mt-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="divide-y divide-gray-300 w-full mx-auto">
                    <div className="flex items-center mb-2">
                      {['Task Name', 'Assigned To', 'Due Date', 'Days Left', 'Status', 'Details'].map((header) => (
                        <div className="flex-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" key={header}>
                          {header}
                        </div>
                      ))}
                    </div>
                    {tasksForStage.map((task, index) => (
                      <div key={task._id} className={`flex items-center ${isPreviousStageCompleted ? '' : 'opacity-50'}`}>
                        <div className="flex-1 px-3 py-4 text-sm font-medium text-gray-900">{task.name}</div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">{task.assignedTo}</div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">
                          <input
                            type="date"
                            disabled={isNotAdmin || isNotAssignedToUser(task)}
                            value={new Date(task.endDate).toISOString().split('T')[0]}
                            style={{ padding: 0 }}
                            onChange={(e) => handleDateChange(task, e.target.value)}
                            className="rounded-md border border-white text-xs"
                          />
                        </div>
                        <div className="flex-1 px-3 py-4 text-sm">
                          {task.completionDate ? (
                            <span className="text-gray-500">Completed on {new Date(task.completionDate).toLocaleDateString('en-GB')}</span>
                          ) : (
                            <span className={daysLeft(task.endDate) < 0 ? 'text-red-500' : 'text-gray-500'}>{daysLeft(task.endDate)} days</span>
                          )}
                        </div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">
                          <TaskStatusLabel
                            task={task}
                            status={task.status}
                            index={index}
                            totalCount={tasksForStage.length}
                            onStatusChange={handleStatusChange}
                            isNotAdmin={isNotAdmin || !isPreviousStageCompleted || isNotAssignedToUser(task)} // Disable if not admin or previous stage not completed
                          />
                        </div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">
                          <button onClick={() => handleEditClick(task)} className="font-medium text-[#2A9D8F] hover:text-[#2A9D8F] hover:underline">
                            More Details
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {open && currentTask && <TasksEditModal task={currentTask} setOpen={setOpen} fetchTasks={fetchTasks} isNotAdmin={isNotAdmin} />}
    </div>
  );
};

export default TasksTable;
