import React, { useContext } from 'react';
import { UserContext } from '../../App';
import useGetDefaultTasks from '../Hooks/useGetDefaultTasks';
import UserDefaultTasksTable from '../Projects/Tasks/UserDefaultTaskTable';

const TaskManagerPage = () => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const userId = userContextData.user_id;

  const { defaultTasks, error, isLoading } = useGetDefaultTasks(userId);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <UserDefaultTasksTable userId={userId} defaultTasks={defaultTasks} /> // <-- Use it here
      )}
    </div>
  );
};

export default TaskManagerPage;
