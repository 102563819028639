// ChangeRequestsSummary.jsx

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ChangeRequestTable from './Table';

function ChangeRequestsSummary({ changeRequests, refetch }) {
  const { projectId } = useParams();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="border-t border-gray-200">
        <ChangeRequestTable projectId={projectId} changeRequests={changeRequests} refetch={refetch} />
      </div>
    </div>
  );
}

export default ChangeRequestsSummary;
