import React, { useState, useRef, useContext } from 'react';
import { PaperClipIcon, PhotoIcon, LinkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { UserContext } from '../../../App';
import { uploadDocument } from '../../../services/documentService';

import LoadingModal from '../Common/LoadingModal';

const UploadDocuments = ({ show, projectId, userRole }) => {
  const { userContextData } = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [docName, setDocName] = useState('');
  const [docCategory, setDocCategory] = useState('Working Documents');
  const [uploading, setUploading] = useState(false);
  const [docUrl, setDocUrl] = useState('');
  const [isFile, setIsFile] = useState(true);
  const [docSubCategory, setDocSubCategory] = useState('');
  const [docVersion, setDocVersion] = useState('');
  const [isImportant, setIsImportant] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);

  const fileInputRef = useRef(null);

  const documentCategories = [
    'Working Documents',
    'Formal Documents',
    'Reference Documents',
    'Onsite Documents',
    'Quotes Documents',
    'Existing Property Documents',
    'Other',
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setDocUrl('');
    } else {
      console.log('No file selected');
    }
  };

  const handleUrlChange = (event) => {
    setDocUrl(event.target.value);
    setSelectedFile(null);
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error('File input ref is null');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isFile && !selectedFile) {
      console.error('No file selected');
      return;
    }

    if (!isFile && !docUrl) {
      console.error('No URL provided');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('name', docName);
    formData.append('category', docCategory);
    formData.append('subCategory', docSubCategory);
    formData.append('version', docVersion);
    formData.append('isImportant', isImportant);
    formData.append('visibleToClient', true);
    formData.append('sendEmail', sendEmail);

    if (isFile) {
      formData.append('file', selectedFile);
    } else {
      formData.append('url', docUrl);
    }

    try {
      await uploadDocument(projectId, formData);

      window.location.reload();
    } catch (err) {
      console.error('Error uploading the document', err);
      console.error('Error details:', err.response ? err.response.data : err.message);
    } finally {
      setUploading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pt-6">
      <div className="">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Upload Document(s)</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Select a document category and upload the required file or enter a Google Drive link.</p>
      </div>
      <form onSubmit={handleSubmit} className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <div className="grid grid-cols-6 gap-x-4 col-span-6">
          <div className="col-span-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">Document Name *</label>
            <input
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
              required
            />
          </div>

          <div className="col-span-6 mt-2">
            <label className="block text-sm font-medium leading-6 text-gray-900">Document Importance *</label>
            <select
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
              value={isImportant}
              onChange={(e) => setIsImportant(e.target.value === 'Important')}
            >
              <option value="Important">Important - Requires client approval</option>
              <option value="Non-Important">Non-Important - Does not require client approval</option>
            </select>
          </div>
          <div className="col-span-3 mt-2">
            <label className="block text-sm font-medium leading-6 text-gray-900">Category *</label>
            <select
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
              value={docCategory}
              onChange={(e) => setDocCategory(e.target.value)}
              required
            >
              {documentCategories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-3 mt-2">
            <label className="block text-sm font-medium leading-6 text-gray-900">Document Version</label>
            <input
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
              value={docVersion}
              onChange={(e) => setDocVersion(e.target.value)}
              placeholder="Enter version number"
            />
          </div>

          {userRole === 'admin' && (
            <div className="col-span-6 mt-2">
              <label className="block text-sm font-medium leading-6 text-gray-900">Send Email Notification</label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox" checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
                  <span className="ml-2">Send email notification to project members</span>
                </label>
              </div>
            </div>
          )}

          <div className="col-span-6 mt-4">
            <label className="block text-sm font-medium leading-6 text-gray-900">Document Source *</label>
            <div className="mt-2">
              <label className="inline-flex items-center mr-6">
                <input type="radio" className="form-radio" name="documentSource" checked={isFile} onChange={() => setIsFile(true)} />
                <span className="ml-2">File Upload</span>
              </label>
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="documentSource" checked={!isFile} onChange={() => setIsFile(false)} />
                <span className="ml-2">Google Drive Link</span>
              </label>
            </div>
          </div>
        </div>
        {isFile ? (
          <div className="col-span-6">
            <div className="flex items-center justify-between text-sm ">
              <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="truncate font-medium ml-4">{selectedFile ? selectedFile.name : 'No document selected'}</span>
              </div>
            </div>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 h-[200px]">
              <div className="text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                <button
                  type="button"
                  onClick={handleUploadClick}
                  className="mt-2 text-xs text-[#2A9D8F] hover:text-[#264653] focus:outline-none focus:underline"
                >
                  Upload a Document
                </button>
                <input
                  ref={fileInputRef}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-span-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">Google Drive Link:</label>
            <input
              type="url"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
              value={docUrl}
              onChange={handleUrlChange}
              required={!isFile}
              placeholder="Enter URL"
            />
          </div>
        )}

        <div className="col-span-6 mt-4">
          {uploading ? (
            <div>Uploading. Please do not close this page...</div>
          ) : (
            <button
              type="submit"
              className="px-4 py-2 bg-[#2A9D8F] text-white rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-[#264653] focus:ring-offset-2"
            >
              Upload
            </button>
          )}
        </div>
      </form>
      <LoadingModal open={uploading} />
    </div>
  );
};

export default UploadDocuments;
