import { useState, useEffect } from 'react';
import { getSharedProjects } from '../../services/projectService';
import api from "../../utils/api";

export function useSharedProjects(userEmail) {
  const [sharedProjects, setSharedProjects] = useState([]);

  const fetchSharedProjects = async () => {
    try {
      const fetchedProjects = await getSharedProjects({ email: userEmail });
      setSharedProjects(fetchedProjects);
    } catch (error) {
      console.error('Error fetching shared projects:', error);
    }
  };

  useEffect(() => {
    fetchSharedProjects();
  }, [userEmail]);

  return { sharedProjects, fetchSharedProjects };
}

export default useSharedProjects;