import { useState, useEffect, useCallback } from "react";
import { getDocuments } from "../../services/documentService";
import api from "../../utils/api";

function useGetProjectDocuments(projectId) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllDocuments = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDocuments(projectId);
      setDocuments(response); // Access the 'documents' property
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchAllDocuments();
  }, [fetchAllDocuments]);

  return { documents, loading, error, refetch: fetchAllDocuments };
}

export default useGetProjectDocuments;