import React from 'react';
import { Field, useFormikContext, ErrorMessage } from 'formik';

const additionalSpaceMapping = {
  'Rear single storey extension': 'rearSingleStoreyExtension',
  'Rear double storey extension': 'rearDoubleStoreyExtension',
  'Side single storey extension': 'sideSingleStoreyExtension',
  'Side double storey extension': 'sideDoubleStoreyExtension',
  'Infill extension': 'infillExtension',
  'Loft conversion': 'loftConversion',
  Basement: 'basement',
  'Garage Conversion': 'garageConversion',
  Porch: 'porch',
  'Outbuilding/ Garden Room': 'outbuildingGardenRoom',
  'New Garage/ Carport': 'newGarageCarport',
};

const internalLayoutReconfigurationMapping = {
  'Full reconfiguration of existing': 'fullReconfigurationOfExisting',
  'Partial (i.e ground floor only)': 'partial',
  'Affected areas only': 'affectedAreasOnly',
  Unknown: 'unknown',
};

const servicesMapping = {
  'Electric (re-wiring, CCU relocation, etc)': 'electric',
  'Gas (New boiler, plumbing, heating, etc)': 'gas',
  'Water (Upgrade pressure, new pipes, etc)': 'water',
  'Green home renewable energy': 'greenHomeRenewableEnergy',
  Unknown: 'unknown',
};

const decorationMapping = {
  'Whole house internal': 'wholeHouseInternal',
  'Whole house external': 'wholeHouseExternal',
  'Affected areas only': 'affectedAreasOnly',
  Unknown: 'unknown',
};

const sectionMappings = [
  additionalSpaceMapping,
  internalLayoutReconfigurationMapping,
  servicesMapping,
  decorationMapping,
];

const AdditionalInfo = () => {
  const { values } = useFormikContext();

  const sections = [
    {
      title: 'Additional Space ',
      subtitle:
        'Please select which routes you are interested in exploring in your Project in order to gain more space in your home.',
      options: [
        'Rear single storey extension',
        'Rear double storey extension',
        'Side single storey extension',
        'Side double storey extension',
        'Infill extension',
        'Loft conversion',
        'Basement',
        'Garage Conversion',
        'Porch',
        'Outbuilding/ Garden Room',
        'New Garage/ Carport',
      ],
    },

    {
      title: 'Internal layout re-configuration',
      subtitle:
        'Moving walls and altering layouts can make a huge difference to your home. Please select if you know you would like to change your internal layouts.',
      options: [
        'Full reconfiguration of existing',
        'Partial (i.e ground floor only)',
        'Affected areas only',
        'Unknown',
      ],
    },
    {
      title: 'Services',
      subtitle:
        'If known please indicate the services which you would like to upgrade/ require attention or works to them',
      options: [
        'Electric (re-wiring, CCU relocation, etc)',
        'Gas (New boiler, plumbing, heating, etc)',
        'Water (Upgrade pressure, new pipes, etc)',
        'Green home renewable energy',
        'Unknown',
      ],
    },
    {
      title: 'Decoration',
      subtitle:
        'Please select the decoration works you would like to carry out',
      options: [
        'Whole house internal',
        'Whole house external',
        'Affected areas only',
        'Unknown',
      ],
    },
    {
      title: 'Further Details ',
      subtitle: 'Please provide any further details you may have',
      isTextBox: true,
    },
  ];

  const sectionKeys = [
    'additionalSpace',
    'internalLayoutReconfiguration',
    'services',
    'decoration',
  ];

  function toCamelCase(str) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      .replace(/[^a-zA-Z0-9]/g, '');
  }

  function toComputerReadable(str) {
    return str
      .toLowerCase()
      .replace(/\s+/g, '')
      .replace(/[^\w\s]/gi, '');
  }
  return (
    <div className="flex flex-col gap-y-10 border-b border-gray-900/10 pb-12 md:flex-row md:items-start">
      <div className="w-full md:w-1/3">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Additional Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Please provide any additional information you may have.
        </p>
      </div>
      <div className="flex flex-col gap-y-8 w-full md:w-2/3">
        {sections.map((section, index) => (
          <div key={index} className="w-full">
            <label
              htmlFor="onlineLink"
              className="block text-sm font-medium leAdading-6 text-gray-900"
            >
              {section.title}
            </label>
            {section.subtitle && (
              <p className="text-xs leading-5 text-gray-600 mt-2">
                {section.subtitle}
              </p>
            )}
            <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {section.isTextBox ? (
                <Field
                  as="textarea"
                  placeholder="Enter details here"
                  name={`additionalInfo.furtherDetails`}
                  className="col-span-1 md:col-span-2 lg:col-span-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
                />
              ) : (
                section.options.map((option, i) => (
                  <div key={i} className="col-span-1">
                    <div className="flex items-center">
                      <Field
                        type="checkbox"
                        name={`additionalInfo.${sectionKeys[index]}.${sectionMappings[index][option]}`}
                        id={`additionalInfo.${sectionKeys[index]}.${sectionMappings[index][option]}`}
                        className="h-4 w-4 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#264653]"
                      />
                      <label
                        htmlFor={`additionalInfo.${sectionKeys[index]}.${sectionMappings[index][option]}`}
                        className="ml-3 text-sm leading-6 text-gray-900"
                      >
                        {option}
                      </label>
                    </div>
                    <ErrorMessage
                      name={`additionalInfo.${sectionKeys[index]}.${sectionMappings[index][option]}`}
                      component="div"
                      className="text-xs text-red-500 italic"
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalInfo;
