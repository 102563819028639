import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import api from "../../utils/api";

const useGetTasksByUser = (userEmail) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTasksByUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_SERVER_URL}/api/tasks/user/${userEmail}/tasks`
      ); // Adjust the endpoint accordingly
      setTasks(response.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userEmail) {
      fetchTasksByUser();
    }
  }, [userEmail, fetchTasksByUser]);

  return { tasks, loading, error, refetch: fetchTasksByUser };
};

export default useGetTasksByUser;
