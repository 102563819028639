import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { PhotoIcon } from '@heroicons/react/24/solid';

const ImageUploader = ({ onDrop, isDragActive }) => {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <div className="my-6">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Upload New Image</label>
      <div
        {...getRootProps()}
        className={`flex items-center justify-center p-6 bg-gray-50 border border-dashed border-gray-900/25 rounded-lg ${isDragActive ? 'bg-blue-50' : ''}`}
      >
        <input {...getInputProps()} accept="image/*" capture="environment" className="sr-only" />
        <div className="text-center">
          <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2A9D8F] focus-within:ring-offset-2 hover:text-[#2A9D8F]"
            >
              <span>Upload a file</span>
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
