import React from "react";

const additionalSpaceMapping = {
  "Rear single storey extension": "rearSingleStoreyExtension",
  "Rear double storey extension": "rearDoubleStoreyExtension",
  "Side single storey extension": "sideSingleStoreyExtension",
  "Side double storey extension": "sideDoubleStoreyExtension",
  "Infill extension": "infillExtension",
  "Loft conversion": "loftConversion",
  Basement: "basement",
  "Garage Conversion": "garageConversion",
  Porch: "porch",
  "Outbuilding/ Garden Room": "outbuildingGardenRoom",
  "New Garage/ Carport": "newGarageCarport",
};

const internalLayoutReconfigurationMapping = {
  "Full reconfiguration of existing": "fullReconfigurationOfExisting",
  "Partial (i.e ground floor only)": "partial",
  "Affected areas only": "affectedAreasOnly",
  Unknown: "unknown",
};

const servicesMapping = {
  "Electric (re-wiring, CCU relocation, etc)": "electric",
  "Gas (New boiler, plumbing, heating, etc)": "gas",
  "Water (Upgrade pressure, new pipes, etc)": "water",
  "Green home renewable energy": "greenHomeRenewableEnergy",
  Unknown: "unknown",
};

const decorationMapping = {
  "Whole house internal": "wholeHouseInternal",
  "Whole house external": "wholeHouseExternal",
  "Affected areas only": "affectedAreasOnly",
  Unknown: "unknown",
};

const sectionMappings = [
  additionalSpaceMapping,
  internalLayoutReconfigurationMapping,
  servicesMapping,
  decorationMapping,
];

const sectionKeys = [
  "additionalSpace",
  "internalLayoutReconfiguration",
  "services",
  "decoration",
];

const sections = [
  {
    title: "Additional Space ",
    subtitle:
      "Please select which routes you are interested in exploring in your Project in order to gain more space in your home.",
    options: [
      "Rear single storey extension",
      "Rear double storey extension",
      "Side single storey extension",
      "Side double storey extension",
      "Infill extension",
      "Loft conversion",
      "Basement",
      "Garage Conversion",
      "Porch",
      "Outbuilding/ Garden Room",
      "New Garage/ Carport",
    ],
  },

  {
    title: "Internal Layout Re-Configuration",
    subtitle:
      "Moving walls and altering layouts can make a huge difference to your home. Please select if you know you would like to change your internal layouts.",
    options: [
      "Full reconfiguration of existing",
      "Partial (i.e ground floor only)",
      "Affected areas only",
      "Unknown",
    ],
  },
  {
    title: "Services",
    subtitle:
      "If known please indicate the services which you would like to upgrade/ require attention or works to them",
    options: [
      "Electric (re-wiring, CCU relocation, etc)",
      "Gas (New boiler, plumbing, heating, etc)",
      "Water (Upgrade pressure, new pipes, etc)",
      "Green home renewable energy",
      "Unknown",
    ],
  },
  {
    title: "Decoration",
    subtitle: "Please select the decoration works you would like to carry out",
    options: [
      "Whole house internal",
      "Whole house external",
      "Affected areas only",
      "Unknown",
    ],
  },
];

const StyleAndServicesInformation = ({
  project = {
    additionalInfo: {
      additionalSpace: {},
      internalLayoutReconfiguration: {},
      services: {},
      decoration: {},
      furtherDetails: "",
    },
    projectStyle: "",
    styleComments: "",
  },
}) => {
  const {
    additionalInfo: {
      additionalSpace = {},
      internalLayoutReconfiguration = {},
      services = {},
      decoration = {},
      furtherDetails = "",
    } = {},
    projectStyle = "",
    styleComments = "",
  } = project || {};

  const renderOptions = (section, mapping) => {
    if (!section || !mapping) return <li>N/A</li>; // Add this line
    return Object.keys(mapping).map((key) => {
      if (section[mapping[key]]) {
        return <li key={key}>{key}</li>;
      }
      return null;
    });
  };

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Style & Services
        </h3>
      </div>
      <div className="mt-6 border-t  border-gray-100">
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b  border-gray-100">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Project Style
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 ">
            {projectStyle.charAt(0).toUpperCase() + projectStyle.slice(1)}
          </dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b  border-gray-100">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Style Comments
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {styleComments}
          </dd>
        </div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-gray-100"
          >
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {section.title}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <ul>
                {renderOptions(
                  {
                    additionalSpace,
                    internalLayoutReconfiguration,
                    services,
                    decoration,
                  }[sectionKeys[index]],
                  sectionMappings[index]
                )}
              </ul>
            </dd>
          </div>
        ))}
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Further Details
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {furtherDetails}
          </dd>
        </div>
      </div>
    </div>
  );
};

export default StyleAndServicesInformation;
