import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const changeRequestService = {
  getChangeRequests: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/change-requests/project/${projectId}/change-requests`);
      return response.data;
    } catch (error) {
      console.error('Error fetching change requests:', error.message);
      throw error;
    }
  },

  createChangeRequest: async (changeRequestData) => {
    try {
      const response = await api.post(`${API_URL}/api/change-requests`, changeRequestData);
      return response.data;
    } catch (error) {
      console.error('Error creating change request:', error.message);
      throw error;
    }
  },

  updateChangeRequest: async (changeRequestId, updatedData) => {
    try {
      const response = await api.put(`${API_URL}/api/change-requests/${changeRequestId}`, updatedData);
      return response.data;
    } catch (error) {
      console.error('Error updating change request:', error.message);
      throw error;
    }
  },

  deleteChangeRequest: async (changeRequestId) => {
    try {
      await api.delete(`${API_URL}/api/change-requests/${changeRequestId}`);
    } catch (error) {
      console.error('Error deleting change request:', error.message);
      throw error;
    }
  },

  addActionItem: async (changeRequestId, actionItem) => {
    try {
      const response = await api.post(`${API_URL}/api/change-requests/${changeRequestId}/action-items`, actionItem);
      return response.data;
    } catch (error) {
      console.error('Error adding action item:', error.message);
      throw error;
    }
  },

  updateActionItem: async (changeRequestId, actionItemId, updatedActionItem) => {
    try {
      const response = await api.put(`${API_URL}/api/change-requests/${changeRequestId}/action-items/${actionItemId}`, updatedActionItem);
      return response.data;
    } catch (error) {
      console.error('Error updating action item:', error.message);
      throw error;
    }
  },

  removeActionItem: async (changeRequestId, actionItemId) => {
    try {
      const response = await api.delete(`${API_URL}/api/change-requests/${changeRequestId}/action-items/${actionItemId}`);
      return response.data;
    } catch (error) {
      console.error('Error removing action item:', error.message);
      throw error;
    }
  }
};

export default changeRequestService;

export const { 
  getChangeRequests, 
  createChangeRequest, 
  updateChangeRequest, 
  deleteChangeRequest,
  addActionItem,
  updateActionItem,
  removeActionItem
} = changeRequestService;