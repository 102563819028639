import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { BarsArrowDownIcon } from '@heroicons/react/24/outline';
import api from '../../../utils/api';

const TaskStatusLabel = ({ task, fetchTasks, index, totalCount, onStatusChange, isNotAdmin }) => {
  const [initialStatus, setInitialStatus] = useState(task.status);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const [dropdownDirection, setDropdownDirection] = useState('down');

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the dropdown is currently shown and if the click was outside of the dropdown
      if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Attach the click event listener
    document.addEventListener('mousedown', handleClickOutside);

    if (index === totalCount - 1) {
      setDropdownDirection('up');
    } else {
      setDropdownDirection('down');
    }

    // Cleanup - remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown, index, totalCount]);

  let backgroundColor = '';
  let textColor = '';
  let ringColor = '';
  let displayStatus = '';

  switch (initialStatus) {
    case 'Not Started':
      backgroundColor = 'bg-yellow-50';
      textColor = 'text-yellow-700';
      ringColor = 'ring-yellow-600';
      displayStatus = 'Not Started';
      break;
    case 'In Progress':
      backgroundColor = 'bg-blue-50';
      textColor = 'text-blue-700';
      ringColor = 'ring-blue-600';
      displayStatus = 'In Progress';
      break;
    case 'Completed':
      backgroundColor = 'bg-green-50';
      textColor = 'text-green-700';
      ringColor = 'ring-green-600';
      displayStatus = 'Completed';
      break;
    default:
      break;
  }
  const updateTaskStatus = async (taskId, newState) => {
    try {
      const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/tasks/task/${taskId}/update`, {
        status: newState,
        completionDate: newState === 'Completed' ? new Date() : null,
      });

      if (response.status === 200) {
        const updatedTask = {
          ...task,
          status: newState,
          completionDate: newState === 'Completed' ? new Date() : null,
        };
        setInitialStatus(newState);
        // Call the parent function to update the task list.
        if (typeof onStatusChange === 'function') {
          onStatusChange(updatedTask);
        }
      }
    } catch (error) {
      console.error('Error updating task status:', error);

      if (error.response.data.message === 'All dependencies must be completed first') {
        alert('All previous tasks must be completed first');
      }
    }
  };
  const handleStatusUpdate = (newStatus) => {
    updateTaskStatus(task._id, newStatus);
    setShowDropdown(false);
  };

  return (
    <div className="relative">
      <span
        onClick={() => !isNotAdmin && setShowDropdown(!showDropdown)}
        className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}/20 ${
          isNotAdmin ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <div className="flex items-center justify-center">
          {displayStatus}
          {!isNotAdmin && <BarsArrowDownIcon className="ml-2 h-3 w-3" />} {/* Only show dropdown icon for admin */}
        </div>
      </span>
      {!isNotAdmin &&
        showDropdown && ( // <-- Add conditional rendering here
          <div
            ref={dropdownRef}
            className={`absolute z-50 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${
              dropdownDirection === 'up' ? 'bottom-full mb-2' : 'mt-2'
            }`}
            style={{ maxHeight: '150px', overflowY: 'auto' }}
          >
            {['Not Started', 'In Progress', 'Completed'].map((statusOption) => (
              <div key={statusOption} onClick={() => handleStatusUpdate(statusOption)} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                {statusOption}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default TaskStatusLabel;
