import React from 'react';
import { useDrop } from 'react-dnd';
import Image from './Image';

const ItemType = {
  IMAGE: 'image',
};

const Category = ({ name, images, onDropImage, onImageClick, onDeleteImage, onSelectImage, moveImage }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType.IMAGE,
    drop: (item) => onDropImage(item.id, name),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className={`p-6 bg-gray-50 border-4 border-dashed rounded-lg ${isOver ? 'bg-blue-50' : ''}`}>
      <h3 className="text-xl font-semibold leading-7 text-gray-900">{name}</h3>
      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        {images.map((image, index) => (
          <Image
            key={image._id}
            image={image}
            index={index}
            category={name}
            onImageClick={onImageClick}
            onDeleteImage={onDeleteImage}
            onSelectImage={onSelectImage}
            moveImage={moveImage}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
