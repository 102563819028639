import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const MilestonesTimeline = () => {
  const category = ['Design Milestones', 'Permissions Milestones', 'Support Milestones', 'Onsite Milestones'];

  const { projectId } = useParams();
  const [milestones, setMilestones] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(category[0]);
  const [filteredMilestones, setFilteredMilestones] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchMilestones = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/milestones/project/${projectId}/milestones`);
        setMilestones(response.data);
      } catch (error) {
        setErrorMessage('Failed to fetch milestones');
      }
    };

    fetchMilestones();
  }, [projectId]);

  useEffect(() => {
    setFilteredMilestones(milestones.filter((milestone) => milestone.category === selectedCategory));
  }, [selectedCategory, milestones]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const countCompletedMilestones = (category) => {
    const milestonesForCategory = milestones.filter((milestone) => milestone.category === category);
    const completedMilestones = milestonesForCategory.filter((milestone) => milestone.status).length;
    return `${completedMilestones} / ${milestonesForCategory.length}`;
  };

  const getTotalMilestones = () => milestones.length;
  const getCompletedMilestones = () => milestones.filter((milestone) => milestone.status).length;
  const getCompletionPercentage = () => {
    const total = getTotalMilestones();
    const completed = getCompletedMilestones();
    return total > 0 ? ((completed / total) * 100).toFixed(0) : 0;
  };

  const hasMilestonesWithDeadline = milestones.some((m) => m.deadline);

  return (
    <div className="space-y-10">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-xl leading-6 font-semibold text-gray-900">Milestones Summary</h3>
          <p className="mt-2 text-sm text-gray-600">Overview of project milestones.</p>
        </div>
        <div className="border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="px-4 py-4 sm:px-6 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Total Milestones</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getTotalMilestones()}</dd>
            </div>
            <div className="px-4 py-4 sm:px-6 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Completed Milestones</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getCompletedMilestones()}</dd>
            </div>
            <div className="px-4 py-4 sm:px-6 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Completion Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getCompletionPercentage()}% Done</dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-6 sm:px-8">
          <h3 className="text-xl leading-6 font-semibold text-gray-900">Key Dates</h3>
          <p className="mt-2 text-sm text-gray-600">Important milestone deadlines.</p>
        </div>
        <div className="border-t border-gray-200 px-6 py-6 sm:px-8">
          {milestones.length > 0 ? (
            hasMilestonesWithDeadline ? (
              <div className="relative">
                <div className="absolute w-0.5 bg-gray-300 h-full left-9 top-0"></div>
                <ul className="space-y-6 pl-3">
                  {milestones
                    .filter((m) => m.deadline)
                    .sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())
                    .map((milestone, index) => (
                      <li key={milestone._id} className="relative pl-9">
                        <div className="absolute left-0 top-2">
                          <div
                            className={`w-4 h-4 rounded-full border-2 ${milestone.status ? 'bg-green-500 border-green-500' : 'bg-white border-gray-300'}`}
                          ></div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div>
                            <p className="text-sm font-semibold text-gray-900">{milestone.title}</p>
                            <p className="text-xs text-gray-500">Deadline: {formatDate(milestone.deadline)}</p>
                          </div>
                          <span className={`text-xs font-medium ${milestone.status ? 'text-green-500' : 'text-yellow-500'}`}>
                            {milestone.status ? 'Completed' : 'Pending'}
                          </span>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <p className="text-sm text-gray-500">No milestones with deadlines set.</p>
            )
          ) : (
            <p className="text-sm text-gray-500">No milestones available.</p>
          )}
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-6 sm:px-8">
          <h3 className="text-xl leading-6 font-semibold text-gray-900">Milestone Categories</h3>
          <p className="mt-2 text-sm text-gray-600">Select a category to view specific milestones.</p>
        </div>
        <div className="border-t border-gray-200 px-6 py-6 sm:px-8">
          <div className="flex flex-wrap gap-3">
            {category.map((cat, index) => (
              <button
                key={index}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  cat === selectedCategory ? 'bg-[#2A9D8F] text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setSelectedCategory(cat)}
              >
                {cat} ({countCompletedMilestones(cat)})
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-6 sm:px-8">
          <h3 className="text-xl leading-6 font-semibold text-gray-900">Milestones for {selectedCategory}</h3>
        </div>
        <div className="border-t border-gray-200">
          {filteredMilestones.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {filteredMilestones.map((milestone) => (
                <li key={milestone._id} className="px-6 py-6 sm:px-8 hover:bg-gray-50">
                  <Link to={`/dashboard/project/milestones/${projectId}`} className="block">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-[#2A9D8F] truncate">{milestone.title}</p>
                      <div className="ml-2 flex-shrink-0 flex">
                        <p
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            milestone.status ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                          }`}
                        >
                          {milestone.status ? 'Completed' : 'Pending'}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="px-6 py-6 sm:px-8 text-sm text-gray-500">No milestones available for this category. Please go to Milestones to add new milestones.</p>
          )}
        </div>
      </div>

      {errorMessage && (
        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default MilestonesTimeline;
