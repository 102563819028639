import React, { useEffect, useState, useContext } from 'react';

import { useParams } from 'react-router-dom';

import useGetTasksByUser from '../Hooks/useGetTasksByUser';

import { Spinner } from 'flowbite-react';

import TasksCalendar from '../Projects/Tasks/TasksCalendar';

import { UserContext } from '../../App';

const PersonalCalendarPage = () => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const email = userContextData.email;

  const { tasks, isLoading, isError, refetch } = useGetTasksByUser(email);

  useEffect(() => {}, [tasks]);

  if (isLoading) {
    return (
      <div>
        <Spinner aria-label="Info spinner example" color="info" />
      </div>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <TasksCalendar tasks={tasks} />
    </div>
  );
};

export default PersonalCalendarPage;
