// Import necessary dependencies and components
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import { useNavigate, useParams, useLocation, Routes, Route } from 'react-router-dom';
import { DocumentDuplicateIcon, FolderIcon, PlusIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import api from '../utils/api';

// Import UI components
import Sidebar from './UI/Sidebar';
import MobileSidebar from './UI/MobileSidebar';
import Header from './UI/Header';

// Import page components
import ProjectCreatePage from './Pages/ProjectPages/ProjectCreatePage';
import ProjectAllTablePage from './Pages/ProjectPages/ProjectAllTablePage';
import ProjectSharedPage from './Pages/ProjectPages/ProjectSharedPage';
import ProjectEditPage from './Pages/ProjectPages/ProjectEditPage';
import ProjectVersionsTablePage from './Pages/ProjectPages/ProjectVersionsTablePage';
import ProjectSeeDetailsPage from './Pages/ProjectPages/ProjectSeeDetailsPage';
import ProjectAttachmentsPage from './Pages/ProjectPages/ProjectAttachmentsPage';
import TasksPage from './Pages/TasksPage';
import TaskManagerPage from './Pages/TaskManagerPage';
import CalendarPage from './Pages/CalendarPage';
import PersonalCalendarPage from './Pages/PersonalCalendarPage';
import ProjectNotesPage from './Pages/ProjectPages/ProjectNotesPage';
import ProjectSummaryPage from './Pages/ProjectPages/ProjectSummaryPage';
import ProjectImagesPage from './Pages/ProjectPages/ProjectImagesPage';
import ProjectMilestonesPage from './Pages/ProjectPages/ProjectMilestonesPage';
import ProjectChangeRequestPage from './Pages/ProjectPages/ProjectChangeRequestPage';
import ProjectContactsPage from './Pages/ProjectPages/ProjectContactsPage';
import OrganisationPage from './Pages/OrganisationPage';
import ProfilePage from './Pages/ProfilePage';
import DashboardOverviewPage from './Pages/DashboardOverviewPage';

// Import custom hooks
import useProjects from './Hooks/useProjects';
import useSharedProjects from './Hooks/useSharedProjects';

// Import navigation constants
import { USER_NAVIGATION, ADMIN_NAVIGATION_LINKS, CLIENT_NAVIGATION_LINKS } from '../constants/navigationLinks';

// Helper function to update navigation links' current state
const updateNavigationLinksCurrentState = (navigationLinks, currentPath) => {
  return navigationLinks.map((link) => {
    const isExactMatch = currentPath === link.href;
    const matchesBasePathExactlyOrWithSlash = currentPath === link.href || currentPath.startsWith(`${link.href}/`);
    const isDashboard = link.href === '/dashboard';
    const shouldMarkCurrent = isDashboard ? isExactMatch : matchesBasePathExactlyOrWithSlash;
    return { ...link, current: shouldMarkCurrent };
  });
};

export default function MainDashboardPage() {
  // Extract user context and routing information
  const { userContextData } = useContext(UserContext);
  const { projects, fetchProjects } = useProjects(userContextData);
  const { sharedProjects, fetchSharedProjects } = useSharedProjects(userContextData.email);
  const location = useLocation();
  const navigate = useNavigate();
  const pathParts = location.pathname.split('/');
  const projectId = pathParts[pathParts.length - 1];

  // State management
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    id: userContextData.selected_project || null,
    status: userContextData.selected_project_status || null,
  });

  const isAdmin = userContextData?.type === 'Firm';

  const [navLinks, setNavLinks] = useState(updateNavigationLinksCurrentState(isAdmin ? ADMIN_NAVIGATION_LINKS : CLIENT_NAVIGATION_LINKS, location.pathname));

  const [navigationVisibility, setNavigationVisibility] = useState({
    showSelectedProjectNavigation: true,
    showAdvancedNavigation: true,
  });

  // Redirect non-admin users to shared projects page
  useEffect(() => {
    if (!isAdmin && location.pathname === '/dashboard') {
      navigate('/dashboard/projects');
    }
  }, [isAdmin, location.pathname, navigate]);

  // Update navigation links when location changes
  useEffect(() => {
    setNavLinks((prevLinks) => updateNavigationLinksCurrentState(prevLinks, location.pathname));
  }, [location.pathname, isAdmin]);

  // Update selected project when context or URL changes
  useEffect(() => {
    setSelectedProject((prevState) => ({
      id: projectId.length === 24 ? projectId : userContextData.selected_project,
      status: userContextData.selected_project_status,
    }));
  }, [userContextData.selected_project, userContextData.selected_project_status, projectId]);

  // Determine navigation visibility based on current page and user role
  useEffect(() => {
    const isOnGeneralNavigationPage = () => {
      const generalPaths = ['/dashboard', '/dashboard/projects', '/dashboard/shared'];
      return generalPaths.some((path) => location.pathname.startsWith(path)) && !location.pathname.includes('/project/');
    };

    const isOnProjectRelatedPage = () => {
      return location.pathname.includes('/project/') || location.pathname.includes('/edit-project/');
    };

    const onGeneralNavigationPage = isOnGeneralNavigationPage();
    const onProjectRelatedPage = isOnProjectRelatedPage();

    setNavigationVisibility({
      showSelectedProjectNavigation: selectedProject.id && (isAdmin || selectedProject.status !== 'clientReview') && onProjectRelatedPage,
      showAdvancedNavigation: selectedProject.id && isAdmin && onProjectRelatedPage,
    });
  }, [location.pathname, selectedProject, isAdmin]);

  // Define navigation items for selected project
  const selectedProjectNavigation = [
    { id: 1, name: 'Summary', href: `/dashboard/project/summary/${selectedProject?.id}`, initial: 'S', current: true },
    { id: 2, name: 'Milestones', href: `/dashboard/project/milestones/${selectedProject?.id}`, initial: 'M', current: false },
    { id: 3, name: 'Documents', href: `/dashboard/project/attachments/${selectedProject?.id}`, initial: 'D', current: false },
    { id: 4, name: 'Change Requests', href: `/dashboard/project/change-requests/${selectedProject?.id}`, initial: 'CR', current: false },
    { id: 5, name: 'Notes', href: `/dashboard/project/notes/${selectedProject?.id}`, initial: 'N', current: false },
    { id: 6, name: 'Images', href: `/dashboard/project/images/${selectedProject?.id}`, initial: 'I', current: false },
    { id: 7, name: 'Contacts', href: `/dashboard/project/contacts/${selectedProject?.id}`, initial: 'C', current: false },
  ];

  // Define advanced navigation items
  const advancedNavigation = [
    { id: 8, name: 'Admin', href: `/dashboard/project/${selectedProject?.id}`, initial: 'A', current: false },
    { id: 10, name: 'Edit', href: `/dashboard/edit-project/${selectedProject?.id}`, initial: 'E', current: false },
  ];

  return (
    <>
      <div>
        {/* Mobile sidebar component */}
        <MobileSidebar
          navigation={navLinks}
          selectedProjectNavigation={navigationVisibility.showSelectedProjectNavigation ? selectedProjectNavigation : []}
          advancedNavigation={navigationVisibility.showAdvancedNavigation ? advancedNavigation : []}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        {/* Desktop sidebar component */}
        <Sidebar
          navigation={navLinks}
          selectedProjectNavigation={navigationVisibility.showSelectedProjectNavigation ? selectedProjectNavigation : []}
          advancedNavigation={navigationVisibility.showAdvancedNavigation ? advancedNavigation : []}
        />

        <div className="lg:pl-72">
          {/* Header component */}
          <Header setSidebarOpen={setSidebarOpen} userNavigation={USER_NAVIGATION} projectId={projectId} projects={projects} />

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              {/* Route definitions */}
              {/* prettier-ignore */}
              <Routes>
                <Route path="/" element={<DashboardOverviewPage />} />
                <Route path="/add-project" element={<ProjectCreatePage fetchProjects={fetchProjects} />} />
                <Route path="/edit-project/:projectId" element={<ProjectEditPage projects={sharedProjects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />} />
                <Route path="/projects" element={<ProjectAllTablePage projects={projects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />} />
                <Route path="/projects/:projectId" element={<ProjectSeeDetailsPage projects={projects} sharedProjects={sharedProjects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />} />
                <Route path="/task-manager" element={<TaskManagerPage />} />
                <Route path="/project/:projectId" element={<ProjectSeeDetailsPage projects={projects} sharedProjects={sharedProjects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />} />
                <Route path="/project/summary/:projectId" element={<ProjectSummaryPage />} />
                <Route path="/project/attachments/:projectId" element={<ProjectAttachmentsPage />} />
                <Route path="/project/tasks/:projectId" element={<TasksPage />} />
                <Route path="/project/calendar/:projectId" element={<CalendarPage />} />
                <Route path="/project/notes/:projectId" element={<ProjectNotesPage />} />
                <Route path="/project/version-control/:projectId" element={<ProjectVersionsTablePage />} />
                <Route path="/project/change-requests/:projectId" element={<ProjectChangeRequestPage />} />
                <Route path="/project/contacts/:projectId" element={<ProjectContactsPage />} />
                <Route path="/shared" element={<ProjectSharedPage projects={sharedProjects} fetchProjects={fetchSharedProjects} />} />
                <Route path="/tasks" element={<PersonalCalendarPage />} />
                <Route path="/project/images/:projectId" element={<ProjectImagesPage />} />
                <Route path="/projects/versions/:projectId" element={<ProjectVersionsTablePage />} />
                <Route path="/project/milestones/:projectId" element={<ProjectMilestonesPage />} />
                <Route path="/organisation" element={<OrganisationPage />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
