import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const projectService = {
  getProject: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/projects/getProject/${projectId}`);
      return response.data.project;
    } catch (error) {
      console.error('Error fetching project:', error.message);
      throw error;
    }
  },

  getUserProjects: async (auth0_id) => {
    try {
      const response = await api.get(`${API_URL}/api/projects/getUserProjects`, { params: { auth0_id } });
      return response.data.projects;
    } catch (error) {
      console.error('Error fetching user projects:', error.message);
      throw error;
    }
  },

  getSharedProjects: async (userInfo) => {
    try {
      const response = await api.get(`${API_URL}/api/projects/getSharedProjects`, { params: { userInfo } });
      return response.data.projects;
    } catch (error) {
      console.error('Error fetching shared projects:', error.message);
      throw error;
    }
  },

  createProject: async (formData) => {
    try {
      const response = await api.post(`${API_URL}/api/projects/create`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data.project;
    } catch (error) {
      console.error('Error creating project:', error.message);
      throw error;
    }
  },

  updateProject: async (projectId, formData) => {
    try {
      const response = await api.put(`${API_URL}/api/projects/updateProject/${projectId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating project:', error.message);
      throw error;
    }
  },

  getUserRole: async (projectId, userId) => {
    try {
      const response = await api.get(`${API_URL}/api/projects/userRole/${projectId}/${userId}`);
      return response.data.role;
    } catch (error) {
      console.error('Error fetching user role:', error.message);
      throw error;
    }
  },
};

export default projectService;

export const { getProject, getUserProjects, getSharedProjects, createProject, updateProject, getUserRole } = projectService;