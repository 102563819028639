import { useState, useEffect, useCallback } from "react";
import { getChangeRequests } from "../../services/changeRequestService";

function useGetProjectChangeRequests(projectId) {
  const [changeRequests, setChangeRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchChangeRequests = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getChangeRequests(projectId);
      setChangeRequests(data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchChangeRequests();
  }, [fetchChangeRequests]);

  return { changeRequests, loading, error, refetch: fetchChangeRequests };
}

export default useGetProjectChangeRequests;