import React from "react";
import { FolderIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const SectionHeading = ({ title }) => {
  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h3>
      <div className="mt-3 sm:ml-4 sm:mt-0">
        {/* <Link to="/dashboard/projects">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FolderIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            All Projects
          </button>
        </Link> */}
      </div>
    </div>
  );
};

export default SectionHeading;
