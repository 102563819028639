import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const handleLogin = async () => {
    const params = new URLSearchParams(location.search);
    const inviteEmail = params.get('email');

    const options = {};
    if (inviteEmail) {
      options.screen_hint = 'signup';
      options.login_hint = inviteEmail;
      options.appState = { inviteEmail };
    }
    try {
      await loginWithRedirect(options);
    } catch (error) {
      console.error('Error during loginWithRedirect:', error);
    }
  };

  return (
    <button onClick={handleLogin} className="rounded bg-[#2A9D8F] px-4 py-2 font-medium text-white hover:bg-[#2A9D8F]">
      Log In / Sign Up
    </button>
  );
};

export default LoginButton;
