import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import useGetSignedUrls from '../../Hooks/useGetSignedUrls';
import { Attachments } from '../../Projects/Attachments';
import { Spinner } from 'flowbite-react';

const ProjectAttachmentsPage = () => {
  const { projectId } = useParams();

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Attachments projectId={projectId} />
    </div>
  );
};

export default ProjectAttachmentsPage;
