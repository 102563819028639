import React, { useState, useEffect } from 'react';
import UserDefaultTaskEditModal from './UserDefaultTaskEditModal'; // Import your edit modal component
import useGetDefaultTasks from '../../Hooks/useGetDefaultTasks';
import UserDefaultTaskAddModal from './UserDefaultTaskAddModal';
import api from '../../../utils/api';

const UserDefaultTasksTable = ({ userId, defaultTasks }) => {
  const [initialTasks, setInitialTasks] = useState(defaultTasks);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { fetchDefaultTasks } = useGetDefaultTasks(userId);

  useEffect(() => {
    setInitialTasks(defaultTasks);
  }, [defaultTasks]);

  // Group tasks by stage
  const groupedTasksByStage = initialTasks ? Object.entries(initialTasks) : [];

  // ...existing code

  const handleAddClick = async (newTask, stage) => {
    try {
      await api.post(`${process.env.REACT_APP_SERVER_URL}/api/users/user/${userId}/addDefaultTask/${stage}`, newTask);
      //   fetchTasks();
      setOpenAddModal(false); // close the modal
      window.location.reload();
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const handleEditClick = (task, stage) => {
    setSelectedTask({ ...task, stage });
    setOpenModal(true);
  };

  const handleDeleteClick = async (task, stage) => {
    try {
      const confirmDelete = window.confirm(`Are you sure you want to delete the task "${task.name}"?`);

      if (!confirmDelete) return;

      const normalizedTaskName = task.name.trim().toLowerCase();

      await api.delete(`${process.env.REACT_APP_SERVER_URL}/api/users/user/${userId}/deleteDefaultTask/${stage}/${normalizedTaskName}`);

      window.location.reload();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-20">
      {groupedTasksByStage.map(([stage, tasksForStage]) => {
        return (
          <div key={stage}>
            <div className="sm:flex sm:items-center mb-4">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">{stage} Stage</h2>
            </div>
            <div className="mt-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="divide-y divide-gray-300 w-full mx-auto">
                    <div className="flex items-center mb-2">
                      {['Task Name', 'Days To Complete', 'Actions'].map((header) => (
                        <div className="flex-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" key={header}>
                          {header}
                        </div>
                      ))}
                    </div>
                    {tasksForStage.map((task, index) => (
                      <div key={index} className="flex items-center">
                        <div className="flex-1 px-3 py-4 text-sm font-medium text-gray-900">
                          {task.name
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')}{' '}
                          {/* Capitalize first letters of words */}
                        </div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">{task.days} days</div>
                        <div className="flex-1 px-3 py-4 text-sm text-gray-500">
                          <button className="font-medium hover:underline text-[#2A9D8F] hover:text-[#264653]" onClick={() => handleEditClick(task, stage)}>
                            Edit
                          </button>
                          <button
                            className="ml-4 font-medium hover:underline text-[#E76F51] hover:text-[#264653]"
                            onClick={() => handleDeleteClick(task, stage)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <button onClick={() => setOpenAddModal(true)} className="mb-4 bg-[#2A9D8F] hover:bg-[#264653] text-white p-2 rounded">
        Add Task
      </button>

      {/* Modal */}
      {openModal && <UserDefaultTaskEditModal task={selectedTask} stage={selectedTask?.stage} setOpen={setOpenModal} fetchTasks={fetchDefaultTasks} />}

      {openAddModal && <UserDefaultTaskAddModal initialTasks={initialTasks} setOpen={setOpenAddModal} fetchTasks={fetchDefaultTasks} />}
    </div>
  );
};

export default UserDefaultTasksTable;
