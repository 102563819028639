import { useState, useEffect } from "react";
import { getProject } from "../../services/projectService";

function useGetProject(projectId) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    // Immediately return null if projectId is not defined
    if (!projectId) {
      setLoading(false); // Set loading to false as there's nothing to load
      return;
    }

    // Check if projectId follows this format - 65ff008df882efdc999692df
    if (projectId.length !== 24) {
      setLoading(false);
      return;
    }

    const fetchProject = async () => {
      try {
        const project = await getProject(projectId);
        setProject(project);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (projectId.length == 24) {
      fetchProject();
    }
  }, [projectId, refetch]); // Dependency array includes projectId and refetch

  const triggerRefetch = () => {
    setRefetch(!refetch); // Toggle the state to trigger refetch
  };



  return { project, loading, error, refetch: triggerRefetch }; // Include refetch function in the returned object
}

export default useGetProject;