import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import api from "../../utils/api";

const useGetTasks = (projectId) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_SERVER_URL}/api/tasks/project/${projectId}/tasks`
      );
      setTasks(response.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchTasks();
    }
  }, [projectId, fetchTasks]);

  return { tasks, loading, error, refetch: fetchTasks };
};

export default useGetTasks;
