import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { PencilIcon } from '@heroicons/react/24/outline';
import { sub } from 'date-fns';
import api from '../../../utils/api';

export default function SlideOver({ open, setOpen, milestone, handleStatusChange, updateMilestone }) {
  // Initialize localMilestone with milestone or a fallback to prevent errors
  const [localMilestone, setLocalMilestone] = useState(milestone || {});
  // Ensure editMode.subMilestones is initialized as an empty object
  const [editMode, setEditMode] = useState({
    main: false,
    subMilestones: {},
    deadline: false,
  });

  // Adjusted toggleEditMode function
  const toggleEditMode = (id, isSubMilestone = false, isDeadline = false) => {
    setEditMode((prev) => {
      if (isSubMilestone) {
        // Safely toggle the edit mode for subMilestones
        return {
          ...prev,
          subMilestones: {
            ...prev.subMilestones,
            [id]: !prev.subMilestones[id],
          },
        };
      } else if (isDeadline) {
        // Toggle the edit mode for the deadline
        return {
          ...prev,
          deadline: !prev.deadline,
        };
      } else {
        // Toggle the edit mode for the main milestone
        return {
          ...prev,
          main: !prev.main,
        };
      }
    });
  };

  useEffect(() => {
    // Update localMilestone whenever the milestone prop changes
    setLocalMilestone(milestone || {});
  }, [milestone]);

  // Function to toggle edit mode

  const toggleMainStatus = () => {
    // Check if any sub-milestones are incomplete
    const incompleteSubMilestones = localMilestone.subMilestones?.some((sub) => !sub.status);

    // Prompt user for confirmation if trying to complete the main milestone while sub-milestones are incomplete
    if (incompleteSubMilestones && !window.confirm('Not all sub-milestones are completed. Are you sure you want to mark the main milestone as complete?')) {
      return; // User cancelled the action
    }

    // Update the status of the main milestone
    const updatedMilestone = {
      ...localMilestone,
      status: !localMilestone.status,
    };

    // Update local state
    setLocalMilestone(updatedMilestone);

    // Invoke the parent component's handleStatusChange function
    // Assuming handleStatusChange accepts the milestone ID and the new status value
    handleStatusChange(updatedMilestone._id, updatedMilestone.status);
  };

  const toggleSubMilestoneStatus = (subMilestoneId) => {
    const updatedSubMilestones = localMilestone.subMilestones.map((subMilestone) => {
      if (subMilestone._id === subMilestoneId) {
        // Toggle the status
        const newStatus = !subMilestone.status;

        // Set completedOn date if new status is true, else set to null
        const newCompletedOn = newStatus ? new Date() : null;
        return {
          ...subMilestone,
          status: newStatus,
          completedOn: newCompletedOn,
        };
      }
      return subMilestone;
    });

    const updatedMilestone = {
      ...localMilestone,
      subMilestones: updatedSubMilestones,
    };

    setLocalMilestone(updatedMilestone);

    // After updating the local state, trigger the handleStatusChange callback
    handleStatusChange(localMilestone._id, subMilestoneId);
  };

  const handleTitleChange = (event, id, isSubMilestone = false) => {
    if (isSubMilestone) {
      const updatedSubMilestones = localMilestone.subMilestones.map((sub) => {
        if (sub._id === id) {
          return { ...sub, title: event.target.value };
        }
        return sub;
      });
      setLocalMilestone((prev) => ({
        ...prev,
        subMilestones: updatedSubMilestones,
      }));
    } else {
      setLocalMilestone((prev) => ({
        ...prev,
        title: event.target.value,
      }));
    }
  };

  const handleDeadlineChange = (e) => {
    setLocalMilestone({ ...localMilestone, deadline: e.target.value });
  };

  const saveChanges = async () => {
    // Assuming `updateUrl` is the endpoint where you update the milestone details
    const updateUrl = `${process.env.REACT_APP_SERVER_URL}/api/milestones/milestones/${localMilestone._id}`;

    try {
      // Make a PUT request to update the milestone
      const response = await api.put(updateUrl, localMilestone);

      updateMilestone(localMilestone);

      // Exit edit mode
      setEditMode({ main: false, subMilestones: {} });
    } catch (error) {
      // Handle error
      console.error('Update failed', error.response ? error.response.data : error);
    }
  };

  if (!milestone) {
    return null; // Early return if milestone is null, rendering nothing
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Milestone Details</Dialog.Title>
                    </div>

                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Milestone Content */}
                      {localMilestone && (
                        <div className="space-y-6">
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Title:</span>
                            <div className="flex justify-between items-center">
                              {editMode.main ? (
                                <div className="flex items-center space-x-2">
                                  <input
                                    type="text"
                                    value={localMilestone.title}
                                    onChange={(event) => handleTitleChange(event, localMilestone._id, false)}
                                    className=" text-sm p-1 border h-full border-gray-300 rounded focus:ring-2 focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                  />
                                  <button
                                    onClick={saveChanges}
                                    className="bg-[#2A9D8F] text-white text-sm px-4 py-1 rounded hover:bg-[#23867a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                  >
                                    Save Changes
                                  </button>
                                  <button
                                    onClick={() => toggleEditMode(localMilestone._id, false)}
                                    className="text-gray-800 text-sm px-4 py-1 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                  >
                                    Dismiss
                                  </button>
                                </div>
                              ) : (
                                <div className="flex items-center space-x-2">
                                  <span className="text-sm text-gray-900 flex-1">{localMilestone.title}</span>
                                  <button
                                    onClick={() => toggleEditMode(localMilestone._id, false)}
                                    className="text-[#2A9D8F] hover:text-[#264653] flex items-center text-xs cursor-pointer"
                                  >
                                    <PencilIcon className="h-5 w-5 mr-1" />
                                    Edit
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Category:</span>
                            <span className="text-sm text-gray-900">{localMilestone.category}</span>
                          </div>

                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Deadline:</span>
                            <span className="text-sm text-gray-900">
                              {editMode.deadline ? (
                                <>
                                  <input
                                    type="date"
                                    className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    value={localMilestone.deadline ? new Date(localMilestone.deadline).toISOString().substring(0, 10) : ''}
                                    onChange={handleDeadlineChange}
                                  />
                                  <button onClick={saveChanges} className="mt-2 mr-2 py-2 px-4 bg-[#2A9D8F] text-white rounded hover:bg-[#133632]">
                                    Save
                                  </button>
                                  <button
                                    onClick={() => toggleEditMode(localMilestone._id, false, true)}
                                    className="mt-2 py-2 px-4 border border-gray-300 rounded shadow-sm text-gray-700 hover:bg-gray-50"
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <div className="flex items-center space-x-2">
                                    <span className="text-sm text-gray-900">
                                      {localMilestone.deadline ? new Date(localMilestone.deadline).toLocaleDateString() : 'No Deadline Set'}
                                    </span>

                                    <button
                                      onClick={() => toggleEditMode(localMilestone._id, false, true)}
                                      className="text-[#2A9D8F] hover:text-[#264653] flex items-center text-xs cursor-pointer"
                                    >
                                      <PencilIcon className="h-5 w-5 mr-1" />
                                      Edit
                                    </button>
                                  </div>
                                </>
                              )}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-500">Status:</span>
                            <div className="flex items-center">
                              <input
                                id={`status-${localMilestone._id}`}
                                name={`status-${localMilestone._id}`}
                                type="checkbox"
                                checked={localMilestone.status}
                                onChange={toggleMainStatus}
                                className="mt-2 w-4 h-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                              />
                              <label htmlFor={`status-${localMilestone._id}`} className="block ml-2 mt-2 text-sm text-gray-900">
                                {localMilestone.status ? 'Completed on ' + new Date(localMilestone.completedOn).toLocaleDateString() : 'Incomplete'}
                              </label>
                            </div>
                          </div>
                          {/* SubMilestones list */}
                          {localMilestone.subMilestones?.length > 0 && (
                            <div className="flex flex-col mt-4">
                              <span className="text-lg font-semibold text-gray-700 mb-2">Sub-Milestones:</span>
                              <ul className="space-y-4">
                                {localMilestone.subMilestones.map((subMilestone) => (
                                  <li key={subMilestone._id} className="flex items-center justify-between bg-white p-4 rounded-lg shadow">
                                    {editMode.subMilestones[subMilestone._id] ? (
                                      <div className="flex-grow">
                                        <input
                                          type="text"
                                          value={subMilestone.title}
                                          onChange={(event) => handleTitleChange(event, subMilestone._id, true)}
                                          className="w-full text-sm p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                        />
                                        <div className="mt-2 flex justify-end space-x-2">
                                          <button
                                            onClick={saveChanges}
                                            className="bg-[#2A9D8F] text-white text-sm px-4 py-2 rounded hover:bg-[#23867a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                          >
                                            Save
                                          </button>
                                          <button
                                            onClick={() => toggleEditMode(subMilestone._id, true)}
                                            className="bg-gray-300 text-gray-800 text-sm px-4 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex-grow">
                                        <div className="flex flex-col">
                                          <span className="text-sm font-medium text-gray-900">{subMilestone.title}</span>
                                          {subMilestone.status && (
                                            <span className="text-xs text-gray-500">
                                              Completed on {new Date(subMilestone.completedOn).toLocaleDateString()}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {!editMode.subMilestones[subMilestone._id] && (
                                      <div className="flex items-center space-x-2">
                                        <input
                                          type="checkbox"
                                          checked={subMilestone.status}
                                          onChange={() => toggleSubMilestoneStatus(subMilestone._id)}
                                          className="w-4 h-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                                        />
                                        <button
                                          onClick={() => toggleEditMode(subMilestone._id, true)}
                                          className="text-[#2A9D8F] hover:text-[#264653] flex items-center text-xs cursor-pointer"
                                        >
                                          <PencilIcon className="h-5 w-5 mr-1" />
                                          Edit
                                        </button>
                                      </div>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
