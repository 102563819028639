import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import SlideOver from './SlideOver';

const MilestoneFolder = ({ category, milestones, handleStatusChange, handleDeleteMilestone, handleDeleteSubMilestone, updateMilestone }) => {
  const [visibleSubMilestones, setVisibleSubMilestones] = useState({});
  const [mainMilestoneCompleteConfirmed, setMainMilestoneCompleteConfirmed] = useState(false);

  const [mainMilestoneDeleteConfirmed, setMainMilestoneDeleteConfirmed] = useState(false);

  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);

  const handleMilestoneClick = (milestone) => {
    setSelectedMilestone(milestone);
    setIsSlideOverOpen(true);
  };

  const toggleSubMilestonesVisibility = (milestoneId) => {
    setVisibleSubMilestones((prevVisibleSubMilestones) => ({
      ...prevVisibleSubMilestones,
      [milestoneId]: !prevVisibleSubMilestones[milestoneId],
    }));
  };

  const handleMarkMainMilestoneComplete = (milestoneId) => {
    const milestone = milestones.find((m) => m._id === milestoneId);
    if (!milestone) return;

    // Check if there are incomplete sub-milestones
    const incompleteSubMilestones = milestone.subMilestones.filter((subMilestone) => !subMilestone.status);

    if (incompleteSubMilestones.length > 0 && !mainMilestoneCompleteConfirmed) {
      // Show a warning message and ask for confirmation
      if (window.confirm('Not all sub-milestones are completed. Are you sure you want to mark the main milestone as complete?')) {
        // User confirmed, update the main milestone's status
        handleStatusChange(milestoneId);
      }
    } else {
      // No incomplete sub-milestones or user confirmed, update the main milestone's status
      handleStatusChange(milestoneId);
    }
  };

  const confirmSubMilestoneStatusChange = (mainMilestoneId, subMilestoneId, currentStatus) => {
    const action = currentStatus ? 'incomplete' : 'complete';
    const message = `Are you sure you want to mark this submilestone as ${action}?`;

    if (window.confirm(message)) {
      handleStatusChange(mainMilestoneId, subMilestoneId);
    }
  };

  const handleDeleteMainMilestone = (milestoneId) => {
    if (!mainMilestoneDeleteConfirmed) {
      // Show a warning message and ask for confirmation
      if (window.confirm('This action cannot be undone. Are you sure you want to delete the main milestone?')) {
        // User confirmed, proceed with deletion
        handleDeleteMilestone(milestoneId);
      }
    } else {
      // User has already confirmed, proceed with deletion
      handleDeleteMilestone(milestoneId);
    }
  };

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with '0' if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month and pad with '0' if needed (months are 0-based)
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/${year}`;
  }

  const countCompletedSubMilestones = (milestone) => {
    return milestone.subMilestones.reduce((count, subMilestone) => (subMilestone.status ? count + 1 : count), 0);
  };

  const isDeadlineOverdue = (deadline) => {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    return deadlineDate < today;
  };

  return (
    <div className="p-2 w-full sm:w-full md:w-1/2 lg:w-1/4 xl:w-1/4">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-4 bg-[#2A9D8F] text-white">
          <h2 className="text-xs font-semibold uppercase">{category}</h2>
        </div>
        <div className="p-2 ">
          {milestones.map((milestone) => (
            <div key={milestone._id} className="mb-4 border border-gray-200 rounded-lg p-4 hover:bg-gray-50 transition ease-in-out duration-150 relative">
              <button
                onClick={() => handleDeleteMainMilestone(milestone._id)}
                className="absolute top-2 right-2 inline-flex justify-center items-center w-4 h-4 text-xs font-medium text-gray-600 bg-gray-100 border border-transparent rounded-full hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                X
              </button>

              <div className="flex items-center">
                <h3 className="text-xs font-bold text-gray-900">{milestone.title}</h3>
              </div>

              <button onClick={() => handleMilestoneClick(milestone)} className="mt-2 text-[#2A9D8F] hover:text-[#287970] text-xs font-semibold">
                View More Details
              </button>

              <div className="flex items-center mt-2">
                <h4 className="text-xs font-semibold text-gray-900">Status:</h4>
                <input
                  id={`status-${milestone._id}`}
                  name={`status-${milestone._id}`}
                  type="checkbox"
                  checked={milestone.status}
                  onChange={() => handleMarkMainMilestoneComplete(milestone._id)}
                  className="ml-2 w-4 h-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                />
                <label htmlFor={`status-${milestone._id}`} className="block ml-2 text-xs text-gray-900">
                  {milestone.status ? 'Completed' : 'Incomplete'}
                </label>
              </div>

              {milestone?.status && (
                <div className="flex items-center mt-2">
                  <h4 className="text-xs font-semibold text-gray-900">Completed On:</h4>
                  <h3 className="ml-2 text-xs  text-gray-900">{formatDateFromTimestamp(milestone.completedOn)}</h3>
                </div>
              )}

              {milestone?.deadline && (
                <div className="flex items-center mt-2">
                  <h4 className="text-xs font-semibold text-gray-900">Deadline:</h4>
                  <h3 className={`ml-2 text-xs ${!milestone.status && isDeadlineOverdue(milestone.deadline) ? 'text-red-500' : 'text-gray-900'}`}>
                    {formatDateFromTimestamp(milestone.deadline)}
                  </h3>
                </div>
              )}

              {milestone.subMilestones && milestone.subMilestones.length > 0 && (
                <div className="mt-4 border-t border-gray-200 pt-4">
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleSubMilestonesVisibility(milestone._id)}
                      className="text-xs font-bold text-gray-900 flex items-center" // Add 'flex items-center' class
                    >
                      Sub-Milestones ({countCompletedSubMilestones(milestone)}/{milestone.subMilestones.length})
                      {visibleSubMilestones[milestone._id] ? <ChevronUpIcon className="w-5 h-5 ml-2" /> : <ChevronDownIcon className="w-5 h-5 ml-2" />}
                    </button>
                  </div>

                  <div
                    className={`mt-4 transition-all duration-500 ease-in-out ${
                      visibleSubMilestones[milestone._id] ? 'max-h-full min-h-[150px]' : 'max-h-0 overflow-hidden'
                    }`}
                  >
                    {milestone.subMilestones.map((subMilestone) => (
                      <div
                        key={subMilestone._id}
                        className="mb-2 mt-2 border border-gray-200 rounded-lg p-2 hover:bg-gray-50 transition ease-in-out duration-150 relative"
                      >
                        <h5 className="text-[10px] font-semibold text-gray-900">{subMilestone.title}</h5>
                        <div className="flex items-center mt-2">
                          <h6 className="text-[10px] font-semibold text-gray-900">Status:</h6>
                          <input
                            id={`status-${milestone._id}-${subMilestone._id}`}
                            name={`status-${milestone._id}-${subMilestone._id}`}
                            type="checkbox"
                            checked={subMilestone.status}
                            onChange={() => confirmSubMilestoneStatusChange(milestone._id, subMilestone._id, subMilestone.status)}
                            className="ml-2 w-4 h-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                          />
                          <label htmlFor={`status-${milestone._id}-${subMilestone._id}`} className="block ml-2 text-[10px] text-gray-900">
                            {subMilestone.status ? 'Completed' : 'Incomplete'}
                          </label>
                        </div>
                        {subMilestone?.status && (
                          <div className="flex items-center mt-2">
                            <h4 className="text-[10px] font-semibold text-gray-900">Completed On:</h4>
                            <h3 className="ml-2 text-[10px]  text-gray-900">{formatDateFromTimestamp(subMilestone.completedOn)}</h3>
                          </div>
                        )}
                        <button
                          onClick={() => handleDeleteSubMilestone(milestone._id, subMilestone._id)}
                          className="absolute top-2 right-2 inline-flex justify-center items-center w-4 h-4 text-xs font-medium text-gray-600 bg-gray-100 border border-transparent rounded-full hover:bg-gray-300 focus:outline-none"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <SlideOver
          open={isSlideOverOpen}
          setOpen={setIsSlideOverOpen}
          milestone={selectedMilestone}
          handleStatusChange={handleStatusChange}
          updateMilestone={updateMilestone}
        />
      </div>
    </div>
  );
};

const MilestoneList = ({ categories, milestones, handleStatusChange, handleDeleteMilestone, handleDeleteSubMilestone, formatDate, updateMilestone }) => {
  const uncategorizedMilestones = Array.isArray(milestones) ? milestones.filter((milestone) => !milestone.category || milestone.category === '') : [];

  return (
    <div className="w-full">
      <h2 className="text-gray-900 font-semibold">Project Milestones</h2>
      <h2 className="text-sm text-gray-600 pb-2">These are the milestones for this project.</h2>
      <div className="flex flex-wrap">
        {categories.map((category) => (
          <MilestoneFolder
            key={category}
            category={category}
            milestones={Array.isArray(milestones) ? milestones.filter((m) => m.category === category) : []}
            handleStatusChange={handleStatusChange}
            handleDeleteMilestone={handleDeleteMilestone}
            handleDeleteSubMilestone={handleDeleteSubMilestone}
            updateMilestone={updateMilestone}
          />
        ))}
      </div>

      {uncategorizedMilestones.length > 0 && (
        <div className="w-full p-2">
          <h3 className="text-lg font-semibold text-gray-900">Uncategorized Milestones</h3>
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-2">
              {uncategorizedMilestones.map((milestone) => (
                <MilestoneFolder
                  key={milestone._id}
                  category={''}
                  milestones={[milestone]}
                  handleStatusChange={handleStatusChange}
                  handleDeleteMilestone={handleDeleteMilestone}
                  handleDeleteSubMilestone={handleDeleteSubMilestone}
                  formatDate={formatDate}
                  updateMilestone={updateMilestone}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MilestoneList;
