import { useState, useEffect } from 'react';
import api from "../../utils/api";

export function useProjects(userContextData) {
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/getUserProjects`, {
        params: { auth0_id: userContextData.auth0_id }
      });
      setProjects(response.data.projects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [userContextData.auth0_id]);

  return { projects, fetchProjects };
}

export default useProjects;