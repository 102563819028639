import React, { useContext, useState, useEffect, useMemo } from 'react';
import { PaperClipIcon, ArrowUpIcon, ArrowDownIcon, AdjustmentsVerticalIcon, PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { TrashIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { UserContext } from '../../../App';
import SlideOver from './SlideOver';
import { updateDocument, deleteDocument } from '../../../services/documentService';

function ListDocuments({ projectId, documents, onDocumentDeleted, userRole }) {
  const [clientViewableStatus, setClientViewableStatus] = useState({});
  const [approvedByClientStatus, setApprovedByClientStatus] = useState({});
  const { userContextData } = useContext(UserContext);
  const [comment, setComment] = useState({});

  const [searchQuery, setSearchQuery] = useState('');
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: 'uploadedDate',
    direction: 'descending',
  });

  const [showDocumentManager, setShowDocumentManager] = useState(false);

  // Function to flatten the documents object into an array
  const flattenDocuments = useMemo(() => {
    return Object.values(documents).flatMap((category) => Object.values(category).flatMap((subcategory) => subcategory));
  }, [documents]);

  // Filter documents for clients based on visibility
  const visibleDocuments = useMemo(() => {
    return userRole === 'client' ? flattenDocuments.filter((doc) => doc.visibleToClient) : flattenDocuments;
  }, [flattenDocuments, userRole]);

  const sortedDocuments = useMemo(() => {
    let sortableItems = [...visibleDocuments];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [visibleDocuments, sortConfig]);

  const filteredAndSortedDocuments = useMemo(() => {
    return sortedDocuments.filter(
      (doc) =>
        doc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doc.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (doc.subCategory && doc.subCategory.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [sortedDocuments, searchQuery]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleButtonAction = (e, action, doc) => {
    e.stopPropagation();

    if (action === 'open') {
      openDocument(doc.signedUrl);
    }
    if (action === 'checkboxShowToClient') {
      handleClientViewableChange(doc._id, e.target.checked);
    }
    if (action === 'checkboxApprovedByClient') {
      handleApprovalChange(doc._id, e.target.checked);
    } else if (action === 'delete') {
      handleDelete(projectId, doc._id);
    }
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ArrowUpIcon className="w-4 h-4 inline-block" /> : <ArrowDownIcon className="w-4 h-4 inline-block" />;
    }

    return <AdjustmentsVerticalIcon className="w-4 h-4 inline-block" />;
  };

  const openSlideOver = (doc) => {
    setSelectedDocument(doc);
    setIsSlideOverOpen(true);
  };

  useEffect(() => {
    const newClientViewableStatus = flattenDocuments.reduce((acc, doc) => {
      acc[doc._id] = doc.visibleToClient || false;
      return acc;
    }, {});

    const newApprovedByClientStatus = flattenDocuments.reduce((acc, doc) => {
      acc[doc._id] = doc.approvedByClient || false;
      return acc;
    }, {});

    setClientViewableStatus(newClientViewableStatus);
    setApprovedByClientStatus(newApprovedByClientStatus);
  }, [flattenDocuments]);

  const openDocument = (signedUrl) => {
    window.open(signedUrl, '_blank');
  };

  const handleClientViewableChange = async (documentId, isChecked) => {
    setClientViewableStatus({
      ...clientViewableStatus,
      [documentId]: isChecked,
    });

    try {
      await updateDocument(projectId, documentId, { visibleToClient: isChecked });
    } catch (error) {
      console.error('Error updating document visibility:', error);
    }
  };

  const handleApprovalChange = async (documentId, isChecked) => {
    setApprovedByClientStatus({
      ...approvedByClientStatus,
      [documentId]: isChecked,
    });

    try {
      await updateDocument(projectId, documentId, { approvedByClient: isChecked });
    } catch (error) {
      console.error('Error updating document approval status:', error);
    }
  };

  function handleDelete(projectId, documentId) {
    const isConfirmed = window.confirm('Are you sure you want to delete the document? This action cannot be undone.');
    if (!isConfirmed) {
      return;
    }

    deleteDocument(projectId, documentId, userContextData)
      .then(() => {
        if (onDocumentDeleted) {
          onDocumentDeleted();
        }
      })
      .catch((error) => {
        console.error('There was an error deleting the document:', error);
        if (error.response && error.response.data) {
          alert('Error deleting document: ' + error.response.data.message);
        }
      });
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <div className="relative my-10">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <button
            type="button"
            onClick={() => setShowDocumentManager(!showDocumentManager)}
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            {showDocumentManager ? 'Hide Document Manager' : 'Show Document Manager'}
          </button>
        </div>
      </div>

      {showDocumentManager && (
        <div className="flow-root">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
            <div>
              <h1 className="text-lg font-semibold leading-6 text-gray-900">Document Manager</h1>
              <p className="mt-1 text-sm text-gray-700">
                A list of all documents uploaded for this project. You can search, sort, manage, and delete documents here.
              </p>
            </div>
            <div className="w-full sm:w-auto mt-4 sm:mt-0 relative">
              <input
                type="text"
                placeholder="Search Documents"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full sm:w-48 p-2 pr-8 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#2A9D8F]"
              />
              <MagnifyingGlassIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            </div>
          </div>
          <div className="-mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full overflow-hidden align-middle md:overflow-x-auto lg:px-10">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" onClick={() => handleSort('name')}>
                      Document Name {getSortIcon('name')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell"
                      onClick={() => handleSort('category')}
                    >
                      Category {getSortIcon('category')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                      onClick={() => handleSort('uploadedDate')}
                    >
                      Date {getSortIcon('uploadedDate')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Download
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                      Comments
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Approved By Client
                    </th>
                    {userRole === 'admin' && (
                      <>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Show To Client
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Delete
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {filteredAndSortedDocuments.map((doc) => (
                    <tr key={doc._id} className={`hover:bg-gray-100 ${doc.isImportant ? '' : ''}`}>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hover:cursor-pointer" onClick={() => openSlideOver(doc)}>
                        {doc.name}
                        <br />
                        {doc?.version ? <span className="text-[12px]">Version: {doc.version}</span> : <span className="text-[10px]"></span>}
                      </td>
                      <td
                        className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell hover:cursor-pointer"
                        onClick={() => openSlideOver(doc)}
                      >
                        {doc.category}
                      </td>
                      <td
                        className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell hover:cursor-pointer"
                        onClick={() => openSlideOver(doc)}
                      >
                        {new Date(doc.uploadedDate).toLocaleDateString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button onClick={(e) => handleButtonAction(e, 'open', doc)} className="text-[#2A9D8F] hover:text-[#264653]">
                          Open
                        </button>
                      </td>
                      <td
                        className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden lg:table-cell hover:cursor-pointer"
                        onClick={() => openSlideOver(doc)}
                      >
                        {doc.comments.length > 0 ? doc.comments.length + ' Comment(s)' : '-'}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="checkbox"
                          checked={approvedByClientStatus[doc._id]}
                          onChange={(e) => handleButtonAction(e, 'checkboxApprovedByClient', doc)}
                          className="h-4 w-4 rounded border-gray-300 focus:ring-[#2A9D8F]"
                        />
                        <label className="ml-2">Approved</label>
                      </td>
                      {userRole === 'admin' && (
                        <>
                          <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                            <input
                              type="checkbox"
                              checked={clientViewableStatus[doc._id]}
                              onChange={(e) => handleButtonAction(e, 'checkboxShowToClient', doc)}
                              className="h-4 w-4 rounded border-gray-300 focus:ring-[#2A9D8F]"
                            />
                            <label className="ml-2">Yes</label>
                          </td>
                          <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                            <button onClick={(e) => handleButtonAction(e, 'delete', doc)} className="text-red-500 hover:bg-red-100 p-2 rounded-full">
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <SlideOver
        open={isSlideOverOpen}
        setOpen={setIsSlideOverOpen}
        data={selectedDocument}
        projectId={projectId}
        userContextData={userContextData}
        setComment={setComment}
        userRole={userRole}
      />
    </>
  );
}

export default ListDocuments;
