import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../App';
import ChangeRequestForm from '../../Projects/ChangeRequests/Form'; // Adjust the import path as needed
import ChangeRequestTable from '../../Projects/ChangeRequests/Table';
import useGetProjectChangeRequests from '../../Hooks/useGetProjectChangeRequests';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import { PlusIcon } from '@heroicons/react/20/solid';

const ProjectChangeRequestPage = () => {
  const { projectId } = useParams();
  const { userContextData } = useContext(UserContext);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const { changeRequests, loading, error, refetch } = useGetProjectChangeRequests(projectId);

  const { documents, loading: documentsLoading, error: documentsError, refetch: documentsRefetch } = useGetProjectDocuments(projectId);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-8">
      <ChangeRequestTable changeRequests={changeRequests} refetch={refetch} />

      <div className="relative my-10">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <button
            type="button"
            onClick={() => setShowUploadForm(!showUploadForm)}
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            {showUploadForm ? 'Hide Upload Form' : 'Upload New Change Request(s)'}
          </button>
        </div>
      </div>

      {showUploadForm && <ChangeRequestForm projectId={projectId} userEmail={userContextData.email} refetch={refetch} documents={documents} />}
    </div>
  );
};

export default ProjectChangeRequestPage;
