import React, { useState, useContext } from 'react';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import UploadDocuments from './UploadDocuments';
import ListDocuments from './ListDocuments';
import DocumentFolders from './DocumentFolders';
import useGetUserRole from '../../Hooks/useGetUserRole';
import { PlusIcon } from '@heroicons/react/20/solid';

import { UserContext } from '../../../App';

function Attachments({ projectId }) {
  const [showUploadForm, setShowUploadForm] = useState(false);
  const { userContextData } = useContext(UserContext);
  const { documents, loading, error, refetch } = useGetProjectDocuments(projectId);

  const { role: userRole, loading: userRoleLoading, error: userRoleError } = useGetUserRole(projectId, userContextData.user_id);

  if (loading || userRoleLoading) return <div>Loading...</div>;
  if (error || userRoleError) return <div>Error: {error.message}</div>;

  return (
    <div className="space-y-6">
      <DocumentFolders documents={documents} />

      <div className="relative my-10">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <button
            type="button"
            onClick={() => setShowUploadForm(!showUploadForm)}
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            {showUploadForm ? 'Hide Upload Form' : 'Upload Other Document(s)'}
          </button>
        </div>
      </div>

      {showUploadForm && <UploadDocuments show={true} projectId={projectId} userRole={userRole} />}

      <ListDocuments projectId={projectId} documents={documents} onDocumentDeleted={refetch} userRole={userRole} />
    </div>
  );
}

export default Attachments;
