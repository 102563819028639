import React from 'react';

const SubmitButton = ({ showSuccessMessage, text }) => {
  if (showSuccessMessage) return null;

  return (
    <button
      type="submit"
      className="rounded-md bg-[#2A9D8F] hover:bg-[#264653] px-3 py-2 text-sm font-semibold text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#264653]"
    >
      {text}
    </button>
  );
};

export default SubmitButton;
