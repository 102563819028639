import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import LogoutButton from '../Auth/LogoutButton';
import { Bars3Icon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { UserContext } from '../../App';
import { useParams, useNavigate } from 'react-router-dom';
import useGetProject from '../Hooks/useGetProject';
import useGetUserRole from '../Hooks/useGetUserRole';

const Header = ({ setSidebarOpen, userNavigation, projectId, projects }) => {
  const { userContextData } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  let projectIdIsValid = projectId && projectId.match(/^[0-9a-fA-F]{24}$/);
  if (!projectIdIsValid) {
    projectId = null;
  }

  const { project, isLoading, isError, refetch } = useGetProject(projectId);
  const { role, loading, error } = useGetUserRole(projectId, userContextData.user_id);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filtered = projects.filter(
        (project) =>
          project.jobReference.toLowerCase().includes(searchQuery.toLowerCase()) ||
          project.clients.some((client) => client.name.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilteredProjects(filtered.slice(0, 5)); // Limit to 5 results
    } else {
      setFilteredProjects([]);
    }
  }, [searchQuery, projects]);

  const handleProjectSelect = (projectId) => {
    navigate(`/dashboard/project/summary/${projectId}`);
    setSearchQuery('');
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 items-center justify-between">
        <div className="flex-1">
          <h1 className="text-sm font-medium text-gray-900">
            {projectId ? `Project: ${project?.jobReference}` : ''}
            {role && role === 'admin' ? (
              <span className="text-[#2A9D8F] font-semibold ml-2">Admin View</span>
            ) : (
              <span className="text-[#2A9D8F] font-semibold ml-2">Client View</span>
            )}
          </h1>
        </div>

        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {projects && projects.length > 0 && (
            <div className="relative w-64">
              <MagnifyingGlassIcon className="pointer-events-none absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" aria-hidden="true" />
              <input
                type="text"
                placeholder="Search projects..."
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {filteredProjects.length > 0 && (
                <div className="absolute left-0 z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                  {filteredProjects.map((project) => (
                    <div key={project._id} className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleProjectSelect(project._id)}>
                      <div className="font-medium">{project.jobReference}</div>
                      <div className="text-sm text-gray-500">{project.clients.map((client) => client.name).join(', ')}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img className="h-8 w-8 rounded-full bg-gray-50" src={userContextData.picture} alt="" />
              <span className="hidden lg:flex lg:items-center">
                <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  {userContextData.name}
                </span>
                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <>
                        {item.name === 'Sign out' ? (
                          <div className={classNames(active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}>
                            <LogoutButton />
                          </div>
                        ) : (
                          <a href={item.href} className={classNames(active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}>
                            {item.name}
                          </a>
                        )}
                      </>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
