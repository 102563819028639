import React, { useState, useContext } from 'react';
import axios from 'axios';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import { UserContext } from '../../../App';
import api from '../../../utils/api';

function ManageDocuments({ projectId }) {
  const { documents, loading, error } = useGetProjectDocuments(projectId);
  const [selectedFile, setSelectedFile] = useState(null);
  const [docName, setDocName] = useState('');
  const [docCategory, setDocCategory] = useState('Property'); // Default category
  const [uploading, setUploading] = useState(false); // Added for loader

  const { userContextData } = useContext(UserContext);

  const documentCategories = ['Property', 'Reports', 'Specifications', 'Certificates', 'Contracts', 'Other'];

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('file', selectedFile);
    formData.append('name', docName);
    formData.append('category', docCategory);

    setUploading(true); // Start the loader

    try {
      await api.post(`${process.env.REACT_APP_SERVER_URL}/api/projects/project/${projectId}/uploadDocument`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.location.reload();
    } catch (err) {
      console.error('Error uploading the file', err);
    } finally {
      setUploading(false); // Stop the loader, whether success or error
    }
  };

  const groupByCategory = (docs) => {
    return docs.reduce((acc, doc) => {
      if (!acc[doc.category]) {
        acc[doc.category] = [];
      }
      acc[doc.category].push(doc);
      return acc;
    }, {});
  };

  const documentsGrouped = groupByCategory(documents);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="mt-6 border-t border-gray-100">
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Upload a Document</h3>
        <form onSubmit={handleSubmit}>
          <label className="block text-sm font-medium leading-6 text-gray-900">Document Name</label>
          <input type="text" className="mt-1 border rounded-md px-2 py-1" />
          <div>
            <label>Category:</label>
            <select className="mt-1 border rounded-md px-2 py-1" value={docCategory} onChange={(e) => setDocCategory(e.target.value)} required>
              {documentCategories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>File:</label>
            <input type="file" name="file" onChange={handleFileChange} required />
          </div>
          {uploading && <div>Uploading. Please do not close this page</div>} {/* Loader */}
          <div>
            <button type="submit">Upload</button>
          </div>
        </form>
      </div>

      {Object.entries(documentsGrouped).map(([category, docs]) => (
        <div key={category}>
          <h2>{category}</h2>
          <ul>
            {docs.map((doc) => (
              <li key={doc._id}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">
                  {doc.name}
                </a>
                <span> Uploaded by: {doc.uploadedBy}</span>
                <span> Date: {new Date(doc.uploadedDate).toLocaleDateString()}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default ManageDocuments;
