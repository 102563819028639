import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PlusIcon, PencilIcon, TrashIcon, CheckIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { updateChangeRequest, addActionItem, updateActionItem, removeActionItem, deleteChangeRequest } from '../../../services/changeRequestService';
import { toast } from 'react-hot-toast';
import Notes from './Notes';

export default function SlideOver({ open, setOpen, data, refetch, onClose, onUpdate }) {
  const [changeRequest, setChangeRequest] = useState(data);
  const [editingField, setEditingField] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [newActionItem, setNewActionItem] = useState({ description: '', status: 'Not Started' });
  const [editingActionItem, setEditingActionItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setChangeRequest(data);
    setActionItems(data?.actionItems || []);
  }, [data]);

  const handleInputChange = (field, value) => {
    setChangeRequest((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveField = async (field) => {
    setIsLoading(true);
    try {
      const updatedChangeRequest = await updateChangeRequest(changeRequest._id, { [field]: changeRequest[field] });
      setEditingField(null);
      refetch();
      onUpdate(updatedChangeRequest); // Add this line
      toast.success(`${field} updated successfully`);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      toast.error(`Failed to update ${field}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteChangeRequest = async () => {
    if (window.confirm('Are you sure you want to delete this change request?')) {
      setIsLoading(true);
      try {
        await deleteChangeRequest(changeRequest._id);
        setOpen(false);
        refetch();
        toast.success('Change request deleted successfully');
      } catch (error) {
        console.error('Error deleting change request:', error);
        toast.error('Failed to delete change request');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddActionItem = async () => {
    if (!newActionItem.description) return;
    setIsLoading(true);
    try {
      const updatedChangeRequest = await addActionItem(changeRequest._id, newActionItem);
      setActionItems(updatedChangeRequest.actionItems);
      onUpdate(updatedChangeRequest);
      setNewActionItem({ description: '', status: 'Not Started' });
      toast.success('Action item added successfully');
    } catch (error) {
      console.error('Error adding action item:', error);
      toast.error('Failed to add action item');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateActionItem = async () => {
    if (!editingActionItem) return;
    setIsLoading(true);
    try {
      const updatedChangeRequest = await updateActionItem(changeRequest._id, editingActionItem._id, editingActionItem);
      setActionItems(updatedChangeRequest.actionItems);
      onUpdate(updatedChangeRequest);
      setEditingActionItem(null);
      toast.success('Action item updated successfully');
    } catch (error) {
      console.error('Error updating action item:', error);
      toast.error('Failed to update action item');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveActionItem = async (itemId) => {
    if (window.confirm('Are you sure you want to remove this action item?')) {
      setIsLoading(true);
      try {
        const updatedChangeRequest = await removeActionItem(changeRequest._id, itemId);
        setActionItems(updatedChangeRequest.actionItems);
        onUpdate(updatedChangeRequest);
        toast.success('Action item removed successfully');
      } catch (error) {
        console.error('Error removing action item:', error);
        toast.error('Failed to remove action item');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCompleteChangeRequest = async () => {
    setIsLoading(true);
    try {
      const updatedChangeRequest = await updateChangeRequest(changeRequest._id, { status: 'Completed' });
      setChangeRequest(updatedChangeRequest);
      onUpdate(updatedChangeRequest);
      toast.success('Change request marked as completed');
    } catch (error) {
      console.error('Error completing change request:', error);
      toast.error('Failed to complete change request');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteActionItem = async (itemId, completed) => {
    setIsLoading(true);
    try {
      const item = actionItems.find((item) => item._id === itemId);
      if (!item) {
        throw new Error('Action item not found');
      }
      const updatedItem = {
        ...item,
        status: completed ? 'Completed' : 'In Progress',
      };
      const updatedChangeRequest = await updateActionItem(changeRequest._id, itemId, updatedItem);
      setActionItems(updatedChangeRequest.actionItems);
      onUpdate(updatedChangeRequest);
      toast.success(`Action item ${completed ? 'completed' : 'reopened'}`);
    } catch (error) {
      console.error('Error updating action item:', error);
      toast.error('Failed to update action item');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Change Request Details</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2"
                            onClick={() => {
                              setOpen(false);
                              onClose();
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {changeRequest && (
                        <div className="space-y-6">
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                              <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500">Title</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {editingField === 'title' ? (
                                      <div className="flex items-center">
                                        <input
                                          type="text"
                                          value={changeRequest.title}
                                          onChange={(e) => handleInputChange('title', e.target.value)}
                                          className="flex-grow mr-2 border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                        />
                                        <button
                                          onClick={() => handleSaveField('title')}
                                          disabled={isLoading}
                                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                        >
                                          <CheckIcon className="h-4 w-4 mr-1" />
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="flex items-center">
                                        <span className="flex-grow">{changeRequest.title}</span>
                                        <button onClick={() => setEditingField('title')} className="ml-2 text-[#2A9D8F] hover:text-[#2A9D8F]">
                                          <PencilIcon className="h-5 w-5" />
                                        </button>
                                      </div>
                                    )}
                                  </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500">Description</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {editingField === 'description' ? (
                                      <div className="flex items-start">
                                        <textarea
                                          value={changeRequest.description}
                                          onChange={(e) => handleInputChange('description', e.target.value)}
                                          className="flex-grow mr-2 border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                          rows={3}
                                        />
                                        <button
                                          onClick={() => handleSaveField('description')}
                                          disabled={isLoading}
                                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                        >
                                          <CheckIcon className="h-4 w-4 mr-1" />
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="flex items-start">
                                        <span className="flex-grow">{changeRequest.description}</span>
                                        <button onClick={() => setEditingField('description')} className="ml-2 text-[#2A9D8F] hover:text-[#2A9D8F]">
                                          <PencilIcon className="h-5 w-5" />
                                        </button>
                                      </div>
                                    )}
                                  </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                      <span className="flex-grow">{changeRequest.status}</span>
                                      {changeRequest.status !== 'Completed' && (
                                        <button
                                          onClick={handleCompleteChangeRequest}
                                          disabled={isLoading}
                                          className="ml-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        >
                                          <CheckCircleIcon className="h-4 w-4 mr-1" />
                                          Mark Complete
                                        </button>
                                      )}
                                    </div>
                                  </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500">Uploaded By</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{changeRequest.uploadedBy}</dd>
                                </div>
                              </dl>
                            </div>
                          </div>

                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Action Items</h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <div className="mb-4">
                                <div className="flex items-center space-x-2">
                                  <input
                                    type="text"
                                    value={newActionItem.description}
                                    onChange={(e) => setNewActionItem({ ...newActionItem, description: e.target.value })}
                                    placeholder="New action item"
                                    className="flex-grow border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                  />
                                  <select
                                    value={newActionItem.status}
                                    onChange={(e) => setNewActionItem({ ...newActionItem, status: e.target.value })}
                                    className="border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                  >
                                    <option value="Not Started">Not Started</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Completed">Completed</option>
                                  </select>
                                  <button
                                    onClick={handleAddActionItem}
                                    disabled={isLoading || !newActionItem.description}
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] disabled:opacity-50"
                                  >
                                    <PlusIcon className="h-5 w-5 mr-1" />
                                    Add
                                  </button>
                                </div>
                              </div>
                              <ul className="divide-y divide-gray-200">
                                {actionItems.map((item) => (
                                  <li key={item._id} className="py-4">
                                    {editingActionItem && editingActionItem._id === item._id ? (
                                      <div className="flex items-center space-x-2">
                                        <input
                                          type="text"
                                          value={editingActionItem.description}
                                          onChange={(e) => setEditingActionItem({ ...editingActionItem, description: e.target.value })}
                                          className="flex-grow border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                        />
                                        <select
                                          value={editingActionItem.status}
                                          onChange={(e) => setEditingActionItem({ ...editingActionItem, status: e.target.value })}
                                          className="border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                                        >
                                          <option value="Not Started">Not Started</option>
                                          <option value="In Progress">In Progress</option>
                                          <option value="Completed">Completed</option>
                                        </select>
                                        <button onClick={handleUpdateActionItem} disabled={isLoading} className="text-[#2A9D8F] hover:text-[#2A9D8F]">
                                          <CheckIcon className="h-5 w-5" />
                                        </button>
                                        <button onClick={() => setEditingActionItem(null)} className="text-gray-600 hover:text-gray-900">
                                          <XMarkIcon className="h-5 w-5" />
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-3">
                                          <button
                                            onClick={() => handleCompleteActionItem(item._id, item.status !== 'Completed')}
                                            className={`flex-shrink-0 h-5 w-5 rounded-full border-2 ${
                                              item.status === 'Completed' ? 'bg-green-600 border-green-600' : 'border-gray-300 hover:border-green-500'
                                            }`}
                                          >
                                            {item.status === 'Completed' && <CheckIcon className="h-4 w-4 text-white" />}
                                          </button>
                                          <span className={`flex-grow ${item.status === 'Completed' ? 'line-through text-gray-500' : ''}`}>
                                            {item.description}
                                          </span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                          <button onClick={() => setEditingActionItem(item)} className="text-[#2A9D8F] hover:text-[#2A9D8F]">
                                            <PencilIcon className="h-5 w-5" />
                                          </button>
                                          <button onClick={() => handleRemoveActionItem(item._id)} className="text-red-600 hover:text-red-900">
                                            <TrashIcon className="h-5 w-5" />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          {changeRequest && <Notes changeRequestId={changeRequest._id} />}

                          <div className="mt-6 flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2"
                              onClick={() => {
                                setOpen(false);
                                onClose();
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <button
                              onClick={handleDeleteChangeRequest}
                              disabled={isLoading}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              Delete Change Request
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
