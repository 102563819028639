
import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;


export const imageService = {
  getImages: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/images/project/${projectId}/images`);
      return response.data;
    } catch (error) {
      console.error('Error fetching images:', error.message);
      throw error;
    }
  },

  uploadImage: async (projectId, formData) => {
    try {
      const response = await api.post(`${API_URL}/api/images/images/${projectId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error.message);
      throw error;
    }
  },

  deleteImage: async (imageId) => {
    try {
      await api.delete(`${API_URL}/api/images/images/${imageId}`);
    } catch (error) {
      console.error('Error deleting image:', error.message);
      throw error;
    }
  },

  updateImageCategory: async (projectId, imageId, newCategory) => {
    try {
      await api.put(`${API_URL}/api/images/images/${projectId}/${imageId}/category-order`, { category: newCategory });
    } catch (error) {
      console.error('Error updating image category:', error.message);
      throw error;
    }
  },
};

export default imageService;

export const { getImages, uploadImage, deleteImage, updateImageCategory } = imageService;