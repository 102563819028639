import { useState, useEffect, useCallback } from "react";
import { getMilestones } from "../../services/milestoneService";

function useGetProjectMilestones(projectId) {
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMilestones = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getMilestones(projectId);
      setMilestones(data || []);
      setError(null);
    } catch (err) {
      setError("Failed to fetch milestones");
      setMilestones([]);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchMilestones();
  }, [fetchMilestones]);

  return { milestones, loading, error, refetch: fetchMilestones };
}

export default useGetProjectMilestones;