import React, { useState } from 'react';
import SectionHeading from '../../Projects/Details/SectionHeading';
import EditProjectForm from '../../Projects/EditProject/Form';
import { useParams } from 'react-router-dom';
import ProjectDetails from '../../Projects/Details/ProjectDetails';
import useGetProject from '../../Hooks/useGetProject';
import { Spinner } from 'flowbite-react';

const ProjectSeeDetailsPage = ({ fetchProjects, fetchSharedProjects }) => {
  const { projectId } = useParams();
  const { project, loading, error } = useGetProject(projectId);

  if (loading) {
    return (
      <div>
        {' '}
        <Spinner aria-label="Info spinner example" color="info" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!project) {
    return <div>Project not found!</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        <SectionHeading title={`Project ${project.jobReference}`} project={project} fetchProjects={fetchProjects} />
        <ProjectDetails project={project} />
      </div>
    </div>
  );
};

export default ProjectSeeDetailsPage;
