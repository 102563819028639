import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../App';
import api from '../../../utils/api';

const Notes = ({ changeRequestId }) => {
  const [changeRequestNotes, setChangeRequestNotes] = useState([]);
  const [newNoteContent, setNewNoteContent] = useState('');
  const { userContextData } = useContext(UserContext);

  const selectedProjectId = userContextData.selected_project;

  useEffect(() => {
    fetchNotes();
  }, [changeRequestId]);

  const fetchNotes = async () => {
    if (changeRequestId) {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/notes/changeRequest/${changeRequestId}/notes`;
      try {
        const response = await api.get(url);
        setChangeRequestNotes(response.data);
      } catch (error) {
        console.error('Error fetching change request notes:', error);
      }
    }
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNoteContent.trim()) return;

    try {
      await api.post(`${process.env.REACT_APP_SERVER_URL}/api/notes/notes`, {
        content: newNoteContent,
        changeRequestId,
        authorId: userContextData.user_id,
        projectId: selectedProjectId,
      });

      setNewNoteContent('');
      fetchNotes(); // Refresh the notes list
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const deleteNote = async (noteId) => {
    try {
      await api.delete(`${process.env.REACT_APP_SERVER_URL}/api/notes/notes/${noteId}`);
      setChangeRequestNotes((prevNotes) => prevNotes.filter((note) => note._id !== noteId));
    } catch (error) {
      console.error('Failed to delete note:', error.message);
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <span className="text-sm font-medium text-gray-500">Notes:</span>

      {/* Notes List */}
      {changeRequestNotes.map((note, index) => (
        <div key={index} className="mt-2 p-2 border rounded-lg">
          <span className="text-sm text-gray-900">{note.content}</span>
          <div className="text-xs text-gray-600">
            Uploaded by: {note.authorEmail} on {formatTimestamp(note.timestamp)}
          </div>
          <button onClick={() => deleteNote(note._id)} className="mt-2 text-red-500 text-xs  rounded">
            Delete Note
          </button>
        </div>
      ))}

      {/* Add Note Form */}
      <form onSubmit={handleAddNote} className="space-y-4">
        <textarea
          value={newNoteContent}
          onChange={(e) => setNewNoteContent(e.target.value)}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          placeholder="Write your note here..."
          rows={3}
          required
        />
        <button
          type="submit"
          className="bg-[#2A9D8F] text-white py-2 px-4 rounded-md hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] transition duration-150 ease-in-out"
        >
          Add Note
        </button>
      </form>
    </div>
  );
};

export default Notes;
