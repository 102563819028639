import React, { useState } from 'react';
import api from '../../utils/api';

export default function Profile({ organisation, setOrganisation, isOwner, userContextData }) {
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrganisation((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/organisations/${userContextData.organisation}`, {
        name: organisation.name,
        address: organisation.address,
        email: organisation.email,
        number: organisation.number,
        website: organisation.website,
        companyNumber: organisation.companyNumber,
        vatNumber: organisation.vatNumber,
      });
      setOrganisation(response.data);
      setSaveSuccess(true);
      setIsEditing(false);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating organisation details:', error);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Organisation Profile</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details of your organization.</p>
      </div>
      <div className="border-t border-gray-200">
        {isEditing ? (
          <form onSubmit={handleSubmit} className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="col-span-1 sm:col-span-2">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Organisation Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={organisation.name}
                  onChange={handleChange}
                  className="mt-1 focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {[
                { label: 'Address', name: 'address' },
                { label: 'Phone Number', name: 'number' },
                { label: 'Website', name: 'website' },
                { label: 'Company Number', name: 'companyNumber' },
                { label: 'VAT Number', name: 'vatNumber' },
              ].map((field) => (
                <div key={field.name} className="col-span-1">
                  <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                    {field.label}
                  </label>
                  <input
                    type="text"
                    name={field.name}
                    id={field.name}
                    value={organisation[field.name]}
                    onChange={handleChange}
                    className="mt-1 focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              ))}
            </div>
            <div className="mt-5 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="w-full sm:w-auto bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <dl className="divide-y divide-gray-200">
            {[
              { label: 'Organisation name', value: organisation.name },
              { label: 'Address', value: organisation.address },
              { label: 'Email address', value: organisation.email },
              { label: 'Phone number', value: organisation.number },
              { label: 'Website', value: organisation.website },
              { label: 'Company number', value: organisation.companyNumber },
              { label: 'VAT number', value: organisation.vatNumber },
            ].map((item) => (
              <div key={item.label} className="px-4 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.value || 'Not provided'}</dd>
              </div>
            ))}
          </dl>
        )}
      </div>
      {!isEditing && isOwner && (
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => setIsEditing(true)}
            className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
          >
            Edit Organisation Profile
          </button>
        </div>
      )}
      {saveSuccess && <div className="mt-4 p-4 bg-green-100 text-green-700 rounded-md">Organisation profile saved successfully!</div>}
    </div>
  );
}
