import React from "react";
import { Link } from "react-router-dom";

import { InformationCircleIcon } from "@heroicons/react/20/solid";

const EditButton = ({ projectId, additionalInfoRequired }) => {
  return (
    <div className="">
      <Link to={`/dashboard/edit-project/${projectId}`}>
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <InformationCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          {additionalInfoRequired ? "Add Additional Info" : "Edit Project"}
        </button>
      </Link>
    </div>
  );
};

export default EditButton;
