import React, { useEffect, useState } from 'react';

const UploadForm = ({
  contactFields,
  handleInputChange,
  handleSubmit,
  editMode,
  organisationContacts,
  handleExistingContactSelect,
}) => {
  const [selectedType, setSelectedType] = useState(contactFields.contactType);
  const [showOtherType, setShowOtherType] = useState(false);

  useEffect(() => {
    setSelectedType(contactFields.contactType);
  }, [contactFields.contactType]);

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSelectedType(type);
    handleInputChange({ target: { name: 'contactType', value: type } });

    if (type === 'other') {
      handleInputChange({ target: { name: 'contactType', value: '' } });
      setShowOtherType(true);
    } else {
      setShowOtherType(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {!editMode && (
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="existingContact"
            className="text-sm font-medium text-gray-900"
          >
            Select Existing Contact
          </label>
          <select
            id="existingContact"
            name="existingContact"
            onChange={handleExistingContactSelect}
            className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          >
            <option value="">-- Select a contact --</option>
            {organisationContacts.map((contact) => (
              <option key={contact._id} value={contact._id}>
                {contact.name} ({contact.email})
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex flex-col space-y-2">
        <label
          htmlFor="contactType"
          className="text-sm font-medium text-gray-900"
        >
          Contact Type *
        </label>
        <select
          name="contactType"
          id="contactType"
          value={contactFields.contactType}
          onChange={handleTypeChange}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          required
        >
          <option value="">--- Select Contact Type ---</option>
          <optgroup label="Client" className="font-bold">
            <option value="Client">Client</option>
          </optgroup>
          <optgroup label="Supporting Services Details" className="font-bold">
            <option value="Engineer">Engineer </option>
            <option value="Building Control">Building Control</option>
            <option value="Party Wall">Party Wall </option>
            <option value="Arboriculturist">Arboriculturist</option>
          </optgroup>
          <optgroup label="Contractor Details" className="font-bold">
            <option value="Main Contractor">Main Contractor</option>
            <option value="Secondary Contractor">Secondary Contractor</option>
          </optgroup>
          <optgroup label="Supplier Details" className="font-bold">
            <option value="Supplier">Supplier</option>
          </optgroup>
          <option value="other">Other</option>
        </select>
      </div>

      {showOtherType && (
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="otherDetails"
            className="text-sm font-medium text-gray-900"
          >
            Specify Type *
          </label>
          <input
            type="text"
            name="contactType"
            id="contactType"
            value={contactFields.contactType}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
            placeholder="Specify contact type"
          />
        </div>
      )}

      <div className="flex flex-col space-y-2">
        <label htmlFor="name" className="text-sm font-medium text-gray-900">
          Name *
        </label>
        <input
          type="text"
          name="name"
          id="name"
          value={contactFields.name}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          placeholder="Contact Name"
          required
        />
      </div>

      <div className="flex flex-col space-y-2">
        <label htmlFor="email" className="text-sm font-medium text-gray-900">
          Email
        </label>
        <input
          type="text"
          name="email"
          id="email"
          value={contactFields.email}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          placeholder="Contact Email"
        />
      </div>

      <div className="flex flex-col space-y-2">
        <label htmlFor="phone" className="text-sm font-medium text-gray-900">
          Phone
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          value={contactFields.phone}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          placeholder="Contact Phone Number"
        />
      </div>

      <div className="flex flex-col space-y-2">
        <label htmlFor="link" className="text-sm font-medium text-gray-900">
          Link
        </label>
        <input
          type="text"
          name="link"
          id="link"
          value={contactFields.link}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md focus:border-[#2A9D8F] focus:ring focus:ring-[#2A9D8F] transition-shadow duration-150 ease-in-out"
          placeholder="Contact Link"
        />
      </div>

      <button
        type="submit"
        className="w-full sm:w-auto bg-[#2A9D8F] text-white py-2 px-4 rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] transition duration-150 ease-in-out"
      >
        {editMode ? 'Update Contact' : 'Add Contact'}
      </button>
    </form>
  );
};

export default UploadForm;
