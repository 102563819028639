import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import api from '../../utils/api';

export default function SelectOrganisationTypePage() {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [organisationType, setOrganisationType] = useState('');
  const [organisationName, setOrganisationName] = useState('');
  const [organisationEmail, setOrganisationEmail] = useState('');
  const navigate = useNavigate();

  const handleOrganisationTypeSelect = (type) => {
    setOrganisationType(type);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (organisationType === 'Firm') {
        const response = await api.post(`${process.env.REACT_APP_SERVER_URL}/api/organisations`, {
          name: organisationName,
          email: organisationEmail || userContextData.email,
          creatorId: userContextData.auth0_id,
        });

        setUserContextData((prevData) => ({
          ...prevData,
          type: response.data.user.type,
          role: response.data.user.role,
          organisation: response.data.user.organisation,
        }));
      } else {
        const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${userContextData.auth0_id}`, {
          type: 'Client',
        });

        setUserContextData((prevData) => ({
          ...prevData,
          type: response.data.type,
        }));
      }

      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating organisation type:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Firm or Client?</h2>
        <div className="flex space-x-6 mb-6">
          <button
            type="button"
            onClick={() => handleOrganisationTypeSelect('Firm')}
            className={`flex-1 relative block w-full rounded-lg border-2 ${
              organisationType === 'Firm' ? 'border-[#2A9D8F]' : 'border-gray-300'
            } p-12 text-center hover:border-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2 transition-all duration-200`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-16 w-16 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
              />
            </svg>
            <span className="mt-4 block text-xl font-semibold text-gray-900">Architectural Firm</span>
            <span className="block text-sm text-gray-500">Create and manage projects.</span>
          </button>
          <button
            type="button"
            onClick={() => handleOrganisationTypeSelect('Client')}
            className={`flex-1 relative block w-full rounded-lg border-2 ${
              organisationType === 'Client' ? 'border-[#2A9D8F]' : 'border-gray-300'
            } p-12 text-center hover:border-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:ring-offset-2 transition-all duration-200`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-16 w-16 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            <span className="mt-4 block text-xl font-semibold text-gray-900">Client</span>
            <span className="block text-sm text-gray-500">I was invited to a project.</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {organisationType === 'Firm' && (
            <>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="organisationName">
                  Organisation Name
                </label>
                <input
                  type="text"
                  id="organisationName"
                  value={organisationName}
                  onChange={(e) => setOrganisationName(e.target.value)}
                  className="shadow appearance-none border border-[#2A9D8F] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="organisationEmail">
                  Organisation Email
                </label>
                <input
                  type="email"
                  id="organisationEmail"
                  value={organisationEmail}
                  onChange={(e) => setOrganisationEmail(e.target.value)}
                  placeholder={userContextData.email}
                  className="shadow appearance-none border border-[#2A9D8F] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </>
          )}
          <div className="flex space-x-4">
            <button
              type="submit"
              className="flex-1 bg-[#2A9D8F] hover:bg-[#2A9D8F] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200"
            >
              Continue
            </button>
          </div>
        </form>
        <div className="mt-6 text-center text-gray-600 border rounded py-2">
          <LogoutButton className="flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200" />
        </div>
      </div>
    </div>
  );
}
