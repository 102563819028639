import React from 'react';
import { categories } from '../../Pages/ProjectPages/ProjectMilestonesPage';

export const MilestoneForm = ({
  handleAddMilestone,
  newMilestoneStage,
  setNewMilestoneStage,
  newMilestoneTitle,
  setNewMilestoneTitle,
  newMilestoneCategory,
  setNewMilestoneCategory,
  newMilestoneDeadline, // State for managing the deadline
  setNewMilestoneDeadline, // Function to update the deadline
}) => {
  return (
    <div>
      <h2 className="font-medium text-gray-900">Add Key Milestone</h2>
      <p className="mt-1 text-sm text-gray-600">Enter details for the new milestone</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddMilestone();
        }}
      >
        <div className="grid grid-cols-1 gap-y-4 mt-5">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title *</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={newMilestoneTitle}
              onChange={(e) => setNewMilestoneTitle(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Category *</label>
            <select
              value={newMilestoneCategory}
              onChange={(e) => setNewMilestoneCategory(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="">Select a Category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          {/* <div>
            <label className="block text-sm font-medium text-gray-700">
              Stage
            </label>
            <select
              value={newMilestoneStage}
              onChange={(e) => setNewMilestoneStage(e.target.value)}
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select a Stage</option>
              {stages.map((stage, index) => (
                <option key={index} value={stage}>
                  {stage}
                </option>
              ))}
            </select>
          </div> */}
          {/* Deadline input field */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Deadline *</label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={newMilestoneDeadline}
              onChange={(e) => setNewMilestoneDeadline(e.target.value)}
              required
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 bg-[#2A9D8F] text-white font-medium rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-[#264653] focus:ring-offset-2 transition duration-300"
            >
              Add Milestone
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MilestoneForm;
