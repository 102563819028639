import React, { useEffect, useState, useContext } from 'react';
import SectionHeading from '../../UI/SectionHeading';
import EditProjectForm from '../EditProject/Form';
import { useParams } from 'react-router-dom';
import ClientInformation from './ClientInformation';
import ProjectInformation from './ProjectInformation';
import InternalInformation from './InternalInformation';
import PeopleInformation from './PeopleInformation';
import StylesAndServicesInformation from './StylesAndServicesInformation';
import InvitationLinks from './InvitationLinks';
import { Attachments } from '../Attachments';
import { SubmitButton } from '../CreateProject'; // Adjusted to import from index.js
import { UserContext } from '../../../App';
import { Link } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/24/outline';
import { ListBulletIcon } from '@heroicons/react/20/solid';
import SecondaryButton from '../../UI/SecondaryButton';
import EditButton from './EditButton';

const ProjectDetails = ({ project }) => {
  const { userContextData } = useContext(UserContext);

  // additional information
  const [additionalInfoRequired, setAdditionalInfoRequired] = useState(false);

  useEffect(() => {
    if (project?.additionalInfo && Object.keys(project.additionalInfo).length !== 0) {
      setAdditionalInfoRequired(true);
    }
  }, [project]);

  const userRole = userContextData?.projects?.length > 0 ? userContextData.projects[0].role : null;

  return (
    <>
      <InvitationLinks project={project} />
      <ClientInformation project={project} />
      <ProjectInformation project={project} />
      <InternalInformation project={project} />
      <PeopleInformation project={project} />
      <StylesAndServicesInformation project={project} />
      {/* <Attachments project={project} /> */}
      <div className="flex justify-end space-x-4">{<EditButton projectId={project._id} additionalInfoRequired={additionalInfoRequired} />}</div>
    </>
  );
};

export default ProjectDetails;
