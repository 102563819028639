import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useGetTasks from '../Hooks/useGetTasks';

import { Spinner } from 'flowbite-react';

import Attachments from '../Projects/Attachments';
import TasksTable from '../Projects/Tasks/TasksTable';

const TasksPage = () => {
  const { projectId } = useParams();

  const { tasks, isLoading, isError, refetch } = useGetTasks(projectId);

  useEffect(() => {}, [tasks]);

  if (isLoading) {
    return (
      <div>
        <Spinner aria-label="Info spinner example" color="info" />
      </div>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  // update component when tasks change

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <TasksTable projectId={projectId} initialTasks={tasks} fetchTasks={refetch} />
    </div>
  );
};

export default TasksPage;
