import React from 'react';
import AllProjectsTable from '../../Projects/Tables/AllProjectsTable';
import EmptyProjectsMessage from '../../Projects/Common/EmptyProjectsMessage';

const ProjectAllTablePage = ({ projects, fetchProjects, fetchSharedProjects }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        {projects.length > 0 ? (
          <AllProjectsTable projects={projects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />
        ) : (
          <EmptyProjectsMessage />
        )}
      </div>
    </div>
  );
};

export default ProjectAllTablePage;
