import { HomeIcon, DocumentDuplicateIcon, FolderIcon, PlusIcon, Cog8ToothIcon, UserIcon } from '@heroicons/react/24/outline';

const USER_NAVIGATION = [{ name: 'Sign out', href: '#' }];

const ADMIN_NAVIGATION_LINKS = [
  { id: 0, name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false },
  { id: 1, name: 'Add Project', href: '/dashboard/add-project', icon: PlusIcon, current: false },
  { id: 2, name: 'Projects', href: '/dashboard/projects', icon: FolderIcon, current: false },
  { id: 3, name: 'Organisation', href: '/dashboard/organisation', icon: Cog8ToothIcon, current: false },
  { id: 4, name: 'Profile', href: '/dashboard/profile', icon: UserIcon, current: false },
];

const CLIENT_NAVIGATION_LINKS = [
  { id: 1, name: 'Projects', href: '/dashboard/projects', icon: FolderIcon, current: false },
  { id: 2, name: 'Profile', href: '/dashboard/profile', icon: UserIcon, current: false },
];

export { USER_NAVIGATION, ADMIN_NAVIGATION_LINKS, CLIENT_NAVIGATION_LINKS };