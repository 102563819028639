function PeopleInformation({ project }) {
  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">People</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Client Emails and Roles, Share With Emails and Roles</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Clients</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {project.clients.map((client) => (
                <div key={client._id}>{client.email}</div>
              ))}
            </dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Admins</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {project.admins.map((admin) => (
                <div key={admin._id}>{admin.email || admin.name || 'Unknown Admin'}</div>
              ))}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default PeopleInformation;
