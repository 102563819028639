import React, { useState } from 'react';

const CSVUploadForm = ({ onFileUpload, projectId }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  return (
    <div>
      <h2 className="font-medium text-gray-900">Upload Milestones CSV</h2>
      <p className="mt-1 text-sm text-gray-600">
        Make sure your CSV file has the following columns: Milestone Category,
        Milestone Title, Submilestone Title. If you need a template, you can
        download it from{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/1LwUqBtH-uawtbIhkyT47g-qfPOBf0ceYNDMoX5W3Jqc/edit#gid=1549319702"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#2A9D8F] hover:underline"
        >
          here
        </a>
        .
      </p>
      <form onSubmit={handleSubmit} className="mt-5">
        <div className="grid grid-cols-1 gap-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Select CSV File *
            </label>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="mt-1 block w-full rounded-md bg-white py-2 px-3 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <input type="hidden" name="projectId" value={projectId} />
          <div className="mt-4">
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 bg-[#2A9D8F] text-white font-medium rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-[#264653] focus:ring-offset-2 transition duration-300"
            >
              Upload CSV
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CSVUploadForm;
