import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import api from '../../../utils/api';

export default function TasksEditModal({ task, setOpen, fetchTasks, isNotAdmin }) {
  return (
    <Transition.Root show={!!task} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Backdrop />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TaskContent task={task} onClose={() => setOpen(false)} fetchTasks={fetchTasks} isNotAdmin={isNotAdmin} />
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Backdrop() {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
  );
}

function TaskContent({ onClose, task, fetchTasks, isNotAdmin }) {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Task Information</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details of the task.</p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {/* Assuming your task has properties like name, description, etc. */}
              <DataField title="Name" variableToChange="name" value={task.name} taskId={task._id} fetchTasks={fetchTasks} isNotAdmin={isNotAdmin} />
              <DataField
                title="Assigned To"
                variableToChange="assignedTo"
                value={task.assignedTo}
                taskId={task._id}
                fetchTasks={fetchTasks}
                isNotAdmin={isNotAdmin}
              />
              {/* <DataField
                title="End Date"
                variableToChange="endDate"
                value={task.endDate}
                taskId={task._id}
              /> */}
              {/* <DataField
                title="Status"
                vavariableToChangeriable="status"
                value={task.status}
                taskId={task._id}
              /> */}
              <DataField
                title="Description"
                variableToChange="description"
                value={task.description}
                taskId={task._id}
                fetchTasks={fetchTasks}
                isNotAdmin={isNotAdmin}
              />
              <DataField title="Comments" variableToChange="comments" value={task.comments} taskId={task._id} fetchTasks={fetchTasks} isNotAdmin={isNotAdmin} />
              {/* If you have more properties, add more <DataField /> components */}
            </dl>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-[#2A9D8F] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={onClose}
            >
              Go back to dashboard
            </button>
          </div>
        </div>
      </Dialog.Panel>
    </Transition.Child>
  );
}

function DataField({ title, variableToChange, value, taskId, fetchTasks, isNotAdmin }) {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const [initialValue, setInitialValue] = useState(value);

  const handleUpdateClick = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleInputChange = (e) => {
    setUpdatedValue(e.target.value);
  };

  const handleUpdateSubmit = async () => {
    try {
      const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/tasks/task/${taskId}/update`, {
        [variableToChange]: updatedValue, // Use variable prop directly
      });
      if (response.data.message === 'Task updated successfully') {
        fetchTasks();
        setInitialValue(updatedValue);
        setShowUpdateModal(false);
      } else {
        // Handle error or alert the user.
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="relative px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
        <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
        <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{initialValue}</span>
          {!isNotAdmin && (
            <span className="ml-4 flex-shrink-0">
              <button type="button" className="rounded-md bg-white font-medium text-[#2A9D8F] hover:text-[#2A9D8F]" onClick={handleUpdateClick}>
                Update
              </button>
            </span>
          )}
        </dd>
      </div>

      <div>
        {showUpdateModal && (
          <div className="relative px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
            <dt className="text-sm font-medium leading-6 text-gray-900">New {title}</dt>
            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                value={updatedValue}
                onChange={handleInputChange}
                placeholder={`Update ${title}`}
                className="border rounded flex-grow w-1/2 h-8"
              />
              <span className="ml-4 flex-shrink-0">
                <button className="rounded-md bg-white font-medium text-[#2A9D8F] hover:text-[#2A9D8F]" onClick={handleUpdateSubmit}>
                  Submit Update
                </button>
              </span>
            </dd>
          </div>
        )}
      </div>
    </div>
  );
}
