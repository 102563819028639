import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import LoginButton from './Auth/LoginButton';
import logoLogInScreen from '../images/logoLogInScreen.png';

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const inviteEmail = params.get('email');

      if (inviteEmail && !isAuthenticated) {
        try {
          await loginWithRedirect({
            screen_hint: 'signup',
            login_hint: inviteEmail,
            appState: { inviteEmail },
          });
        } catch (error) {
          console.error('Error during loginWithRedirect:', error);
        }
      }
    };

    handleAuth();
  }, [location, isAuthenticated, loginWithRedirect]);

  return (
    <div className="flex h-screen items-center justify-center  ">
      <div className="w-full max-w-lg px-5 ">
        <div className="mb-4 text-center ">
          <img src={logoLogInScreen} alt="Company logo" className="h-25 mx-auto my-auto w-auto max-w-sm " />
          <p className="text-md  py-6 text-gray-800">
            DesignVault streamlines your next architectural project by providing a single platform to manage all your projects, communications, and tasks.
          </p>
        </div>

        <div className="flex items-center justify-center">
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
