import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { UserContext } from '../../../App';
import api from '../../../utils/api';

const UserDefaultTaskEditModal = ({
  task,
  setOpen,
  fetchTasks,

  stage,
}) => {
  const { userContextData } = useContext(UserContext);
  const [name, setName] = useState('');
  const [days, setDays] = useState(0);

  const userId = userContextData.user_id;

  useEffect(() => {
    if (task) {
      setName(task.name);
      setDays(task.days);
    }
  }, [task]);

  const handleSaveChanges = async () => {
    try {
      const updatedTask = {
        name,
        days,
      };
      await api.put(process.env.REACT_APP_SERVER_URL + `/api/users/user/${userId}/updateDefaultTask/${stage}/${task.name}`, updatedTask);
      fetchTasks();
      setOpen(null);
      window.location.reload();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };
  return (
    <Transition.Root show={!!task} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => setOpen(null)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Default Task Information</h3>
                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-600">Name:</label>
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="mt-1 p-2 w-full rounded-md border shadow-sm" />
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-600">Days:</label>
                  <input type="number" value={days} onChange={(e) => setDays(e.target.value)} className="mt-1 p-2 w-full rounded-md border shadow-sm" />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSaveChanges}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setOpen(null)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserDefaultTaskEditModal;
