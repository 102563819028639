import { useState, useEffect } from "react";
import { getSignedUrls } from "../../services/documentService";


function useGetSignedUrls(projectId) {
  const [fileUrls, setFileUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      try {
        const documentCategories = [
          "Property",
          "Reports",
          "Specifications",
          "Certificates",
          "Contracts",
        ];

        const urls = await getSignedUrls(projectId, documentCategories);
        setFileUrls(urls);
      } catch (err) {
        setError(err);
        console.error("Error fetching signed URLs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchSignedUrls();
  }, [projectId]);

  return { fileUrls, loading, error };
}

export default useGetSignedUrls;