import React from "react";
import { Field, ErrorMessage, FieldArray, useFormikContext } from "formik";

const InternalInfo = () => {
  const { values, errors } = useFormikContext();

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Internal Info (MP)
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Please enter internal information about the project.
        </p>
      </div>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-3">
          <label
            htmlFor="jobReference"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Internal Job Reference *
          </label>
          <Field
            type="text"
            name="jobReference"
            placeholder="001"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="jobReference"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="projectManager"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            MP Project Manager Email *
          </label>
          <Field
            type="text"
            placeholder="manager@project.com"
            name="projectManager"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          />
          <ErrorMessage
            name="projectManager"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>

        {/* Adding new dropdown field */}
        <div className="sm:col-span-6">
          <label
            htmlFor="projectStage"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Project Stage *
          </label>
          <Field
            as="select"
            name="projectStage"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
          >
            {/* <option value="Lead">Lead</option>
            <option value="Sale">Sale</option> */}
            <option value="Creation Pack">Creation Pack</option>
            <option value="Home Design Pack">Home Design Pack</option>
            <option value="Construction Pack">Construction Pack</option>
            <option value="Onsite">Onsite</option>
            <option value="On Hold">On Hold</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Complete">Complete</option>
          </Field>
          <ErrorMessage
            name="projectStatus"
            component="div"
            className="text-xs text-red-500 italic"
          />
        </div>
      </div>
    </div>
  );
};

export default InternalInfo;
